import React, { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CartBtRecommendItemEmpty from '~/cart/gmarket/ko/common/components/CartRecommend/CartBtRecommendItemEmpty'
import CartBtRecommendItemListTypeA from '~/cart/gmarket/ko/common/components/CartRecommend/CartBtRecommendItemListTypeA'
import CartBtRecommendItemListTypeB from '~/cart/gmarket/ko/common/components/CartRecommend/CartBtRecommendItemListTypeB'
import {
  addCartFromRecommend,
  switchToNextBtItem,
} from '~/cart/modules/complex-actions'
import {
  getCurrentBtRecommendItemList,
  getTotalBtSeedItemCount,
} from '~/cart/modules/recommend/reducer'
import { getCurrentBtSeedItemInfo, RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { goVIP } from '~/lib/utils'

const appendBTImage = (
  isAdvertisement: boolean,
  clickSecureUrl: string,
): void => {
  // BT AD 상품인 경우, VIP 이동/옵션선택/장바구니 담기 시 clickSecureUrl 호출
  try {
    if (isAdvertisement && clickSecureUrl) {
      const clickHiddenImg = document.createElement('img')
      clickHiddenImg.src =
        clickSecureUrl + '&ref=' + encodeURIComponent(domains.CART)
      clickHiddenImg.hidden = true
      document.body.appendChild(clickHiddenImg)
    }
  } catch (e) {}
}

const CartBtRecommendContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )
  const btRecommendItemList = useSelector(
    (state: RootState) => getCurrentBtRecommendItemList(state.recommend),
    isEqual,
  )
  const seedItem = useSelector(
    (state: RootState) => getCurrentBtSeedItemInfo(state),
    shallowEqual,
  )
  const totalBtSeedItemCount = useSelector((state: RootState) =>
    getTotalBtSeedItemCount(state.recommend),
  )
  const currentBtSeedIdx = useSelector(
    (state: RootState) => state.recommend.currentBtSeedIdx,
  )
  const btViewType = useSelector(
    (state: RootState) => state.recommend.btViewType,
  )
  const currentBranchServiceType = useSelector(
    (state: RootState) => state.smileFresh.currentBranchServiceType,
  )
  const onClickRefresh = useCallback(() => {
    dispatch(switchToNextBtItem())
  }, [dispatch])

  const onClickAddCart = useCallback(
    (itemNo: string, isAdvertisement: boolean, clickSecureUrl: string) =>
      (): void => {
        appendBTImage(isAdvertisement, clickSecureUrl)
        dispatch(addCartFromRecommend(itemNo, currentBranchServiceType))
      },
    [currentBranchServiceType, dispatch],
  )

  const onClickGoVIP = useCallback(
    (itemNo: string, isAdvertisement: boolean, clickSecureUrl: string) =>
      (): void => {
        appendBTImage(isAdvertisement, clickSecureUrl)
        goVIP(itemNo)
      },
    [],
  )

  if (
    !isMobile ||
    currentCartTab !== 'All' ||
    totalBtSeedItemCount === 0 ||
    !seedItem
  ) {
    return <></>
  }

  return (
    <div className="box__item-wrap">
      <div className="box__bt-title">
        <span className="box__thumb">
          <img
            src={seedItem.imageUrl}
            alt={seedItem.itemName}
            onError={(e): void => {
              e.currentTarget.src = domains.ITEM_NO_IMAGE
            }}
          />
        </span>
        <h3 className="text__title">
          이 상품을 담은
          <br />
          다른 고객 장바구니 보기
        </h3>
      </div>
      {btRecommendItemList.length > 0 ? (
        btViewType === 'TypeA' ? (
          <CartBtRecommendItemListTypeA
            seedItemNo={seedItem.itemNo}
            recommendItemList={btRecommendItemList}
            onClickGoVIP={onClickGoVIP}
            onClickAddCart={onClickAddCart}
          />
        ) : (
          <CartBtRecommendItemListTypeB
            key={currentBtSeedIdx} // 재 mount 되어 swiper 초기화되도록..!
            seedItemNo={seedItem.itemNo}
            recommendItemList={btRecommendItemList}
            onClickGoVIP={onClickGoVIP}
            onClickAddCart={onClickAddCart}
          />
        )
      ) : (
        <CartBtRecommendItemEmpty />
      )}

      <div className="box__button-wrap">
        <button
          type="button"
          className="button"
          onClick={onClickRefresh}
          data-montelena-acode={
            btViewType === 'TypeA'
              ? areaCodes.REFRESH_RECOMMEND_ITEMS_TYPE_A
              : areaCodes.REFRESH_RECOMMEND_ITEMS_TYPE_B
          }
        >
          <span className="text sprite__cart--before">다른 상품 보기</span>
          <span className="text__pagination">
            <span className="text__pagination--current">
              {currentBtSeedIdx + 1}
            </span>
            /{totalBtSeedItemCount}
          </span>
        </button>
      </div>
    </div>
  )
}

export default CartBtRecommendContainer
