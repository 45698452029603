import React from 'react'
import domains from '~/data/domains'
import { currencyAmount } from '~/lib/formatter'

const Title = React.memo(function Title({
  title,
}: {
  title: string
}): JSX.Element {
  return (
    <span className="text__title">
      <strong className="text__emphasis">{title}</strong>
    </span>
  )
})
const Thumbnail = React.memo(function Thumbnail({
  img,
}: {
  img: string
}): JSX.Element {
  return (
    <div className="box__item-image">
      <img
        src={img}
        onError={(e): void => {
          e.currentTarget.src = domains.ITEM_NO_IMAGE
        }}
        alt="바이박스 썸네일"
      />
    </div>
  )
})

const LowestPrice = React.memo(function LowestPrice({
  price,
}: {
  price: number
}): JSX.Element {
  return (
    <div className="box__price">
      <span className="text__label">최저가</span>
      <strong className="text__price text__price-coupon">
        {currencyAmount(price)}
        <span className="text__unit">원</span>
        <span className="text">쿠폰적용가</span>
      </strong>
    </div>
  )
})

const BuyBoxCard = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return <div className="box__buybox-item">{children}</div>
}

BuyBoxCard.Title = Title
BuyBoxCard.Thumbnail = Thumbnail
BuyBoxCard.LowestPrice = LowestPrice

export default BuyBoxCard
