import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import { formatString } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

const LayerNudgingAppDown = ({
  layerId,
  layerKey,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const buyerName = useSelector((state: RootState) => state.buyer.buyerName)

  useEffect(() => {
    return (): void => {
      CookieHelper.setCookie(
        CookieKeys.NudgingAppDownClosed,
        'true',
        moment().add(1, 'month').toDate(),
      )
    }
  })

  const onClickGoApp = useCallback(() => {
    if (domains.GO_APP) {
      window.location.href = formatString(
        domains.GO_APP,
        encodeURIComponent(domains.CART_HOME),
      )
    }
  }, [])

  if (!buyerName) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div
      id={layerId}
      className="box__layer box__layer--full box__layer__method"
      role="dialog"
    >
      <div className="box__layer-content">
        <div className="box__layer-header">
          <button
            type="button"
            className="button__close sprite__cart--before"
            data-montelena-acode={areaCodes.CLOSE_APP_NUDGING_BANNER}
            onClick={onCloseLayer()}
          >
            <span className="for-a11y">레이어 닫기</span>
          </button>
        </div>
        <div className="box__layer-body">
          <div className="section__app-nudging">
            <p className="text__app-nudging">
              앱전용 쿠폰을 가지고 계시다면,
              <br />
              지금 앱에서 사용할 수 있어요.
            </p>
            <div className="box__button">
              <button
                type="button"
                className="button button__default"
                onClick={onClickGoApp}
                data-montelena-acode={areaCodes.GO_APP}
              >
                G마켓 앱으로 보기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerNudgingAppDown
