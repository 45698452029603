import React, { useEffect, useMemo } from 'react'
import { useBuyBoxContext } from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxContext'
import useBuyBoxImpression from '~/cart/hooks/useBuyBoxImpression'

const SelectOptionButton = ({
  itemNo,
  label = '옵션선택',
  onClick,
}: {
  itemNo: string
  label?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}): JSX.Element => {
  const { tab, buyBox, appearedItems } = useBuyBoxContext()

  const appeared = useMemo(
    () => appearedItems.includes(itemNo),
    [appearedItems, itemNo],
  )

  const [logImp, dataProps] = useBuyBoxImpression(
    'SelectOptionButton',
    { tab, itemNo, buyBox },
    'BuyBoxCard_SelectOptionButton',
  )

  useEffect(() => {
    if (appeared) {
      logImp()
    }
  }, [appeared, logImp])

  return (
    <button
      type="button"
      className="button__cart"
      onClick={onClick}
      {...dataProps}
    >
      <span className="text">{label}</span>
    </button>
  )
}

export default React.memo(SelectOptionButton)
