import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import CartBodyContainer from '~/cart/gmarket/ko/common/containers/CartBodyContainer'
import CartError from '~/cart/gmarket/ko/common/containers/CartError'
import CartHeaderContainer from '~/cart/gmarket/ko/common/containers/CartHeaderContainer'
import ProcessingContainer from '~/cart/gmarket/ko/common/containers/ProcessingContainer'
import ToastContainer from '~/cart/gmarket/ko/common/containers/Toast/ToastContainer'
import GatewayContainer from '~/cart/gmarket/ko/mobile/containers/Gateway/GatewayContainer'
import HeaderContainer from '~/cart/gmarket/ko/mobile/containers/HeaderContainer'
import LayerListContainer from '~/cart/gmarket/ko/mobile/containers/Layer/LayerListContainer'
import { init } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { isFirstLoaded } from '~/cart/modules/view/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import Footer from '~/lib/components/gmarket/ko/mobile/Footer'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'
import logRender from '~/lib/log-render'
import { CartTabType } from '~/types/enums'
import './App.css'

const getClassName = (currentCartTab: CartTabType): string => {
  if (currentCartTab) {
    switch (currentCartTab) {
      case 'All':
        return 'box__all'
      case 'SmileDelivery':
        return 'box__smiledelivery'
      case 'ExpressShop':
        return 'box__todaydelivery'
    }
  }

  return ''
}

const App = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()
  useEffect(() => {
    dispatch(init()).catch(ERROR_HANDLER_DO_NOTHING())
  }, [dispatch])

  const firstLoaded = useSelector((state: RootState) =>
    isFirstLoaded(state.view),
  )
  const firstLoadFailed = useSelector(
    (state: RootState) => state.view.firstLoadFailed,
  )
  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  return (
    <>
      <HeaderContainer />

      <Switch>
        <Route path="/gateway/:type/:key" component={GatewayContainer} />
        <Route path="/">
          <div id="wrap" className={`wrap ${getClassName(currentCartTab)}`}>
            <ProcessingContainer />
            <div id="content" className="cart__wrap">
              <CartHeaderContainer />
              {firstLoaded && !firstLoadFailed && <CartBodyContainer />}
              {firstLoadFailed && <CartError />}
            </div>
          </div>
          <LayerListContainer />
          <ToastContainer />
        </Route>
      </Switch>
      <Footer />
    </>
  )
}

export default App
