import { once } from 'lodash'
import React, { createContext, useContext, useMemo } from 'react'
import isEqual from 'react-fast-compare'

interface FilterProps<TSelected> {
  selected: TSelected
  children: React.ReactNode
}

interface FilterContextProps<T> {
  selected?: T
}

const createFilterContext = once(<T,>() =>
  createContext<FilterContextProps<T>>({}),
)

const useFilterContext = <T,>(): FilterContextProps<T> =>
  useContext(createFilterContext<T>())

const Filter = <TSelected,>({
  selected,
  children,
}: FilterProps<TSelected>): JSX.Element => {
  const FilterContext = createFilterContext()

  return (
    <FilterContext.Provider
      value={{
        selected,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

interface FilterChildProps<TSelected> {
  list: TSelected[]
  children: React.ReactNode
}

const In = <TSelected,>({
  list,
  children,
}: FilterChildProps<TSelected>): JSX.Element => {
  const { selected } = useFilterContext<TSelected>()

  const pass = useMemo(() => {
    if (!selected) {
      return false
    }

    return list.some((elem) => isEqual(elem, selected))
  }, [list, selected])

  return <>{pass && children}</>
}

const NotIn = <TSelected,>({
  list,
  children,
}: FilterChildProps<TSelected>): JSX.Element => {
  const { selected } = useFilterContext<TSelected>()

  const block = useMemo(() => {
    if (!selected) {
      return true
    }

    return list.some((elem) => isEqual(elem, selected))
  }, [list, selected])

  return <>{!block && children}</>
}

Filter.In = In
Filter.NotIn = NotIn

export default Filter
