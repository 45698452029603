import { createContext, useContext } from 'react'
import { BuyBox } from '~/cart/modules/recommend/types'
import { CartTabType } from '~/types/enums'

interface BuyBoxContextProps {
  tab: CartTabType
  buyBox: BuyBox
  appearedItems: string[]
  addIntersectionObserver: (
    key: string,
  ) => (element: HTMLElement | null) => void
}

const BuyBoxContext = createContext<BuyBoxContextProps>({
  tab: 'All',
  buyBox: {
    fromItemNo: '',
    fromCartUnitIds: [],
    epin: 0,
    type: 'GoodDeal',
    action: 'SelectOption',
    reasons: [],
    status: 'Unknown',
    items: [],
  },
  appearedItems: [],
  addIntersectionObserver: () => (): void => undefined,
})

export const useBuyBoxContext = (): BuyBoxContextProps =>
  useContext(BuyBoxContext)

export default BuyBoxContext
