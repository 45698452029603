import { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useDispatch, useSelector } from 'react-redux'
import { getCartUnitById } from '~/cart/modules/cart/reducer'
import { CartUnitType } from '~/cart/modules/cart/types'
import { goCartTab } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { CartTabType } from '~/types/enums'

const mapUnitToTab: { [type in CartUnitType]: CartTabType } = {
  Normal: 'All',
  SmileFresh: 'SmileFresh',
  SmileDelivery: 'SmileDelivery',
  ExpressShop: 'ExpressShop',
}

const needToMoveTab = (
  currentTab: CartTabType,
  unitType: CartUnitType,
): boolean => {
  if (currentTab === 'All') {
    return false
  }
  return currentTab !== mapUnitToTab[unitType]
}

const useCartTabTransition = (id: number): void => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const [target, setTarget] = useState(0)

  const tab = useSelector((state: RootState) => state.cart.currentCartTab)

  const targetCartUnit = useSelector(
    (state: RootState) =>
      target > 0 ? getCartUnitById(state.cart, target) : undefined,
    isEqual,
  )

  useEffect(() => {
    setTarget(id)
  }, [id])

  useEffect(() => {
    const { cartUnitId = 0, cartUnitType } = targetCartUnit ?? {}
    if (cartUnitId > 0) {
      setTarget(0)
      if (cartUnitType && needToMoveTab(tab, cartUnitType)) {
        dispatch(goCartTab('All'))
      }
    }
  }, [dispatch, tab, targetCartUnit])
}

export default useCartTabTransition
