import React, { DetailedHTMLProps, useEffect } from 'react'
import { fetchClientLog } from '~/apis/log'

type GatewayIframeProps = DetailedHTMLProps<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>

const GatewayIframe = (props: GatewayIframeProps): JSX.Element => {
  useEffect(() => {
    try {
      if (props.src) {
        const url = new URL(
          /^\/\//.test(props.src)
            ? window.location.protocol + props.src
            : props.src,
        )
        const path = url.origin + url.pathname

        fetchClientLog('gateway iframe open - ' + path, {
          queryString: url.search,
        }).catch(() => {
          // DO NOTHING
        })
      }
    } catch (e) {
      console.error(e)
      // DON NOTHING
    }

    // 변경사항 상관 안하고 무조건 처음값으로만 로그남기기 위함
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <iframe {...props} />
}

export default GatewayIframe
