import React from 'react'
import isEqual from 'react-fast-compare'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import { ShippingPolicyEx } from '~/cart/modules/shipping/types'
import { currencyAmount } from '~/lib/formatter'

const CartShippingGroupSummary = ({
  shippingPolicy: {
    isQuantityShippingFee,
    isConditionalFree,
    conditionalFreeBasisCost,
    shippingFee,
    overseaShippingInfo,
  },
}: {
  shippingPolicy: ShippingPolicyEx
}): JSX.Element => {
  return (
    <div className="shipping--info">
      <div className="delivery">
        <span className="text">배송비</span>
        {isQuantityShippingFee && (
          <span className="delivery_tip">수량별 배송비</span>
        )}
        {isConditionalFree && (
          <span className="delivery_tip">
            {currencyAmount(conditionalFreeBasisCost)}원 이상 구매 시 무료
          </span>
        )}
        {shippingFee > 0 && <FormatPrice krwAmount={shippingFee} />}
        {shippingFee === 0 && (
          <span className="delivery_price free">무료배송</span>
        )}
        {overseaShippingInfo &&
          overseaShippingInfo.shippingFee > 0 &&
          overseaShippingInfo.shippingWeight > 0 && (
            <span className="delivery-oversea">
              <span className="text">
                해외배송비 (상품무게 {overseaShippingInfo.shippingWeight}kg)
              </span>
              <FormatPrice krwAmount={overseaShippingInfo.shippingFee} />
            </span>
          )}
      </div>
    </div>
  )
}

export default React.memo(CartShippingGroupSummary, isEqual)
