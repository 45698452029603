import React, { useEffect, useRef } from 'react'
import GatewayForm from '~/lib/components/GatewayForm'
import GatewayIframe from '~/lib/components/GatewayIframe'
import { IframeGatewayProps } from '~/types/common'

const NonHeaderIframe = ({
  iframeKey,
  title,
  src,
  formData,
  onLoadIframe,
  iframeAttributes,
}: IframeGatewayProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [])

  return (
    <div className="box__layer-iframe" id="box__layer-iframe">
      <div className="box__iframe">
        <GatewayIframe
          id={`${iframeKey}_iframe`}
          name={`${iframeKey}_iframe`}
          title={title}
          src={formData ? undefined : src}
          width="100%"
          height="100%"
          frameBorder="0"
          onLoad={onLoadIframe}
          {...iframeAttributes}
        />
        {formData && (
          <GatewayForm
            target={`${iframeKey}_iframe`}
            src={src}
            formRef={formRef}
            formData={formData}
          />
        )}
      </div>
    </div>
  )
}

export default NonHeaderIframe
