import { groupBy, map, sortBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShippingCountryComplex } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { getShippingCountry } from '~/cart/modules/shipping/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomInput from '~/lib/components/CustomInput'
import EnumConverter from '~/lib/enum-converter'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import { ShippingCountryType } from '~/types/enums'

const LayerCountrySelector = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const shippingCountry = useSelector((state: RootState) =>
    getShippingCountry(state.shipping),
  )

  const [
    selectedCountryType,
    selectCountryType,
  ] = useState<ShippingCountryType>(shippingCountry.countryType)

  useEffect(() => {
    selectCountryType(shippingCountry.countryType)
  }, [shippingCountry.countryType])

  const countryList = useSelector(
    (state: RootState) => state.shipping.overseaShippingCountries,
  )

  const defaultShippingCountry: ShippingCountryType = 'SouthKorea'

  const changeCountryAndClose = useCallback(
    (countryType: ShippingCountryType): void => {
      dispatch(setShippingCountryComplex(countryType)).then((isSuccess) => {
        if (isSuccess) {
          onCloseLayer()()
        }
      })
    },
    [dispatch, onCloseLayer],
  )

  const onClickCountry = useCallback(
    (countryType: ShippingCountryType) => (): void => {
      changeCountryAndClose(countryType)
    },
    [changeCountryAndClose],
  )
  const onChangeCountry = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const countryType = EnumConverter.toUnionType<ShippingCountryType>(
        e.currentTarget.value,
      )
      selectCountryType(countryType)
    },
    [],
  )

  const groupedCountryList = sortBy(
    map(
      groupBy(
        countryList,
        (country) =>
          country.countryName && country.countryName.slice(0, 1).toUpperCase(),
      ),
      (value, key) => ({ key, value }),
    ),
    (x) => x.key,
  )

  return (
    <div
      className="box__layer box__layer--full box__layer--select-nation"
      id={layerId}
      role="dialog"
    >
      <div className="box__layer-content">
        <div className="box__layer-header">
          <strong className="text__header">배송국가 선택</strong>
          <button
            type="button"
            className="button__close sprite__cart--before"
            onClick={onCloseLayer()}
          >
            <span className="for-a11y">레이어 닫기</span>
          </button>
        </div>
        <div className="box__layer-body">
          <div className="box__alert">
            <button
              className="button__change-shipping-kr strite__cart--after"
              onClick={onClickCountry(defaultShippingCountry)}
            >
              국내배송(한국)으로 변경
            </button>
          </div>
          <div className="sec_path">
            <div className="indicator" id="shipindi">
              <a href="#typeA">
                <span>A</span>
              </a>
              <a href="#typeB">
                <span>B</span>
              </a>
              <a href="#typeC">
                <span>C</span>
              </a>
              <a href="#typeD">
                <span>D</span>
              </a>
              <a href="#typeE">
                <span>E</span>
              </a>
              <a href="#typeF">
                <span>F</span>
              </a>
              <a href="#typeG">
                <span>G</span>
              </a>
              <a href="#typeH">
                <span>H</span>
              </a>
              <a href="#typeI">
                <span>I</span>
              </a>
              <a href="#typeJ">
                <span>J</span>
              </a>
              <a href="#typeK">
                <span>K</span>
              </a>
              <a href="#typeL">
                <span>L</span>
              </a>
              <a href="#typeM">
                <span>M</span>
              </a>
              <a href="#typeN">
                <span>N</span>
              </a>
              <a href="#typeO">
                <span>O</span>
              </a>
              <a href="#typeP">
                <span>P</span>
              </a>
              <a href="#typeQ">
                <span>Q</span>
              </a>
              <a href="#typeR">
                <span>R</span>
              </a>
              <a href="#typeS">
                <span>S</span>
              </a>
              <a href="#typeT">
                <span>T</span>
              </a>
              <a href="#typeU">
                <span>U</span>
              </a>
              <a href="#typeV">
                <span>V</span>
              </a>
              <a href="#typeW">
                <span>W</span>
              </a>
              <a href="#typeX">
                <span>X</span>
              </a>
              <a href="#typeY">
                <span>Y</span>
              </a>
              <a href="#typeZ">
                <span>Z</span>
              </a>
            </div>

            <p className="cur_txt">
              <span className="blind">current Text</span>
              <span className="txt">A</span>
            </p>

            <div className="lst_nation" id="shipNation">
              <div className="scroller">
                <ul>
                  {map(groupedCountryList, (countryGroup, idx) => (
                    <li
                      key={'group_' + idx}
                      className="sec_nat"
                      id={'type' + countryGroup.key}
                    >
                      <p className="grp_txt">{countryGroup.key}</p>
                      <ul>
                        {map(countryGroup.value, (country, idx) => (
                          <li
                            key={idx}
                            className={country.countryCode.toLowerCase()}
                          >
                            <label htmlFor={country.countryCode}>
                              <span className="flag sp_flag52" />
                              <span className="tit">{country.countryName}</span>
                            </label>
                            <CustomInput<ShippingCountryType>
                              type="radio"
                              id={country.countryCode}
                              name="ship_country"
                              className="sprite__cart bx_rdo"
                              value={country.countryType}
                              checked={
                                selectedCountryType === country.countryType
                              }
                              onChange={onChangeCountry}
                              onClick={onClickCountry(country.countryType)}
                            />
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerCountrySelector
