import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  openAddressBookForFastDelivery,
  openSimpleLayer,
  updateLatestAddress,
} from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import {
  getIsOverseaShipping,
  getLatestShippingAddress,
  getShippingCountry,
} from '~/cart/modules/shipping/reducer'
import { LatestShippingAddress } from '~/cart/modules/shipping/types'
import { EnumLayerType } from '~/cart/modules/view/types'
import areaCodes from '~/data/areaCodes'
import { ElementIds } from '~/data/consts'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { CartTabType } from '~/types/enums'

const getAddressViewType = (
  isMember: boolean,
  latestShippingAddress: LatestShippingAddress | undefined,
  currentCartTab: CartTabType,
  isSmileFreshBranchExists: boolean,
):
  | 'None'
  | 'Normal'
  | 'NeedToChange'
  | 'Error'
  | 'NonMember'
  | 'ExpressShop'
  | 'UnableSmileFresh' => {
  if (!isMember) {
    return 'NonMember'
  }
  if (!latestShippingAddress) {
    return 'Error'
  } else {
    if (latestShippingAddress.alterType === 'Normal') {
      if (currentCartTab === 'SmileFresh' && !isSmileFreshBranchExists) {
        return 'UnableSmileFresh'
      }
      if (currentCartTab === 'ExpressShop') {
        return 'ExpressShop'
      }
      return 'Normal'
    } else if (latestShippingAddress.alterType === 'None') {
      return 'None'
    } else {
      return 'NeedToChange'
    }
  }
}

const SmileDeliveryAddressContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )
  const isMember = useSelector(
    (state: RootState) => state.buyer.memberType !== 'NonMember',
  )
  const latestShippingAddress = useSelector((state: RootState) =>
    getLatestShippingAddress(state.shipping),
  )
  const isSmileFreshBranchExists = useSelector(
    (state: RootState) => state.smileFresh.availableBranches.length > 0,
  )

  const addressViewType = useMemo(
    () =>
      getAddressViewType(
        isMember,
        latestShippingAddress,
        currentCartTab,
        isSmileFreshBranchExists,
      ),
    [currentCartTab, isMember, isSmileFreshBranchExists, latestShippingAddress],
  )

  const isOverseaShipping = useSelector((state: RootState) =>
    getIsOverseaShipping(state.shipping),
  )
  const shippingCountry = useSelector(
    (state: RootState) => getShippingCountry(state.shipping),
    shallowEqual,
  )

  const expressShopDeliveryAddress = useSelector(
    (state: RootState) => state.buyer.expressShopDeliveryAddress,
  )

  const onClickOpenAddressBook = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(openAddressBookForFastDelivery(e.currentTarget))
    },
    [dispatch],
  )
  const onClickRefreshLatestAddress = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(updateLatestAddress())
    },
    [dispatch],
  )
  const onClickOpenCountrySelector = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      dispatch(openSimpleLayer(EnumLayerType.CountrySelector, e.currentTarget))
    },
    [dispatch],
  )

  if (currentCartTab !== 'All') {
    return (
      <div className="box__order-info">
        {addressViewType === 'Normal' && (
          <div className="box__address-info">
            <span className="text__address sprite__cart--before">
              {latestShippingAddress && latestShippingAddress.address}
            </span>
            <button
              type="button"
              className="button__address"
              aria-label="배송지 변경하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.CHANGE_LATEST_ADDRESS
                  : areaCodes.CHANGE_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              변경
            </button>
          </div>
        )}
        {addressViewType === 'ExpressShop' && (
          <>
            {expressShopDeliveryAddress ? (
              <>
                <div className="box__address-info">
                  <span className="text__address sprite__cart--before">
                    <span className="text__name">
                      {expressShopDeliveryAddress?.receiverName}
                    </span>
                    {`${expressShopDeliveryAddress?.address} ${expressShopDeliveryAddress?.detailAddress}`}
                  </span>
                </div>
              </>
            ) : (
              <strong className="text__title">결제정보</strong>
            )}
          </>
        )}
        {addressViewType === 'UnableSmileFresh' && (
          <div className="box__address-info">
            <span className="text__address text__address-unable sprite__cart--before ">
              <span className="text__emphasis">배송불가</span>
              {latestShippingAddress && latestShippingAddress.address}
            </span>
            <button
              type="button"
              className="button__address"
              aria-label="배송지 변경하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.CHANGE_LATEST_ADDRESS
                  : areaCodes.CHANGE_UNABLE_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              변경
            </button>
          </div>
        )}
        {addressViewType === 'NeedToChange' && (
          <div className="box__address-info">
            <span className="text__address text__address-none sprite__cart--before">
              <span className="text__emphasis">배송받을 주소</span>를 변경해
              주세요
            </span>
            <button
              type="button"
              className="button__address button__address-set"
              aria-label="배송지 변경하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.CHANGE_LATEST_ADDRESS
                  : areaCodes.NEED_TO_CHANGE_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              변경하기
            </button>
          </div>
        )}
        {addressViewType === 'None' && (
          // 배송지 없는 케이스
          <div className="box__address-info">
            <p className="text__address text__address-none sprite__cart--before">
              <span className="text__emphasis">배송 받을 주소</span>를
              설정해보세요.
            </p>
            <button
              type="button"
              className="button__address button__address-set"
              aria-label="배송지 설정하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.ADD_LATEST_ADDRESS
                  : areaCodes.ADD_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              설정하기
            </button>
          </div>
        )}
        {addressViewType === 'NonMember' && (
          <div className="box__address-info">
            <span className="text__address text__address-none sprite__cart--before">
              <span className="text__emphasis">배송받을 주소</span>를
              설정해보세요.
            </span>
            <button
              type="button"
              className="button__address"
              aria-label="배송지 설정하기"
              data-montelena-acode={areaCodes.CHANGE_LATEST_ADDRESS_NON_MEMBER}
              onClick={onClickOpenAddressBook}
            >
              설정하기
            </button>
          </div>
        )}
        {addressViewType === 'Error' && (
          <div className="box__address-info">
            <span className="text__address text__address-none sprite__cart--before">
              주소를 다시 한번 조회해 주세요
            </span>
            <button
              type="button"
              className="button__address button__address-set"
              aria-label="주소록 조회하기"
              onClick={onClickRefreshLatestAddress}
              data-montelena-acode={areaCodes.LATEST_ADDRESS_API_ERROR}
            >
              조회하기
            </button>
          </div>
        )}
        {currentCartTab !== 'SmileFresh' && (
          <span className="text__oversea">
            <button
              type="button"
              className="button__oversea sprite__cart--after"
              onClick={onClickOpenCountrySelector}
            >
              해외로 배송받고 싶으신가요?
            </button>
          </span>
        )}
      </div>
    )
  } else {
    return !isOverseaShipping ? (
      <div className="box__order-info">
        <strong className="text__title">결제정보</strong>
        <span className="text__oversea">
          <button
            type="button"
            className="button__oversea sprite__cart--after"
            onClick={onClickOpenCountrySelector}
            data-montelena-acode={areaCodes.OVERSEA_SHIPPING}
          >
            해외로 배송받고 싶으신가요?
          </button>
        </span>
      </div>
    ) : (
      <div className="box__order-info">
        <div className="box__address-info">
          <span className="text__address text__address-oversea sprite__cart--before">
            <span className="text__emphasis">
              {shippingCountry.countryName}
            </span>
            (으)로 배송
          </span>
          <button
            id={ElementIds.ChangeCountryWhenOversea}
            type="button"
            className="button__address"
            aria-label="배송지 변경하기"
            onClick={onClickOpenCountrySelector}
            data-montelena-acode={areaCodes.OVERSEA_SHIPPING}
          >
            변경
          </button>
        </div>
      </div>
    )
  }
}

export default SmileDeliveryAddressContainer
