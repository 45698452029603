import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LayerSmileClubOneClickJoin from '~/cart/gmarket/ko/common/containers/Layer/LayerSmileClubOneClickJoin'
import LayerConfirmAuthentication from '~/cart/gmarket/ko/mobile/containers/Layer/LayerConfirmAuthentication'
import LayerConfirmIncomeDutyItemOrderType from '~/cart/gmarket/ko/mobile/containers/Layer/LayerConfirmIncomeDutyItemOrderType'
import LayerConfirmMaxInstallOrder from '~/cart/gmarket/ko/mobile/containers/Layer/LayerConfirmMaxInstallOrder'
import LayerConfirmOrderExceptSomeItems from '~/cart/gmarket/ko/mobile/containers/Layer/LayerConfirmOrderExceptSomeItems'
import LayerCountrySelector from '~/cart/gmarket/ko/mobile/containers/Layer/LayerCountrySelector'
import LayerJoinSmileClubForSmileDelivery from '~/cart/gmarket/ko/mobile/containers/Layer/LayerJoinSmileClubForSmileDelivery'
import LayerNudgingAppDown from '~/cart/gmarket/ko/mobile/containers/Layer/LayerNudgingAppDown'
import LayerNudgingCartCountExtended from '~/cart/gmarket/ko/mobile/containers/Layer/LayerNudgingCartCountExtended'
import LayerNudgingSmileCard from '~/cart/gmarket/ko/mobile/containers/Layer/LayerNudgingSmileCard'
import LayerNudgingSmileClubOneClick from '~/cart/gmarket/ko/mobile/containers/Layer/LayerNudgingSmileClubOneClick'
import LayerOverseaShippingNoti from '~/cart/gmarket/ko/mobile/containers/Layer/LayerOverseaShippingNoti'
import LayerUnitCouponBox from '~/cart/gmarket/ko/mobile/containers/Layer/LayerUnitCouponBox'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import { EnumLayerType, LayerData } from '~/cart/modules/view/types'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import ComplexDataStore from '~/lib/complex-data-store'
import logRender from '~/lib/log-render'
import UXEHelper from '~/lib/uxe-helper'

const LayerContainer = ({ layer }: { layer: LayerData }): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const layerId =
    layer.type === EnumLayerType.UnitCouponBox
      ? 'layer_couponbox'
      : layer.type === EnumLayerType.SmileClubOneClickJoin
      ? 'layer_onclick-smileclub'
      : `xo_layer_${layer.key}`

  const isUseUXEFunction = ![
    // EnumLayerType.CountrySelector,
    EnumLayerType.UnitCouponBox,
    EnumLayerType.JoinSmileClubForSmileDelivery,
    EnumLayerType.SmileClubOneClickJoin,
    EnumLayerType.NudgingClubOneClick,
    EnumLayerType.ConfirmIncomeDutyItemOrderType,
    EnumLayerType.ConfirmAuthentication,
  ].includes(layer.type)

  const layerDetailData = ComplexDataStore.get('LayerDetailData', layer.key)
  useEffect(() => {
    if (layerDetailData && isUseUXEFunction) {
      UXEHelper.openDimmedLayer(`#${layerId}`, layerDetailData.triggerElement)
    } else if (layerDetailData && layer.type === EnumLayerType.UnitCouponBox) {
      UXEHelper.openSlideLayer(layerDetailData.triggerElement)
    } else if (layerDetailData) {
      try {
        $(`#${layerId}`).focus()
      } catch (e) {
        // DO NOTHING
      }
      return (): void => {
        layerDetailData.triggerElement.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseLayerAsync = useCallback(
    (closeImmediately = false): Promise<void> =>
      new Promise<void>((resolve) => {
        if (isUseUXEFunction) {
          UXEHelper.closeDimmedLayer(`#${layerId}`)
        }
        const key = window.setTimeout(
          () => {
            dispatch(closeLayer(layer.key))
            resolve()
          },
          closeImmediately || !isUseUXEFunction ? 0 : 500,
        )
        return (): void => {
          window.clearTimeout(key)
        }
      }),
    [dispatch, isUseUXEFunction, layer.key, layerId],
  )

  const onCloseLayer = useCallback(
    (closeImmediately = false) =>
      async (): Promise<void> => {
        await onCloseLayerAsync(closeImmediately)
      },
    [onCloseLayerAsync],
  )

  if (!layerDetailData) {
    dispatch(closeLayer(layer.key))
    return <></>
  }

  const LayerWrapper = ({
    children,
    isClickDisabled,
  }: {
    children: JSX.Element
    isClickDisabled?: boolean
  }): JSX.Element => (
    <>
      {children}
      <div
        className="dimmed xo_dimmed"
        onClick={isClickDisabled ? undefined : onCloseLayer()}
      />
    </>
  )

  switch (layer.type) {
    case EnumLayerType.UnitCouponBox:
      return (
        <LayerUnitCouponBox
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          detailData={layerDetailData.unitCouponBox}
        />
      )
    case EnumLayerType.CountrySelector:
      return (
        <LayerCountrySelector
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.JoinSmileClubForSmileDelivery:
      return (
        <LayerJoinSmileClubForSmileDelivery
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.SmileClubOneClickJoin:
      return (
        <LayerSmileClubOneClickJoin
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.NudgingClubOneClick:
      return (
        <LayerNudgingSmileClubOneClick
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.NudgingSmileCard:
      return (
        <LayerWrapper>
          <LayerNudgingSmileCard
            layerId={layerId}
            layerKey={layer.key}
            onCloseLayer={onCloseLayer}
          />
        </LayerWrapper>
      )
    case EnumLayerType.NudgingAppDown:
      return (
        <LayerWrapper>
          <LayerNudgingAppDown
            layerId={layerId}
            layerKey={layer.key}
            onCloseLayer={onCloseLayer}
          />
        </LayerWrapper>
      )
    case EnumLayerType.NudgingCartCountExtended:
      return (
        <LayerNudgingCartCountExtended
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.ConfirmOrderExceptSomeItems:
      return (
        <LayerWrapper>
          <LayerConfirmOrderExceptSomeItems
            layerId={layerId}
            layerKey={layer.key}
            onCloseLayer={onCloseLayer}
            onCloseLayerAsync={onCloseLayerAsync}
            detailData={layerDetailData.confirmOrderExceptSomeItems}
          />
        </LayerWrapper>
      )
    case EnumLayerType.ConfirmIncomeDutyItemOrderType:
      return (
        <LayerConfirmIncomeDutyItemOrderType
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmIncomeDutyItemOrderType}
        />
      )
    case EnumLayerType.ConfirmAuthentication:
      return (
        <LayerConfirmAuthentication
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmAuthentication}
        />
      )
    case EnumLayerType.OverseaPackageNoti:
      return (
        <LayerOverseaShippingNoti
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmOverseaShippingNotiType}
        />
      )
    case EnumLayerType.ConfirmMaxInstallOrder:
      return (
        <LayerConfirmMaxInstallOrder
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
  }

  return <></>
}

export default React.memo(LayerContainer, (l, r) => l.layer.key === r.layer.key)
