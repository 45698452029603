import React from 'react'
import isEqual from 'react-fast-compare'

type CouponBoxTitleProps = {
  isMobile?: boolean
  children: React.ReactNode
}
const CouponBoxTitle = ({
  isMobile = false,
  children,
}: CouponBoxTitleProps): JSX.Element => {
  if (isMobile) {
    return <>{children}</>
  }
  return <div className="box__title">{children}</div>
}

export default React.memo(CouponBoxTitle, isEqual)
