import React from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useSelector } from 'react-redux'
import SmileDeliveryGaugeBar from '~/cart/gmarket/ko/common/components/SmileDeliveryGaugeBar'
import { getIsUniverseClub } from '~/cart/modules/buyer/reducer'
import { SellerGroupView } from '~/cart/modules/cart/types'
import {
  getSelectedSellerGroupSummary,
  RootState,
} from '~/cart/modules/reducers'
import { SmileDeliveryConditionalFreeBasisCost } from '~/data/consts'
import logRender from '~/lib/log-render'

const SmileDeliveryGaugeBarContainer = ({
  sellerGroup,
}: {
  sellerGroup: SellerGroupView
}): JSX.Element => {
  logRender()
  const isApp = useSelector((state: RootState) => state.view.isApp)
  const isAndroid = useSelector((state: RootState) => state.view.isAndroid)
  const appType = useSelector((state: RootState) => state.view.appInfo?.appType)
  const isUniverseClub = useSelector((state: RootState) =>
    getIsUniverseClub(state.buyer),
  )
  const isShippingFeeLoadedAfterChanged = useSelector(
    (state: RootState) => state.view.isShippingFeeLoadedAfterChanged,
  )

  const sellerGroupSummary = useSelector(
    (state: RootState) => getSelectedSellerGroupSummary(state, sellerGroup),
    shallowEqual,
  )

  return (
    <SmileDeliveryGaugeBar
      isShippingFeeLoadedAfterChanged={isShippingFeeLoadedAfterChanged}
      isFreeShipping={sellerGroupSummary.totalShippingFee === 0}
      isUniverseClub={isUniverseClub}
      isApp={isApp}
      isAndroid={isAndroid}
      appType={appType}
      selectedCartUnitCount={sellerGroupSummary.cartUnitCount}
      totalItemPrice={sellerGroupSummary.totalItemPrice}
      smileDeliveryConditionalFreeBasisCost={
        SmileDeliveryConditionalFreeBasisCost
      }
    />
  )
}

export default React.memo(SmileDeliveryGaugeBarContainer, isEqual)
