import React from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CartSellerGroupFooter from '~/cart/gmarket/ko/common/components/CartItemList/CartSellerGroupFooter'
import CartSellerGroupHeader from '~/cart/gmarket/ko/common/components/CartItemList/CartSellerGroupHeader'
import CartShippingGroupContainer from '~/cart/gmarket/ko/common/containers/CartItemList/CartShippingGroupContainer'
import SmileDeliveryGaugeBarContainer from '~/cart/gmarket/ko/common/containers/CartItemList/SmileDeliveryGaugeBarContainer'
import SmileFreshGaugeBarContainer from '~/cart/gmarket/ko/common/containers/CartItemList/SmileFreshGaugeBarContainer'
import { getIsAllBuyUnavailable } from '~/cart/modules/cart/reducer'
import { SellerGroupView, SellerGroupViewType } from '~/cart/modules/cart/types'
import { openExpressTimeTable } from '~/cart/modules/complex-actions'
import {
  getSelectedSellerGroupSummary,
  RootState,
} from '~/cart/modules/reducers'
import { getLatestShippingAddress } from '~/cart/modules/shipping/reducer'
import {
  getCurrentSmileFreshArrivalEstimate,
  getIsSeperatedBranches,
} from '~/cart/modules/smile-fresh/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { ExpressShopType } from '~/types/enums'

const getMiniShopClass = (
  viewType: SellerGroupViewType,
  isSeperatedSmileFreshBranches: boolean,
): string => {
  switch (viewType) {
    case 'SmileDelivery':
      return 'type_smiledelivery'
    case 'SmileFresh':
      return isSeperatedSmileFreshBranches
        ? 'type_smilefresh has-smilefresh--menu'
        : 'type_smilefresh'
    case 'ExpressShop':
      return 'type_todaydelivery'
    default:
      return 'type_minishop'
  }
}

const CartSellerGroupContainer = ({
  sellerGroup,
}: {
  sellerGroup: SellerGroupView
}): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const latestShippingAddress = useSelector((state: RootState) =>
    getLatestShippingAddress(state.shipping),
  )

  const isAllBuyUnavailable = useSelector((state: RootState) =>
    getIsAllBuyUnavailable(state.cart, sellerGroup.cartUnitIdList),
  )

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  const smileFreshArrivalEstimate = useSelector((state: RootState) =>
    getCurrentSmileFreshArrivalEstimate(state.smileFresh),
  )

  const sellerGroupSummary = useSelector(
    (state: RootState) => getSelectedSellerGroupSummary(state, sellerGroup),
    shallowEqual,
  )

  const isSeperatedSmileFreshBranches = useSelector((state: RootState) =>
    getIsSeperatedBranches(state.smileFresh),
  )

  const onClickOpenExpressTimeTable =
    (expressShopType: ExpressShopType) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      dispatch(openExpressTimeTable(e.currentTarget, expressShopType))
    }

  const isShowSummary =
    currentCartTab !== 'SmileDelivery' &&
    currentCartTab !== 'SmileFresh' &&
    !isAllBuyUnavailable

  return (
    <li
      className={`cart--basket ${getMiniShopClass(
        sellerGroup.type,
        isSeperatedSmileFreshBranches,
      )}`}
    >
      {!(
        isMobile &&
        sellerGroup.type === 'SmileDelivery' &&
        currentCartTab === 'SmileDelivery'
      ) && (
        <CartSellerGroupHeader
          sellerGroup={sellerGroup}
          currentCartTab={currentCartTab}
          onClickOpenExpressTimeTable={onClickOpenExpressTimeTable}
        />
      )}
      <div className="cart--basket_body">
        {sellerGroup.type === 'SmileDelivery' &&
          currentCartTab === 'SmileDelivery' && (
            <SmileDeliveryGaugeBarContainer sellerGroup={sellerGroup} />
          )}
        {sellerGroup.type === 'SmileFresh' &&
          currentCartTab === 'SmileFresh' && (
            <SmileFreshGaugeBarContainer sellerGroup={sellerGroup} />
          )}
        {sellerGroup.type === 'SmileFresh' &&
          smileFreshArrivalEstimate &&
          latestShippingAddress?.alterType !== 'NotSupport' && (
            <div className="box__ad-smilefresh box__smilefresh--guide">
              <p className="text__smilefresh--guide">
                {smileFreshArrivalEstimate}
              </p>
            </div>
          )}
        {sellerGroup.shippingGroups.map((shippingGroup) => (
          <CartShippingGroupContainer
            key={shippingGroup.key}
            shippingGroup={shippingGroup}
          />
        ))}
      </div>
      {isShowSummary && (
        <CartSellerGroupFooter
          sellerGroup={sellerGroup}
          sellerGroupSummary={sellerGroupSummary}
          isMobile={isMobile}
        />
      )}
    </li>
  )
}

export default React.memo(CartSellerGroupContainer, isEqual)
