import { compact, isEmpty, map, uniq } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiErrorHandler } from '~/api-error-handler'
import { fetchAddCart, fetchReplace } from '~/apis/cart'
import { fetchGetBuyBox } from '~/apis/recommend'
import useBuyBoxTarget, { BuyBoxTarget } from '~/cart/hooks/useBuyBoxTarget'
import useSortedCart from '~/cart/hooks/useSortedCart'
import { addCartUnits, replaceCartUnits } from '~/cart/modules/complex-actions'
import {
  addBuyBoxes,
  addHitBuyBox,
  removeBuyBoxes,
} from '~/cart/modules/recommend/actions'
import {
  BuyBox,
  BuyBoxActionType,
  BuyBoxItem,
  BuyBoxMap,
  BuyBoxStatus,
  RecommendedItem,
  RecommendedItemMap,
} from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { ApiError } from '~/lib/axios-client'
import useDeepCompareCallback from '~/lib/deep-compare-hook/useDeepCompareCallback'
import { formatString } from '~/lib/formatter'

const getPreciseActionType = (items?: RecommendedItem[]): BuyBoxActionType => {
  if (items?.some(({ isOptionUse }) => isOptionUse)) {
    return 'SelectOption'
  }
  return 'ReplaceCart'
}

const reduceToBuyBoxMapOn =
  (status: BuyBoxStatus) =>
  (
    acc: BuyBoxMap,
    { itemNo, cartUnitIds, epin, type, reasons }: BuyBoxTarget,
  ): BuyBoxMap => {
    acc[itemNo] = {
      fromItemNo: itemNo,
      fromCartUnitIds: cartUnitIds,
      epin,
      type,
      action: getPreciseActionType(),
      reasons,
      status,
      items: [],
    }

    return acc
  }

const reduceToBuyBoxMap = (
  responses: cartFE.GetCartBuyBoxResponse[],
  recommendedItems: RecommendedItemMap,
  filter: (
    target: BuyBoxTarget,
    buyBox: cartFE.BuyBox,
  ) => boolean = (): boolean => true,
): ((acc: BuyBoxMap, curr: BuyBoxTarget) => BuyBoxMap) => {
  const responseMap = responses.reduce(
    (acc, curr) => {
      acc[curr.epin] = curr
      return acc
    },
    {} as {
      [epin: number]: cartFE.GetCartBuyBoxResponse
    },
  )

  return (acc: BuyBoxMap, curr: BuyBoxTarget): BuyBoxMap => {
    const { itemNo, cartUnitIds, epin, type, reasons } = curr
    const buyBox: BuyBox = {
      fromItemNo: itemNo,
      fromCartUnitIds: cartUnitIds,
      epin,
      type,
      action: getPreciseActionType(),
      reasons,
      status: 'Pending',
      items: [],
    }

    const { buyBoxes } = responseMap[epin] ?? {}
    const filteredBuyBoxes = buyBoxes?.filter((b) => filter(curr, b))
    if (isEmpty(filteredBuyBoxes)) {
      acc[itemNo] = {
        ...buyBox,
        status: 'NoExist',
      }

      return acc
    }

    acc[itemNo] = {
      ...buyBox,
      status: 'Loaded',
      action: getPreciseActionType(
        compact(filteredBuyBoxes.map(({ itemNo }) => recommendedItems[itemNo])),
      ),
      items: filteredBuyBoxes,
    }

    return acc
  }
}

const voidPromise = Promise.resolve

/**
 * 바이박스 hook
 * @return [바이박스 목록, 추천 목록 획득 action, 장바구니 담기 action, 장바구니 교체 action, 바이박스 clear action]
 */
const useBuyBox = (): [
  BuyBoxMap,
  (
    itemGetter: (loaded: string[]) => Promise<RecommendedItemMap>,
  ) => Promise<void>,
  (
    fromItemNo: string,
    targetItemNo: string,
    beforeCartUpdate: () => Promise<void>,
  ) => Promise<void>,
  (
    fromItemNo: string,
    targetItemNo: string,
    beforeCartUpdate: () => Promise<void>,
  ) => Promise<void>,
  () => Promise<void>,
] => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const buyBoxes = useSelector((state: RootState) => state.recommend.buyBoxes)

  const branchServiceType = useSelector(
    (state: RootState) => state.smileFresh.currentBranchServiceType,
  )

  const [targets, restrained] = useBuyBoxTarget()

  const [, , itemNos] = useSortedCart()

  const recommend = useDeepCompareCallback(
    async (
      itemGetter: (loaded: string[]) => Promise<RecommendedItemMap>,
    ): Promise<void> => {
      if (isEmpty(targets)) {
        return
      }

      dispatch(
        addBuyBoxes({
          ...restrained.reduce(reduceToBuyBoxMapOn('Restrained'), {}),
          ...targets.reduce(reduceToBuyBoxMapOn('Pending'), {}),
        }),
      )

      try {
        const responses = await fetchGetBuyBox({
          targets: targets.map(({ itemNo, epin, type }) => ({
            itemNo,
            epin,
            type,
          })),
        })

        /**
         * 현재 장바구니에 담겨 있는 상품은 바이박스 추천에서 제외되어야 한다.
         */
        const itemNoInCartMap = itemNos.reduce((acc, curr) => {
          acc[curr] = curr
          return acc
        }, {} as { [key: string]: string })

        dispatch(
          addBuyBoxes(
            targets.reduce(
              reduceToBuyBoxMap(
                responses,
                await itemGetter(
                  uniq(
                    responses
                      .flatMap(({ buyBoxes }) => buyBoxes)
                      .map(({ itemNo }) => itemNo),
                  ),
                ),
                (_, buyBox) => !itemNoInCartMap[buyBox.itemNo],
              ),
              {},
            ),
          ),
        )
      } catch (e) {
        dispatch(addBuyBoxes(targets.reduce(reduceToBuyBoxMapOn('Failed'), {})))
      }
    },
    [dispatch, targets, itemNos],
  )

  const hit = useCallback(
    async (
      fromItemNo: string,
      targetItemNo: string,
      cartCommand: (
        buyBox: BuyBox,
        buyBoxItem: BuyBoxItem,
      ) => Promise<number | undefined>,
      postAction: (
        addedCartUnitId: number,
        buyBox: BuyBox,
      ) => Promise<void> = voidPromise,
    ): Promise<void> => {
      const buyBox = buyBoxes[fromItemNo]
      if (!buyBox) {
        throw new Error(`the buy box is empty. - itemNo : ${fromItemNo}`)
      }

      const targetBuyBoxItem = buyBox.items.find(
        ({ itemNo }) => targetItemNo === itemNo,
      )
      if (!targetBuyBoxItem) {
        throw new Error(`the target item is empty. - itemNo : ${targetItemNo}`)
      }

      const addedCartUnitId = await cartCommand(buyBox, targetBuyBoxItem)
      if (addedCartUnitId) {
        dispatch(
          addHitBuyBox({
            itemNo: targetBuyBoxItem.itemNo,
            hitBuyBox: {
              fromItemNo: buyBox.fromItemNo,
              fromCartUnitIds: buyBox.fromCartUnitIds,
              toCartUnitId: addedCartUnitId,
              epin: buyBox.epin,
              type: buyBox.type,
              action: buyBox.action,
              reasons: buyBox.reasons,
              itemNo: targetBuyBoxItem.itemNo,
              couponAppliedPrice: targetBuyBoxItem.couponAppliedPrice,
              imageUrl: targetBuyBoxItem.imageUrl,
            },
          }),
        )
        dispatch(removeBuyBoxes([buyBox.fromItemNo]))
        await postAction(addedCartUnitId, buyBox)
      }
    },
    [buyBoxes, dispatch],
  )

  const addCart = useCallback(
    async (
      fromItemNo: string,
      targetItemNo: string,
      beforeCartUpdate: () => Promise<void> = voidPromise,
    ): Promise<void> => {
      await hit(
        fromItemNo,
        targetItemNo,
        async (buyBox, buyBoxItem) => {
          const { cartUnitResponses = [] } = await fetchAddCart({
            cartUnits: [
              {
                requestKey: 0,
                itemNo: buyBoxItem.itemNo,
                quantity: 1,
                shippingMethodType: 'General',
                shippingChargePayType: 'PaymentInAdvance',
              },
            ],
            branchServiceType: branchServiceType,
          })
          return cartUnitResponses[0]?.cartUnitId
        },
        async (addedCartUnitId) => {
          await beforeCartUpdate()
          await dispatch(addCartUnits([addedCartUnitId]))
        },
      ).catch((e) => {
        apiErrorHandler(
          new ApiError(
            e.url,
            e.domain,
            e.resultCode,
            e.data,
            formatString(domains.VIP, targetItemNo),
            e.message,
            e.status,
          ),
        )
      })
    },
    [hit, branchServiceType, dispatch],
  )

  const replaceCart = useCallback(
    async (
      fromItemNo: string,
      targetItemNo: string,
      beforeCartUpdate: () => Promise<void> = voidPromise,
    ): Promise<void> => {
      await hit(
        fromItemNo,
        targetItemNo,
        async (buyBox, buyBoxItem) => {
          const { addedCartUnitId } = await fetchReplace({
            add: {
              cartUnits: [
                {
                  requestKey: 0,
                  itemNo: buyBoxItem.itemNo,
                  quantity: 1,
                  shippingMethodType: 'General',
                  shippingChargePayType: 'PaymentInAdvance',
                },
              ],
              branchServiceType: branchServiceType,
            },
            removeCartUnitIds: buyBox.fromCartUnitIds,
          })
          return addedCartUnitId
        },
        async (addedCartUnitId, buyBox) => {
          await beforeCartUpdate()
          await dispatch(
            replaceCartUnits([addedCartUnitId], buyBox.fromCartUnitIds),
          )
        },
      ).catch((e) => {
        apiErrorHandler(
          new ApiError(
            e.url,
            e.domain,
            e.resultCode,
            e.data,
            formatString(domains.VIP, targetItemNo),
            e.message,
            e.status,
          ),
        )
      })
    },
    [hit, branchServiceType, dispatch],
  )

  const clear = useCallback(async (): Promise<void> => {
    const removedBuyBoxes = map(buyBoxes, ({ fromItemNo }) => fromItemNo)

    if (isEmpty(removedBuyBoxes)) {
      return
    }

    dispatch(removeBuyBoxes(removedBuyBoxes))
  }, [buyBoxes, dispatch])

  return [buyBoxes, recommend, addCart, replaceCart, clear]
}

export default useBuyBox
