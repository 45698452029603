import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsUniverseClub,
  getIsUniverseClubNudging,
} from '~/cart/modules/buyer/reducer'
import {
  openAddressBookForFastDelivery,
  openOneClickJoin,
  updateLatestAddress,
} from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import {
  getCurrentDawnDeliveryCutOffTime,
  getLatestShippingAddress,
} from '~/cart/modules/shipping/reducer'
import { LatestShippingAddress } from '~/cart/modules/shipping/types'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import logRender from '~/lib/log-render'
import Montelena from '~/lib/montelena'
import { goSignIn } from '~/lib/utils'
import { CartTabType } from '~/types/enums'

const getAddressViewType = (
  isMember: boolean,
  latestShippingAddress: LatestShippingAddress | undefined,
  currentCartTab: CartTabType,
  isSmileFreshBranchExists: boolean,
):
  | 'None'
  | 'Normal'
  | 'NeedToChange'
  | 'Error'
  | 'NonMember'
  | 'UnableSmileFresh' => {
  if (!isMember) {
    return 'NonMember'
  }
  if (!latestShippingAddress) {
    return 'Error'
  } else {
    if (latestShippingAddress.alterType === 'Normal') {
      if (currentCartTab === 'SmileFresh' && !isSmileFreshBranchExists) {
        return 'UnableSmileFresh'
      }
      return 'Normal'
    } else if (latestShippingAddress.alterType === 'None') {
      return 'None'
    } else {
      return 'NeedToChange'
    }
  }
}

const SmileDeliveryAddressContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )
  const isMember = useSelector(
    (state: RootState) => state.buyer.memberType !== 'NonMember',
  )

  const isDomesticBusinessman = useSelector(
    (state: RootState) => state.buyer.isDomesticBusinessman,
  )
  const isSmileClub = useSelector((state: RootState) => state.buyer.isSmileClub)
  const isUniverseClub = useSelector((state: RootState) =>
    getIsUniverseClub(state.buyer),
  )
  const isUniverseClubNudging = useSelector((state: RootState) =>
    getIsUniverseClubNudging(state),
  )
  const latestShippingAddress = useSelector((state: RootState) =>
    getLatestShippingAddress(state.shipping),
  )
  const isSmileFreshBranchExists = useSelector(
    (state: RootState) => state.smileFresh.availableBranches.length > 0,
  )

  const addressViewType = useMemo(
    () =>
      getAddressViewType(
        isMember,
        latestShippingAddress,
        currentCartTab,
        isSmileFreshBranchExists,
      ),
    [currentCartTab, isMember, isSmileFreshBranchExists, latestShippingAddress],
  )

  const isDawnDelivery = useSelector(
    (state: RootState) => !!getCurrentDawnDeliveryCutOffTime(state.shipping),
  )

  const onClickOpenAddressBook = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(openAddressBookForFastDelivery(e.currentTarget))
    },
    [dispatch],
  )
  const onClickRefreshLatestAddress = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(updateLatestAddress())
    },
    [dispatch],
  )

  const onClickOpenOneClickJoin = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!isMember) {
        goSignIn()
        return
      }
      // TODO 퀵패스 연결
      dispatch(
        openOneClickJoin(
          e.currentTarget,
          currentCartTab === 'SmileFresh' ? 'coupon' : 'free-delivery',
        ),
      )
    },
    [currentCartTab, dispatch, isMember],
  )
  useEffect(() => {
    if (
      isUniverseClubNudging &&
      latestShippingAddress?.alterType === 'Normal' &&
      currentCartTab === 'SmileDelivery' &&
      areaCodes.CLUB_NUDGING_ACTIVED
    ) {
      Montelena.logImpression(
        'IMP_VI',
        areaCodes.CLUB_NUDGING_ACTIVED,
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          club_nudge_tab: currentCartTab,
        },
        'SmileDeliveryAddressContainer',
      )
    }
  }, [
    currentCartTab,
    isDomesticBusinessman,
    isUniverseClub,
    isUniverseClubNudging,
    latestShippingAddress,
  ])

  if (currentCartTab === 'SmileDelivery' || currentCartTab === 'SmileFresh') {
    return (
      <div className="box__smiledelivery-wrap" style={{ display: 'block' }}>
        {addressViewType === 'Normal' && (
          <div className="box__address-info">
            <p className="text__address sprite__cart--after sprite__cart--before">
              {latestShippingAddress && latestShippingAddress.address}
            </p>
            <button
              type="button"
              className="button__address"
              aria-label="주소지 변경하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.CHANGE_LATEST_ADDRESS
                  : areaCodes.CHANGE_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              변경
            </button>
          </div>
        )}
        {addressViewType === 'UnableSmileFresh' && (
          <div className="box__address-info">
            <p className="text__address text__address-unable sprite__cart--before sprite__cart--after">
              <span className="text__emphasis">배송불가</span>{' '}
              {latestShippingAddress && latestShippingAddress.address}
            </p>
            <button
              type="button"
              className="button__address"
              aria-label="주소지 변경하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.CHANGE_LATEST_ADDRESS
                  : areaCodes.CHANGE_UNABLE_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              변경
            </button>
          </div>
        )}
        {addressViewType === 'NeedToChange' && (
          <div className="box__address-info">
            <p className="text__address sprite__cart--before">
              <span className="text__emphasis">배송받을 주소</span>를 변경해
              주세요
            </p>
            <button
              type="button"
              className="button__address button__address-set"
              aria-label="배송지 변경하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.CHANGE_LATEST_ADDRESS
                  : areaCodes.NEED_TO_CHANGE_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              변경하기
            </button>
          </div>
        )}
        {addressViewType === 'None' && (
          <div className="box__address-info">
            <p className="text__address sprite__cart--before">
              <span className="text__emphasis">배송 받을 주소</span>를
              설정해보세요.
            </p>
            <button
              type="button"
              className="button__address"
              aria-label="배송지 추가하기"
              data-montelena-acode={
                currentCartTab === 'SmileDelivery'
                  ? areaCodes.ADD_LATEST_ADDRESS
                  : areaCodes.ADD_SMILE_FRESH_LATEST_ADDRESS
              }
              onClick={onClickOpenAddressBook}
            >
              배송지 추가하기
            </button>
          </div>
        )}
        {addressViewType === 'NonMember' && (
          <div className="box__address-info">
            <p className="text__address sprite__cart--before">
              <span className="text__emphasis">배송받을 주소</span>를
              설정해보세요.
            </p>
            <button
              type="button"
              className="button__address button__address-set"
              aria-label="배송지 설정하기"
              data-montelena-acode={areaCodes.CHANGE_LATEST_ADDRESS_NON_MEMBER}
              onClick={onClickOpenAddressBook}
            >
              설정하기
            </button>
          </div>
        )}
        {addressViewType === 'Error' && (
          <div className="box__address-info">
            <p className="text__address sprite__cart--before">
              주소를 다시 한번 조회해 주세요
            </p>
            <button
              type="button"
              className="button__address button__address-set"
              aria-label="배송지 조회하기"
              onClick={onClickRefreshLatestAddress}
              data-montelena-acode={areaCodes.LATEST_ADDRESS_API_ERROR}
            >
              조회하기
            </button>
          </div>
        )}
        {isDawnDelivery &&
          isSmileClub &&
          latestShippingAddress?.alterType === 'Normal' &&
          currentCartTab === 'SmileDelivery' && (
            <div className="box__smiledelivery box__smiledelivery--smileclub">
              <p className="text__smiledelivery sprite__cart--before">
                클럽은 <span className="sprite__cart--before">새벽도착</span>{' '}
                <span className="sprite__cart--before">추가적립</span>{' '}
                <span className="sprite__cart--before">무료배송</span>
              </p>
            </div>
          )}
        {!(isDomesticBusinessman && !isUniverseClub) &&
          latestShippingAddress?.alterType === 'Normal' &&
          currentCartTab === 'SmileDelivery' && (
            <div
              className={`box__smiledelivery ${
                isUniverseClub ? 'box__smiledelivery--smileclub' : ''
              }`}
            >
              <p className="text__smiledelivery">
                <span className="image__club sprite__cart">
                  <span className="for-a11y">유니버스 클럽 로고</span>
                </span>
                {isUniverseClub && ' 유니버스'} 클럽은 1.5만원 이상{' '}
                {isUniverseClub ? (
                  <strong className="text__emphasis">무료배송!</strong>
                ) : (
                  '무료배송!'
                )}
              </p>
              {!isUniverseClub && (
                <CustomAnchor
                  className="link__free-membershop sprite__cart--after"
                  data-montelena-acode={areaCodes.CLUB_NUDGING_CLICK}
                  data-montelena-club_nudge_tab={currentCartTab}
                  onClick={onClickOpenOneClickJoin}
                >
                  무료체험
                </CustomAnchor>
              )}
            </div>
          )}

        {isUniverseClubNudging &&
          latestShippingAddress?.alterType === 'Normal' &&
          currentCartTab === 'SmileFresh' && (
            <div className="box__smiledelivery">
              <p className="text__smiledelivery">
                <span className="image__club sprite__cart">
                  <span className="for-a11y">유니버스 클럽 로고</span>
                </span>{' '}
                클럽 회원님께만 드리는 15% 쿠폰!
              </p>
              <CustomAnchor
                className="link__free-membershop sprite__cart--after"
                data-montelena-acode={areaCodes.CLUB_NUDGING_CLICK}
                data-montelena-club_nudge_tab={currentCartTab}
                onClick={onClickOpenOneClickJoin}
              >
                무료체험
              </CustomAnchor>
            </div>
          )}
      </div>
    )
  } else {
    return <></>
  }
}

export default SmileDeliveryAddressContainer
