import React, { useEffect, useState } from 'react'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import { SummaryType } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import { SmileDeliveryConditionalFreeBasisCost } from '~/data/consts'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { currencyAmount } from '~/lib/formatter'
import { CartTabType } from '~/types/enums'

const OrderSummaryCore = ({
  summaryInfo,
  currentCartTab,
  smileDeliveryCategoryLinkUrl,
  isUniverseClub,
  isShippingFeeLoadedAfterChanged,
}: {
  summaryInfo: SummaryType
  currentCartTab: CartTabType
  smileDeliveryCategoryLinkUrl?: string
  isUniverseClub: boolean
  isShippingFeeLoadedAfterChanged: boolean
}): JSX.Element => {
  const [loadedShippingFee, setLoadedShippingFee] = useState(0)
  const [gaugeValue, setGaugeValue] = useState(0)
  const [isDiscountDetailOpened, setDiscountDetailOpened] = useState(false)
  const [isShippingFeeDetailOpened, setShippingFeeDetailOpened] = useState(
    false,
  )

  useEffect(() => {
    if (isShippingFeeLoadedAfterChanged) {
      setLoadedShippingFee(summaryInfo.totalShippingFee)
      setGaugeValue(summaryInfo.totalItemPrice)
    }
  }, [
    isShippingFeeLoadedAfterChanged,
    summaryInfo.totalItemPrice,
    summaryInfo.totalShippingFee,
  ])

  return (
    <div className="order_info">
      <ul className="receipt_list_box">
        <li className="list">
          <span className="label">상품수</span>
          <span className="format-amount">
            <strong className="text">{summaryInfo.cartUnitCount}</strong>
            <span className="unit">개</span>
          </span>
        </li>
        <li className="list">
          <span className="label">상품금액</span>
          <FormatPrice krwAmount={summaryInfo.totalItemPrice} />
        </li>
        <li
          className={`list ${
            summaryInfo.totalNegativePrice > 0 ? 'has_child' : ''
          }`}
        >
          {summaryInfo.totalNegativePrice > 0 ? (
            <button
              type="button"
              className={`label ${
                isDiscountDetailOpened ? 'label--active' : ''
              }`}
              onClick={(): void =>
                setDiscountDetailOpened(!isDiscountDetailOpened)
              }
              aria-expanded={isDiscountDetailOpened}
              aria-controls="list_child--discount"
            >
              할인금액
            </button>
          ) : (
            <span className="label">할인금액</span>
          )}
          <span className="format-price discount">
            {summaryInfo.totalDiscountPercent > 0 && (
              <span className="box__discount--amount">
                <strong className="text__value">
                  {summaryInfo.totalDiscountPercent}
                </strong>
                <span className="text__percent">% 할인</span>
              </span>
            )}
            {/*<FormatPrice krwAmount={-1 * summaryInfo.totalNegativePrice} />*/}
            <span className="box__format-amount">
              <span className="text__sign">-</span>
              <strong className="text__value">
                {currencyAmount(Math.abs(-1 * summaryInfo.totalNegativePrice))}
              </strong>
              <span className="text__unit">원</span>
            </span>
          </span>

          {summaryInfo.totalNegativePrice > 0 && isDiscountDetailOpened && (
            <ul className="list_child" id="list_child--discount">
              {summaryInfo.totalBuyerCouponOrEbayDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">G마켓할인</span>
                  <FormatPrice
                    krwAmount={
                      -1 * summaryInfo.totalBuyerCouponOrEbayDiscountPrice
                    }
                  />
                </li>
              )}
              {summaryInfo.totalSellerDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">판매자할인</span>
                  <FormatPrice
                    krwAmount={-1 * summaryInfo.totalSellerDiscountPrice}
                  />
                </li>
              )}
              {summaryInfo.totalCouponPrice -
                summaryInfo.totalBuyerCouponPrice >
                0 && (
                <li className="list_child__item">
                  <span className="label">중복할인</span>
                  <FormatPrice
                    krwAmount={
                      -1 *
                      (summaryInfo.totalCouponPrice -
                        summaryInfo.totalBuyerCouponPrice)
                    }
                  />
                </li>
              )}
              {summaryInfo.totalBundleDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">더 사면 할인</span>
                  <FormatPrice
                    krwAmount={-1 * summaryInfo.totalBundleDiscountPrice}
                  />
                </li>
              )}
              {summaryInfo.totalPartnershipDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">제휴할인</span>
                  <FormatPrice
                    krwAmount={-1 * summaryInfo.totalPartnershipDiscountPrice}
                  />
                </li>
              )}
            </ul>
          )}
        </li>
        {/* TODO 스마일클럽 추가할인 나오면 넣을것*/}
        {/*<li className="list list-smileclub">*/}
        {/*  <span className="label">스마일클럽 추가할인</span>*/}
        {/*  <span className="format-price">*/}
        {/*	<span className="box__format-amount"><span className="text__sign">-</span><strong*/}
        {/*    className="text__value">7,000</strong><span className="text__unit">원</span></span>*/}
        {/*</span>*/}
        {/*</li>*/}
        <li
          className={`list ${
            summaryInfo.totalInternationalShippingFee > 0 ? 'has_child' : ''
          }`}
        >
          {summaryInfo.totalInternationalShippingFee > 0 ? (
            <button
              type="button"
              className={`label ${
                isShippingFeeDetailOpened ? 'label--active' : ''
              }`}
              onClick={(): void =>
                setShippingFeeDetailOpened(!isShippingFeeDetailOpened)
              }
              aria-expanded={isShippingFeeDetailOpened}
              aria-controls="list_child--delivery"
            >
              배송비
            </button>
          ) : (
            <span className="label">배송비</span>
          )}
          {currentCartTab === 'SmileDelivery' &&
            summaryInfo.totalShippingFee > 0 &&
            summaryInfo.totalInternationalShippingFee === 0 && (
              <CustomAnchor
                target="_blank"
                data-montelena-acode={areaCodes.REMOVE_SHIPPING_FEE}
                href={smileDeliveryCategoryLinkUrl}
                className="icon sprite__cart btn_introduce_smiledelivery"
              >
                <span className="for-a11y">배송비 없애기</span>
              </CustomAnchor>
            )}
          <FormatPrice krwAmount={summaryInfo.totalShippingFee} />
          {summaryInfo.totalInternationalShippingFee > 0 &&
            isShippingFeeDetailOpened && (
              <ul className="list_child" id="list_child--delivery">
                {summaryInfo.totalShippingFee -
                  summaryInfo.totalInternationalShippingFee >
                  0 && (
                  <li className="list_child__item">
                    <span className="label">기본배송비</span>
                    <FormatPrice
                      krwAmount={
                        summaryInfo.totalShippingFee -
                        summaryInfo.totalInternationalShippingFee
                      }
                    />
                  </li>
                )}
                <li className="list_child__item">
                  <span className="label">해외배송비</span>
                  <FormatPrice
                    krwAmount={summaryInfo.totalInternationalShippingFee}
                  />
                </li>
              </ul>
            )}
          {isUniverseClub &&
            currentCartTab === 'SmileDelivery' &&
            loadedShippingFee > 0 &&
            SmileDeliveryConditionalFreeBasisCost > gaugeValue && (
              <p className="text__free-delivery">
                {currencyAmount(
                  SmileDeliveryConditionalFreeBasisCost - gaugeValue,
                )}
                원 더 담으면 <span className="text__free">무료배송</span>
              </p>
            )}
        </li>
      </ul>
    </div>
  )
}

export default OrderSummaryCore
