import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { INITIAL_TITLE } from '~/cart/modules/view/reducer'
import Header from '~/lib/components/gmarket/ko/mobile/Header'
import logRender from '~/lib/log-render'

const HeaderContainer = (): JSX.Element => {
  logRender()
  const title = useSelector((state: RootState) => state.view.title)
  const closeUrl = useSelector((state: RootState) => state.view.headerCloseUrl)
  const showingAppHeader = useSelector(
    (state: RootState) => state.view.showingAppHeader,
  )
  const isCoreApp = useSelector(
    (state: RootState) =>
      state.view.isApp && state.view.appInfo?.appType === 'Core',
  )

  return (
    <Header
      showingAppHeader={showingAppHeader}
      initialTitle={INITIAL_TITLE}
      title={title}
      closeUrl={closeUrl}
      isCoreApp={isCoreApp}
    />
  )
}

export default HeaderContainer
