import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openOneClickJoin } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import logRender from '~/lib/log-render'
import Montelena from '~/lib/montelena'
import UXEHelper from '~/lib/uxe-helper'
import { LayerProps } from '~/types/common'

const LayerNudgingSmileClubOneClick = ({
  layerId,
  layerKey,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()
  const buyerName = useSelector((state: RootState) => state.buyer.buyerName)
  const isMember = useSelector(
    (state: RootState) => state.buyer.memberType !== 'NonMember',
  )

  useEffect(() => {
    const showingCount =
      parseInt(
        CookieHelper.getCookie(CookieKeys.NudgingOneClickShowingCount) || '0',
      ) || 0

    // 2번째까지 바로 노출. 3번째 이후 10~60초 랜덤노출
    const showInterval =
      showingCount < 2 ? 0 : (Math.floor(Math.random() * 50) + 10) * 1000

    const key = window.setTimeout(() => {
      CookieHelper.setCookie(
        CookieKeys.NudgingOneClickShowingCount,
        (showingCount + 1).toString(),
        moment().add(1, 'day').toDate(),
      )
      if (areaCodes.ONE_CLICK_BANNER) {
        Montelena.logImpression(
          'IMP_VI',
          areaCodes.ONE_CLICK_BANNER,
          {},
          'LayerSmileClubOneClickJoin',
        )
      }
      UXEHelper.oneClickSmileClubLayerFunc('buttonAction')
    }, showInterval)

    return (): void => {
      window.clearTimeout(key)
      CookieHelper.setCookie(
        CookieKeys.NudgingClubOneClickClosed,
        'true',
        moment().add(1, 'day').toDate(),
      )
    }
  }, [])

  const closeWithAnimation = useCallback((): void => {
    UXEHelper.oneClickSmileClubLayerFunc('close')
    window.setTimeout(() => {
      onCloseLayer()()
    }, 500)
  }, [onCloseLayer])

  const onClickOpenOneClickJoin = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      closeWithAnimation()
      if (isMember) {
        dispatch(openOneClickJoin(e.currentTarget, 'coupon'))
      }
    },
    [closeWithAnimation, dispatch, isMember],
  )

  if (!buyerName) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div id={layerId}>
      <button
        className="button__oneclick-smileclub"
        data-montelena-acode={areaCodes.ONE_CLICK_BANNER}
        onClick={onClickOpenOneClickJoin}
      >
        <div className="box__scene-init sprite__oneclick--after">
          <span className="text__title">{`${buyerName}님을 위한 혜택`}</span>
          <span className="text__message">
            지금 <span className="text__emphasis">15% 할인쿠폰</span> 받고
            주문하세요.
          </span>
        </div>
        <div className="box__scene-change">
          <span className="text__message">
            <span className="text__emphasis">15% 할인</span> 받으세요!
          </span>
        </div>
        <span
          id="js-player__oneclick-count"
          className="image__oneclick-count"
        />
      </button>
    </div>
  )
}

export default LayerNudgingSmileClubOneClick
