import _ from 'lodash'
import React, { useEffect } from 'react'
import { useCouponBoxToast } from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToastProvider'
import { CouponBoxToastId } from '~/cart/modules/unit-coupon/types'

type CouponBoxToastProps = {
  id: CouponBoxToastId
  autoClose?: boolean
  onToast?: (id: CouponBoxToastId) => void
  children: React.ReactNode
}

const CouponBoxToast = ({
  id,
  autoClose = false,
  onToast,
  children,
}: CouponBoxToastProps) => {
  const { toastIds, clearToast } = useCouponBoxToast()
  const shown = toastIds.includes(id)
  const first = _.first(toastIds) === id

  useEffect(() => {
    if (shown && onToast) {
      onToast(id)
    }
  }, [id, onToast, shown])

  useEffect(() => {
    if (shown && autoClose) {
      setTimeout(() => clearToast(id), 2500)
    }
  }, [shown, autoClose, id, clearToast])

  return (
    <div
      id={id}
      className={`box__layer-coupon-toast ${
        shown ? (first ? 'js-fadein' : 'js-fadein2') : ''
      }`}
      inert={shown ? undefined : ''}
    >
      {children}
    </div>
  )
}

export default CouponBoxToast
