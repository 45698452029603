import React, { useMemo } from 'react'
import { useBuyBoxContext } from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxContext'
import useLoadingDots from '~/cart/hooks/useLoadingDots'
import useOnMountImpression from '~/cart/hooks/useOnMountImpression'
import areaCodes from '~/data/areaCodes'

const BuyBoxPending = (): JSX.Element => {
  const type = 'dot_mix_48'
  const [ref] = useLoadingDots(type)
  const { tab } = useBuyBoxContext()

  const areaCode = useMemo(() => {
    const code = areaCodes?.PENDING_BUY_BOX ?? {}

    return code[tab] ?? ''
  }, [tab])

  useOnMountImpression({ areaCode, areaValues: {}, source: 'BuyBox_Pending' })

  return (
    <div className="section box__buybox-loading">
      <i className={`image__buybox-loading ${type}`} ref={ref} />
    </div>
  )
}

export default React.memo(BuyBoxPending)
