import React, { useCallback, useEffect } from 'react'
import AutoCouponToast from '~/cart/gmarket/ko/common/components/CouponBox/AutoCouponToast'
import CouponBoxToast from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToast'
import { useCouponBoxToast } from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToastProvider'
import FailCouponToast from '~/cart/gmarket/ko/common/components/CouponBox/FailCouponToast'
import useUnitCouponDownloading from '~/cart/hooks/useUnitCouponDownloading'
import { CouponDownloadMode } from '~/cart/modules/types'
import {
  DownloadableUnitCouponFailedLifeCycle,
  DownloadableUnitCouponFailedReason,
} from '~/cart/modules/unit-coupon/types'

const findPermanentFailedAlertMessage = (
  reason: DownloadableUnitCouponFailedReason,
): string => {
  switch (reason) {
    case 'event-expired':
      return '죄송합니다.\n이벤트가 종료 되어 쿠폰이 다운로드 되지 않았습니다.'
    case 'lack-of-issue-pool':
      return '죄송합니다.\n수량이 모두 소진 되어 쿠폰이 다운로드 되지 않았습니다.'
    case 'lack-of-number':
      return '죄송합니다.\n다운로드 가능한 쿠폰 수량이 초과되어 쿠폰이 다운로드 되지 않았습니다.'
    case 'period-of-event-expired':
      return '죄송합니다.\n이벤트 기간이 종료 되어 쿠폰이 다운로드 되지 않았습니다.'
    case 'time-of-event-expired':
      return '죄송합니다.\n이벤트 시간이 종료 되어 쿠폰이 다운로드 되지 않았습니다.'
    case 'over-try':
      return '죄송합니다.\n계속된 쿠폰 다운로드 시도로 금일 쿠폰 다운로드가 불가 합니다.'
    case 'only-korean':
      return '죄송합니다.\n내국인 회원만 다운로드 가능합니다.'
    case 'only-foreigner':
      return '죄송합니다.\n외국인 회원만 다운로드 가능합니다.'
    case 'identity-verification-required':
      return '죄송합니다.\n본인인증 후 다운로드 가능합니다.'
    case 'only-club':
      return '죄송합니다.\n유니버스 클럽 회원만 쿠폰 다운로드 가능합니다.'
  }
  return '죄송합니다.\n쿠폰이 다운로드 되지 않았습니다. 고객센터로 문의 부탁 드립니다.'
}

const CouponDownloadingContainer = ({
  cartUnitId,
  children,
}: {
  cartUnitId: number
  children: React.ReactNode
}): JSX.Element => {
  const { openToast, clearToast } = useCouponBoxToast()

  const succeeded = useCallback(
    (autoSuccess: boolean) => {
      if (autoSuccess) {
        openToast('auto-coupon')
        return
      }
      clearToast('fail-coupon')
    },
    [openToast, clearToast],
  )

  const failed = useCallback(
    (
      reason?: DownloadableUnitCouponFailedReason,
      lifeCycle?: DownloadableUnitCouponFailedLifeCycle,
    ) => {
      if (lifeCycle === 'permanent') {
        alert(findPermanentFailedAlertMessage(reason))
      } else {
        openToast('fail-coupon')
      }
    },
    [openToast],
  )

  const {
    downloadTargetUnitCouponList,
    onDownloadCoupon,
  } = useUnitCouponDownloading(cartUnitId, succeeded, failed)

  useEffect(() => {
    if (downloadTargetUnitCouponList.length > 0) {
      // 자동 다운로드
      onDownloadCoupon(2, CouponDownloadMode.Auto)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onManualDownloadCoupon = useCallback(async () => {
    // 수동 다운로드
    onDownloadCoupon(10, CouponDownloadMode.Manual)
  }, [onDownloadCoupon])

  return (
    <>
      {children}
      <AutoCouponToast />
      <FailCouponToast onClick={onManualDownloadCoupon} />

      <CouponBoxToast id="go-checkout" autoClose={true}>
        <p className="text">주문서에서 결제 시 적용 가능해요!</p>
      </CouponBoxToast>
    </>
  )
}

export default CouponDownloadingContainer
