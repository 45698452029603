import { useEffect } from 'react'
import useImpression, {
  Impression,
  ImpressionDataProps,
} from '~/cart/hooks/useImpression'

const useOnMountImpression = (
  impression: Impression,
): [() => void, ImpressionDataProps] => {
  const [log, dataProps] = useImpression(impression)

  useEffect(() => {
    log()
  }, [log])

  // use it when you want to
  return [log, dataProps]
}

export default useOnMountImpression
