import React from 'react'
import { currencyAmount } from '~/lib/formatter'

const FormatPrice = ({
  krwAmount,
  rootClass,
  showSign,
}: {
  krwAmount: number
  rootClass?: string
  showSign?: boolean
}): JSX.Element => {
  const signComponent =
    (showSign && krwAmount !== 0) || krwAmount < 0 ? (
      <span className="text__sign">{krwAmount > 0 ? '+' : '-'}</span>
    ) : undefined
  const viewAmount = Math.abs(krwAmount)
  return (
    <span className={rootClass || 'format-price'}>
      <span className="box__format-amount">
        {signComponent}
        <strong className="text__value">{currencyAmount(viewAmount)}</strong>
        <span className="text__unit">원</span>
      </span>
    </span>
  )
}

export default FormatPrice
