import useImpression, {
  Impression,
  ImpressionDataProps,
} from '~/cart/hooks/useImpression'
import { BuyBox } from '~/cart/modules/recommend/types'
import areaCodes, { AreaCodeMap } from '~/data/areaCodes'
import areaValues from '~/data/areaValues'
import useDeepCompareMemo from '~/lib/deep-compare-hook/useDeepCompareMemo'
import { CartTabType } from '~/types/enums'

type BuyBoxImpressionTarget = 'Card' | 'AddCartButton' | 'SelectOptionButton'

const targetAreaCodes: {
  [key in BuyBoxImpressionTarget]: AreaCodeMap
} = {
  Card: areaCodes?.BUY_BOX_CARD ?? {},
  AddCartButton: areaCodes?.BUY_BOX_WITH_ADD_CART ?? {},
  SelectOptionButton: areaCodes?.BUY_BOX_WITH_SELECT_OPTION ?? {},
}

const useBuyBoxImpression = (
  target: BuyBoxImpressionTarget,
  values: {
    tab: CartTabType
    itemNo: string
    buyBox: BuyBox
  },
  source: string,
): [() => void, ImpressionDataProps] => {
  const imp: Impression = useDeepCompareMemo(() => {
    const map = targetAreaCodes[target]
    const buyBoxType = areaValues?.BUY_BOX_TYPE ?? {}
    const { tab, itemNo, buyBox } = values
    return {
      areaCode: map[tab] ?? '',
      areaValues: {
        ['goodscode']: itemNo,
        ['target_goodscode']: buyBox.fromItemNo,
        ['buyboxtype']: buyBoxType[buyBox.type] ?? '',
      },
      source: source,
    }
  }, [target, values, source])

  const [logImp, dataProps] = useImpression(imp)

  return [logImp, dataProps]
}

export default useBuyBoxImpression
