import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { openSmileClubJoin } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

const LayerSmileClubOneClickJoin = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const [timeoutKey, setTimeoutKey] = useState<number>()
  const [isShowing, setShowing] = useState(false)

  useEffect(() => {
    window.setTimeout(() => {
      setShowing(true)
    })
  }, [])

  const closeWithAnimation = useCallback((): void => {
    setShowing(false)
    // 레이어 열고 닫는게 뭐 이리 제각각이냐...
    setTimeoutKey(
      window.setTimeout(() => {
        onCloseLayer()()
      }, 500),
    )
  }, [onCloseLayer])

  // 닫을때 timeout clear 해줘야함
  useEffect(() => {
    return (): void => {
      if (timeoutKey) {
        window.clearTimeout(timeoutKey)
      }
    }
  }, [timeoutKey])

  const onClickJoinSmileClub = useCallback(
    async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
      closeWithAnimation()
      dispatch(openSmileClubJoin(e.currentTarget))
    },
    [closeWithAnimation, dispatch],
  )

  return (
    <div id={layerId} className="box__layer_smileclub-dawn" role="dialog">
      <div
        className={`box__layer-smileclub ${
          isShowing ? 'box__layer-smileclub--active' : ''
        }`}
      >
        <div className="box__content">
          <button
            type="button"
            className="button__closed"
            onClick={closeWithAnimation}
          >
            <span className="sprite__oneclick">닫기</span>
          </button>
          <p className="text__smileclub">
            <em>
              스마일클럽 무료 이용하고
              <br />
              바로 <em className="text__line">새벽배송</em> 받아보세요
            </em>
            새벽배송은 서울 전 지역 가능합니다
          </p>
          <button
            className="button__join"
            onClick={onClickJoinSmileClub}
            data-montelena-acode={areaCodes.FAST_DELIVERY_CLUB_NUDGING_CONFIRM}
          >
            스마일클럽 무료이용 시작하기
          </button>
        </div>
      </div>
    </div>
  )
}

export default LayerSmileClubOneClickJoin
