import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Toast from '~/cart/gmarket/ko/common/components/Toast'
import { setIsAppliedCouponQuantityChanged } from '~/cart/modules/cart/actions'
import { getIsAppliedCouponQuantityChanged } from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

const CouponReApplyNotiToast = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  // 수량변경하려고 할때, 적용된 쿠폰이 있는 경우
  const isAppliedCouponQuantityChanged = useSelector((state: RootState) =>
    getIsAppliedCouponQuantityChanged(state.cart),
  )

  return (
    <Toast
      className="box__toast-auto-init"
      show={isAppliedCouponQuantityChanged}
      onClose={(): void => {
        dispatch(setIsAppliedCouponQuantityChanged(false))
      }}
    >
      <p className="text__message">쿠폰을 다시 적용해 주세요.</p>
    </Toast>
  )
}

export default React.memo(CouponReApplyNotiToast)
