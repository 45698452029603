import React from 'react'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

const LayerConfirmMaxInstallOrder = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()

  return (
    <>
      <div
        className="box__layer box__layer--alert uxelayer"
        id={layerId}
        role="dialog"
        aria-modal="true"
        style={{ display: 'block', position: 'absolute' }}
      >
        <div className="box__layer-content">
          <div className="box__layer-body">
            <p className="text__title">예약설치 상품 주문 안내</p>
            <p className="text__message">
              최대 5개까지 주문할 수 있어요.
              <br />
              나머지 상품은 따로 주문해 주세요.
            </p>
            <div className="box__button">
              <button className="button__confirm" onClick={onCloseLayer()}>
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="dimmed"
        style={{ position: 'fixed' }}
        onClick={onCloseLayer()}
      />
    </>
  )
}

export default LayerConfirmMaxInstallOrder
