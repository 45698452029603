import _ from 'lodash'
import { useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import {
  getCurrentCartTree,
  getCurrentCartUnitList,
} from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'

/**
 * 현재 탭의 장바구니 내 정렬된 상태들을 다루는 hook
 * @return [
 *   스크린에 렌더링된 순서대로의 cartUnitId 목록,
 *   생성된 순서대로의 cartUnitId 목록,
 *   현재 장바구니의 상품번호 목록
 *   ]
 */
const useSortedCart = (): [number[], number[], string[]] => {
  const sellerGroups = useSelector(
    (state: RootState) => getCurrentCartTree(state.cart).sellerGroups,
    isEqual,
  )

  const sortedCartUnitIdsInView = useMemo(
    () => sellerGroups.flatMap(({ cartUnitIdList }) => cartUnitIdList),
    [sellerGroups],
  )

  const sortedByCreatedCartUnitIds = useSelector(
    (state: RootState) =>
      _(getCurrentCartUnitList(state.cart))
        .orderBy(['insertDateISO'], ['desc'])
        .map(({ cartUnitId }) => cartUnitId)
        .value(),
    isEqual,
  )

  const itemNos = useMemo(
    () =>
      sellerGroups
        .flatMap(({ shippingGroups }) => shippingGroups)
        .flatMap(({ itemGroups }) => itemGroups)
        .map(({ key }) => key),
    [sellerGroups],
  )

  return [sortedCartUnitIdsInView, sortedByCreatedCartUnitIds, itemNos]
}

export default useSortedCart
