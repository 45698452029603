import _, { Collection, isEmpty, map, max, uniq } from 'lodash'
import { useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import useHitBuyBox from '~/cart/hooks/useHitBuyBox'
import useSortedCart from '~/cart/hooks/useSortedCart'
import { getCurrentCartUnitList } from '~/cart/modules/cart/reducer'
import {
  CartUnit,
  CartUnitPriceType,
  UnableOrderType,
} from '~/cart/modules/cart/types'
import { BuyBoxReason, BuyBoxType } from '~/cart/modules/recommend/types'
import { getCartUnitPriceInfo, RootState } from '~/cart/modules/reducers'
import useDeepCompareMemo from '~/lib/deep-compare-hook/useDeepCompareMemo'
import { isHighPriority } from '~/lib/utils'

export interface BuyBoxTarget {
  itemNo: string
  cartUnitIds: number[]
  epin: number
  type: BuyBoxType
  reasons: BuyBoxReason[]
}

const buyBoxReasonMap: {
  [key: string]: BuyBoxReason
} = {
  ITEM_STOP_SELLING: 'StopSelling',
  ITEM_OUT_OF_STOCK: 'OutOfStock',
  OPTION_OUT_OF_STOCK: 'OptionOutOfStock',
  ADDITION_OUT_OF_STOCK: 'AdditionOutOfStock',
}

const buyBoxTypeMap: {
  [key in BuyBoxReason]: BuyBoxType
} = {
  ForSale: 'GoodDeal',
  StopSelling: 'SoldOut',
  OutOfStock: 'SoldOut',
  OptionOutOfStock: 'SoldOut',
  AdditionOutOfStock: 'SoldOut',
}

const typePriorities: BuyBoxType[] = ['GoodDeal', 'SoldOut']

const getBuyBoxReason = (unableOrderType?: UnableOrderType): BuyBoxReason => {
  if (unableOrderType) {
    return buyBoxReasonMap[unableOrderType]
  }
  return 'ForSale'
}

const isHigherType = (lhs: BuyBoxType, rhs: BuyBoxType): boolean =>
  isHighPriority(typePriorities, lhs, rhs)

const isPCSApplied = (price: CartUnitPriceType): boolean => {
  return price.cartUnitPartnershipDiscountPrice > 0
}

const isTarget =
  (state: RootState, excepts: string[]) =>
  ({ cartUnitId, unableOrderType, item, cartUnitType }: CartUnit): boolean => {
    const { itemNo, epin = 0 } = item

    return !(
      cartUnitId <= 0 ||
      isEmpty(itemNo) ||
      epin <= 0 ||
      !getBuyBoxReason(unableOrderType) ||
      excepts.includes(itemNo) ||
      cartUnitType === 'SmileFresh' ||
      isPCSApplied(getCartUnitPriceInfo(state, cartUnitId))
    )
  }

const mapToBuyBoxTarget = ({
  cartUnitId,
  unableOrderType,
  item,
}: CartUnit): BuyBoxTarget => {
  const { itemNo, epin } = item
  const reason = getBuyBoxReason(unableOrderType)
  const type = buyBoxTypeMap[reason]
  return {
    itemNo,
    cartUnitIds: [cartUnitId],
    epin,
    type,
    reasons: [reason],
  } as BuyBoxTarget
}

const getReducedTargetsOnSameType = (
  grouped: BuyBoxTarget[],
): Collection<BuyBoxTarget> =>
  _(grouped)
    .groupBy(({ type }) => type)
    .map((typed) => ({
      ...typed[0],
      cartUnitIds: typed.flatMap(({ cartUnitIds }) => cartUnitIds),
      reasons: typed.flatMap(({ reasons }) => reasons),
    }))

const reduceToHigherType = (
  acc: BuyBoxTarget,
  curr: BuyBoxTarget,
): BuyBoxTarget => (isHigherType(acc.type, curr.type) ? acc : curr)

const byTypePriorityAndLatestCartUnit =
  (sortedCartUnits: number[]) =>
  (lhs: BuyBoxTarget, rhs: BuyBoxTarget): number => {
    if (lhs.type === rhs.type) {
      const lCart = sortedCartUnits.indexOf(max(lhs.cartUnitIds) ?? 0)
      const rCart = sortedCartUnits.indexOf(max(rhs.cartUnitIds) ?? 0)
      return lCart - rCart
    }

    if (isHigherType(lhs.type, rhs.type)) {
      return -1
    }
    return 1
  }

/**
 * 바이박스 타겟 목록 획득
 *  현재 담긴 장바구니 유닛 목록 중
 *  filter / map -->
 *  + 정상 장바구니 유닛 항목
 *  + epin 값이 존재
 *  + 정상 판매중인 상품(GoodDeal)
 *  + 판매중지 / 본품 / 옵션 / 추가구성 상품의 품절(SoldOut)
 *  - 바이박스로 추천된 건 혹은 바이박스로 장바구니 담기된 건은 제외
 *  - 스마일프레시는 제외
 *  - PCS 할인 적용된 건은 제외
 *
 *  groupBy / map / reduce --> 바이박스 타입별(GoodDeal, SoldOut)로는 1개의 바이박스로 reduce
 *    ex) 100001234 상품에 옵션선택으로 2개의 유닛이 장바구니에 담겨있고, 이 중 하나의 옵션이 주문불가 상태인 경우,
 *        이 상품에 추천되는 바이박스 중(GoodDeal, SoldOut) SoldOut 타입으로 지정
 *        바이박스 노출도 판매중인 유닛에만 노출
 *
 *  sort -->
 *   1.바이박스 타입 우선순위(GoodDeal < SoldOut)
 *   2. 대상 장바구니유닛번호 중 대표번호(max)의 장바구니에 담긴 순서(sortedCartUnits)로 정렬
 */
const getBuyBoxTargets =
  (excepts: string[], sortedCartUnits: number[]) =>
  (state: RootState): BuyBoxTarget[] =>
    _(getCurrentCartUnitList(state.cart))
      .filter(isTarget(state, excepts))
      .map(mapToBuyBoxTarget)
      .groupBy(({ itemNo }) => itemNo)
      .map((grouped) =>
        getReducedTargetsOnSameType(grouped).reduce(
          reduceToHigherType,
          grouped[0],
        ),
      )
      .sort(byTypePriorityAndLatestCartUnit(sortedCartUnits))
      .value()

/**
 * 바이박스 호출 대상 / 제한 목록 분리
 *  호출 대상 : 동일 epin 상품중 가장 최근에 담김 + 호출 가능 목록 수 내의 타겟
 *  호출 제한 : 동일 epin 상품중 가장 최근에 담긴 것 외 + 호출 가능 목록 수를 초과한 건. 바이박스 api 호출 제한됨
 */
const separateToRequestAndRestrain =
  (numberOfTarget = 10) =>
  (sortedAll: BuyBoxTarget[]): [BuyBoxTarget[], BuyBoxTarget[]] =>
    sortedAll.reduce(
      (acc, curr) => {
        const [requested, restrained] = acc
        if (
          requested.length >= numberOfTarget ||
          requested.some(({ epin }) => epin === curr.epin)
        ) {
          return [requested, [...restrained, curr]]
        }

        return [[...requested, curr], restrained]
      },
      [[] as BuyBoxTarget[], [] as BuyBoxTarget[]],
    )

/**
 * 바이박스 타겟 hook
 * @return [바이박스 타겟 목록, 바이박스 요청 제한 목록]
 */
const useBuyBoxTarget = (): [BuyBoxTarget[], BuyBoxTarget[]] => {
  const buyBoxes = useSelector((state: RootState) => state.recommend.buyBoxes)

  const [, hitBuyBoxItems, hitFromItems] = useHitBuyBox()

  const alreadyRecommended = useMemo(() => {
    return uniq([
      ...map(buyBoxes, (_, key) => key),
      ...hitBuyBoxItems,
      ...hitFromItems,
    ])
  }, [buyBoxes, hitBuyBoxItems, hitFromItems])

  const [, cartUnitsOrderByLatestCreated] = useSortedCart()

  const allTargets = useSelector(
    getBuyBoxTargets(alreadyRecommended, cartUnitsOrderByLatestCreated),
    isEqual,
  )

  return useDeepCompareMemo(
    () => separateToRequestAndRestrain()(allTargets),
    [allTargets],
  )
}

export default useBuyBoxTarget
