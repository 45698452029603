import { DependencyList, useCallback } from 'react'
import useDeepCompareDeps from '~/lib/deep-compare-hook/useDeepCompareDeps'

/**
 * Non-primitive type deps 에 대해 deep compare 를 하는 useCallback hook 입니다.
 * deps 가 Non-primitive type(Object, Function, Array) 일 때만 사용하는 것이 좋습니다.
 * @param callback
 * @param deps
 */
const useDeepCompareCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
): T => {
  return useCallback(callback, useDeepCompareDeps(deps))
}

export default useDeepCompareCallback
