import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useIntersectionObserver from '~/cart/hooks/useIntersectionObserver'
import { callBackCartBtVimp } from '~/cart/modules/complex-actions'
import { FullFilledBtRecommendItem } from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { currencyAmount, formatString } from '~/lib/formatter'
import { CartBtViewType } from '~/types/enums'

const CartBtRecommendItemList = ({
  item,
  btViewType,
  onClickGoVIP,
  onClickAddCart,
}: {
  item: FullFilledBtRecommendItem
  btViewType: CartBtViewType // areacode 삽입시 분기용
  onClickGoVIP: (
    itemNo: string,
    isAdvertisement: boolean,
    clickSecureUrl: string,
  ) => React.MouseEventHandler<HTMLElement>
  onClickAddCart: (
    itemNo: string,
    isAdvertisement: boolean,
    clickSecureUrl: string,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const { addTarget } = useIntersectionObserver({
    rootMargin: '0px',
    threshold: 1,
  })

  const targetRef = useCallback(
    (element: HTMLElement | null) => {
      if (element) {
        addTarget({
          key: item.itemNo,
          element,
          callback: async () => {
            if (item.viewableImpressionSecureUrl) {
              dispatch(callBackCartBtVimp(item.viewableImpressionSecureUrl))
            }
          },
        })
      }
    },
    [addTarget, dispatch, item.itemNo, item.viewableImpressionSecureUrl],
  )

  return (
    <li className="list-item">
      <CustomAnchor
        onClick={onClickGoVIP(
          item.itemNo,
          item.isAdvertisement,
          item.clickSecureUrl || '',
        )}
        href={formatString(domains.VIP, item.itemNo)}
        className="link"
        data-montelena-goodscode={item.itemNo}
        data-montelena-gdlc_cd={item.largeCategoryCode}
        data-montelena-gdmc_cd={item.mediumCategoryCode}
        data-montelena-gdsc_cd={item.smallCategoryCode}
        data-montelena-imp_secure_url={
          item.isAdvertisement
            ? item.impressionSecureUrl +
              '&ref=' +
              encodeURIComponent(domains.CART)
            : ''
        }
        data-montelena-click_secure_url={
          item.isAdvertisement
            ? item.clickSecureUrl + '&ref=' + encodeURIComponent(domains.CART)
            : ''
        }
        data-montelena-servicename={
          item.isAdvertisement ? 'CPC광고' : 'Organic상품'
        }
        data-montelena-acode={
          btViewType === 'TypeA'
            ? areaCodes.RECOMMEND_ITEM_INFO_TYPE_A
            : areaCodes.RECOMMEND_ITEM_INFO_TYPE_B
        }
      >
        <div className="box__thumb">
          {item.isAdvertisement && (
            <span className="box__ad-label sprite__cart--after">
              <span className="for-a11y">광고</span>
            </span>
          )}
          <span className="box__thumb-image">
            <img
              ref={targetRef}
              src={item.imageUrl}
              className="image"
              alt={item.itemName}
              onError={(e): void => {
                e.currentTarget.src = domains.ITEM_NO_IMAGE
              }}
            />
          </span>
        </div>
        <div className="box__item-info">
          <span className="text__price">
            <em className="text__emphasis">{currencyAmount(item.itemPrice)}</em>
            원
          </span>
          <span id={`bt_item_${item.itemNo}`} className="text__title">
            {item.itemName}
          </span>
        </div>
      </CustomAnchor>
      {item.isOptionUse ? (
        <CustomAnchor
          href={formatString(domains.VIP, item.itemNo)}
          target="_blank"
          className="button__cart"
          aria-describedby={`bt_item_${item.itemNo}`}
          data-montelena-goodscode={item.itemNo}
          data-montelena-gdlc_cd={item.largeCategoryCode}
          data-montelena-gdmc_cd={item.mediumCategoryCode}
          data-montelena-gdsc_cd={item.smallCategoryCode}
          data-montelena-servicename={
            item.isAdvertisement ? 'CPC광고' : 'Organic상품'
          }
          data-montelena-acode={
            btViewType === 'TypeA'
              ? areaCodes.SELECT_RECOMMEND_ITEM_OPTION_TYPE_A
              : areaCodes.SELECT_RECOMMEND_ITEM_OPTION_TYPE_B
          }
        >
          <span className="text">옵션선택</span>
        </CustomAnchor>
      ) : (
        <button
          type="button"
          className="button__cart"
          aria-describedby={`bt_item_${item.itemNo}`}
          onClick={onClickAddCart(
            item.itemNo,
            item.isAdvertisement,
            item.clickSecureUrl || '',
          )}
          data-montelena-goodscode={item.itemNo}
          data-montelena-gdlc_cd={item.largeCategoryCode}
          data-montelena-gdmc_cd={item.mediumCategoryCode}
          data-montelena-gdsc_cd={item.smallCategoryCode}
          data-montelena-servicename={
            item.isAdvertisement ? 'CPC광고' : 'Organic상품'
          }
          data-montelena-acode={
            btViewType === 'TypeA'
              ? areaCodes.ADD_TO_CART_RECOMMEND_ITEM_TYPE_A
              : areaCodes.ADD_TO_CART_RECOMMEND_ITEM_TYPE_B
          }
        >
          <span className="text sprite__cart--before">담기</span>
        </button>
      )}
    </li>
  )
}

export default CartBtRecommendItemList
