import React, { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OverseaShippingCompanyComponent from '~/cart/gmarket/ko/common/components/OrderSummary/OverseaShippingCompanyComponent'
import { getCartUnitIdList } from '~/cart/modules/cart/reducer'
import {
  openStaticPageViaGateway,
  selectOverseaShippingCompanyComplex,
} from '~/cart/modules/complex-actions'
import { getSelectedCartUnitList, RootState } from '~/cart/modules/reducers'
import {
  getOverseaShippingCompaniesWithCost,
  getShippingCountry,
} from '~/cart/modules/shipping/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import EnumConverter from '~/lib/enum-converter'
import logRender from '~/lib/log-render'
import { EnumGatewayType, OverseaShippingCompanyType } from '~/types/enums'

const OverseaShippingCompanyContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const selectedCartUnitIdList = useSelector((state: RootState) =>
    getSelectedCartUnitList(state).map((cartUnit) => cartUnit.cartUnitId),
  )
  const shippingCompanies = useSelector(
    (state: RootState) =>
      getOverseaShippingCompaniesWithCost(
        state.shipping,
        getCartUnitIdList(state.cart),
        selectedCartUnitIdList,
      ),
    isEqual,
  )
  const selectedOverseaShippingCompany = useSelector(
    (state: RootState) => state.shipping.selectedOverseaShippingCompany,
  )

  const shippingCountry = useSelector(
    (state: RootState) => getShippingCountry(state.shipping),
    shallowEqual,
  )

  const onChangeShippingCompany = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        selectOverseaShippingCompanyComplex(
          EnumConverter.toUnionType<OverseaShippingCompanyType>(
            e.currentTarget.value,
          ),
        ),
      )
    },
    [dispatch],
  )
  const onClickOpenSFExpressNotice = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(
        openStaticPageViaGateway(
          e.currentTarget,
          'SF Express 이용안내',
          'SFExpressNotice',
          EnumGatewayType.SmallStaticPage,
          undefined,
          undefined,
          shippingCountry.countryType,
          shippingCountry.countryName,
        ),
      )
    },
    [dispatch, shippingCountry.countryName, shippingCountry.countryType],
  )

  return (
    <OverseaShippingCompanyComponent
      shippingCompanies={shippingCompanies}
      selectedOverseaShippingCompany={selectedOverseaShippingCompany}
      selectedCartUnitIdList={selectedCartUnitIdList}
      shippingCountry={shippingCountry}
      isMobile={isMobile}
      onChangeShippingCompany={onChangeShippingCompany}
      onClickOpenSFExpressNotice={onClickOpenSFExpressNotice}
    />
  )
}

export default OverseaShippingCompanyContainer
