import React, { DependencyList } from 'react'
import isEqual from 'react-fast-compare'

const useDeepCompareDeps = (deps: DependencyList): DependencyList => {
  const ref = React.useRef<DependencyList>([])

  if (!isEqual(deps, ref.current)) {
    ref.current = deps
  }

  return ref.current
}

export default useDeepCompareDeps
