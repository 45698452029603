import React from 'react'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import OrderSummaryBenefit from '~/cart/gmarket/ko/common/components/OrderSummary/OrderSummaryBenefit'
import OrderSummaryCore from '~/cart/gmarket/ko/common/components/OrderSummary/OrderSummaryCore'
import OrderSummaryTitleContainer from '~/cart/gmarket/ko/common/containers/OrderSummary/OrderSummaryTitleContainer'
import OverseaShippingSummaryContainer from '~/cart/gmarket/ko/common/containers/OrderSummary/OverseaShippingSummaryContainer'
import SmileDeliveryCutOffTimer from '~/cart/gmarket/ko/common/containers/SmileDelivery/SmileDeliveryCutOffTimer'
import PCSmileDeliveryAddressContainer from '~/cart/gmarket/ko/pc/containers/SmileDelivery/SmileDeliveryAddressContainer'
import { SummaryType, TotalCashbackSummaryType } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import { currencyAmount } from '~/lib/formatter'
import { CartTabType } from '~/types/enums'

const OrderSummary = ({
  isMobile,
  isOverseaShipping,
  isShowingCashbackBenefit,
  isUniverseClub,
  isShippingFeeLoadedAfterChanged,
  currentCartTab,
  summaryInfo,
  cashbackSummary,
  smileDeliveryCategoryLinkUrl,
  onClickGoCheckout,
}: {
  isMobile: boolean
  isOverseaShipping: boolean
  isShowingCashbackBenefit: boolean
  currentCartTab: CartTabType
  isUniverseClub: boolean
  isShippingFeeLoadedAfterChanged: boolean
  summaryInfo: SummaryType
  cashbackSummary: TotalCashbackSummaryType
  smileDeliveryCategoryLinkUrl?: string
  onClickGoCheckout: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div id="cart_order" className="cart_total">
      <h3 className="for_a11y">최종 결제정보</h3>
      <div className="order">
        {isMobile ? (
          <OrderSummaryTitleContainer />
        ) : (
          <PCSmileDeliveryAddressContainer />
        )}
        {isOverseaShipping && <OverseaShippingSummaryContainer />}
        <OrderSummaryCore
          summaryInfo={summaryInfo}
          currentCartTab={currentCartTab}
          smileDeliveryCategoryLinkUrl={smileDeliveryCategoryLinkUrl}
          isUniverseClub={isUniverseClub}
          isShippingFeeLoadedAfterChanged={isShippingFeeLoadedAfterChanged}
        />
        <div className="order_summary">
          <strong className="label">총 결제금액</strong>
          <FormatPrice krwAmount={summaryInfo.totalPrice} />
        </div>
        {isShowingCashbackBenefit && (
          <OrderSummaryBenefit
            cashbackSummary={cashbackSummary}
            currentCartTab={currentCartTab}
          />
        )}
        <SmileDeliveryCutOffTimer />
        <div className="order_action fixed">
          <div className="flex_wrap">
            <div className="flex add_line">
              <div className="summary">
                <span className="summary__sale--amount">
                  <span className="box__sale-amount">
                    <span className="text__sale--value">
                      {currencyAmount(summaryInfo.totalNegativePrice)}
                    </span>
                    <span className="text__unit">원</span>
                    <span className="text__sale--summary"> 할인 적용 중</span>
                  </span>
                </span>
                <FormatPrice krwAmount={summaryInfo.totalPrice} />
              </div>
            </div>
            <div className="flex">
              <button
                className="btn_submit"
                onClick={onClickGoCheckout}
                data-montelena-acode={areaCodes.GO_ORDER}
              >
                <span className="text">구매하기</span>
                <span className="item--qty">
                  {summaryInfo.cartUnitCount}
                  <span className="for-a11y">개 상품</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary
