import React, { createContext, useCallback, useContext, useState } from 'react'
import { CouponBoxToastId } from '~/cart/modules/unit-coupon/types'

type CouponBoxToastProviderProps = {
  children: React.ReactNode
}

export type CouponBoxToastContextProps = {
  toastIds: CouponBoxToastId[]
  openToast: (id: CouponBoxToastId) => void
  clearToast: (id: CouponBoxToastId) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const empty = () => {
  /* empty */
}

export const CouponBoxToastContext = createContext<CouponBoxToastContextProps>({
  toastIds: [],
  openToast: empty,
  clearToast: empty,
})

const CouponBoxToastProvider = ({ children }: CouponBoxToastProviderProps) => {
  const [toastIds, setToastIds] = useState<CouponBoxToastId[]>([])

  const clearToast = useCallback((id: CouponBoxToastId) => {
    setToastIds((currentToastIds) =>
      currentToastIds.filter((toastId) => toastId !== id),
    )
  }, [])

  const openToast = useCallback((id: CouponBoxToastId) => {
    setToastIds((currentToastIds) => {
      if (currentToastIds.includes(id)) {
        return currentToastIds
      }
      return [...currentToastIds, id]
    })
  }, [])

  return (
    <CouponBoxToastContext.Provider
      value={{
        toastIds,
        openToast,
        clearToast,
      }}
    >
      {children}
    </CouponBoxToastContext.Provider>
  )
}

export default CouponBoxToastProvider

export const useCouponBoxToast = (): CouponBoxToastContextProps => {
  return useContext<CouponBoxToastContextProps>(CouponBoxToastContext)
}
