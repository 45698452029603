import moment from 'moment'
import React, { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { useCouponBoxToast } from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToastProvider'
import { ExtraDiscountCandidate } from '~/cart/modules/unit-coupon/types'
import { koreanNumeralNotation } from '~/lib/formatter'

type CouponBoxExtraDiscountProps = {
  extraDiscount: ExtraDiscountCandidate
}

function getDiscountPhrase(extraDiscount: ExtraDiscountCandidate): string {
  return extraDiscount.unitType == 'Rate'
    ? `${koreanNumeralNotation(extraDiscount.discountRate || 0)}%`
    : `${koreanNumeralNotation(extraDiscount.discountAmount || 0)}원`
}

function getTitle(extraDiscount: ExtraDiscountCandidate): string {
  return `${getDiscountPhrase(extraDiscount)} 즉시할인`
}

function getDescription(extraDiscount: ExtraDiscountCandidate): string {
  const baseAmount = extraDiscount.baseAmount || 0
  const maxAmount = extraDiscount.maxDiscountAmount || 0

  const baseAmountPhrase =
    baseAmount > 0 ? `${koreanNumeralNotation(baseAmount)}원 이상 구매시` : ''
  const maxAmountPhrase =
    maxAmount > 0 ? `최대 ${koreanNumeralNotation(maxAmount)}원` : ''
  const endDatePhrase = extraDiscount.endDate
    ? `(~${moment(extraDiscount.endDate).format('MM/DD')})`
    : ''

  return [baseAmountPhrase, maxAmountPhrase, endDatePhrase]
    .filter((phrase) => phrase !== '')
    .join(' ')
}

const CouponBoxExtraDiscount = ({
  extraDiscount,
}: CouponBoxExtraDiscountProps): JSX.Element => {
  const { openToast } = useCouponBoxToast()

  const openGoCheckoutToast = useCallback(() => {
    openToast('go-checkout')
  }, [openToast])

  return (
    <li className="list-item">
      <button
        type="button"
        className="button__discount"
        onClick={openGoCheckoutToast}
      >
        <p className="text__discount-title">
          <strong className="text__emphasis">{getTitle(extraDiscount)}</strong>
          <span className="text__sub">{extraDiscount.name}</span>
        </p>
        <p className="text">{getDescription(extraDiscount)}</p>
      </button>
    </li>
  )
}

export default React.memo(CouponBoxExtraDiscount, isEqual)
