import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CartToolbar from '~/cart/gmarket/ko/common/components/CartToolbar'
import {
  openSimpleLayer,
  removeCartUnits,
} from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { EnumLayerType } from '~/cart/modules/view/types'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

const CartToolbarContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const isDomesticBusinessman = useSelector(
    (state: RootState) => state.buyer.isDomesticBusinessman,
  )

  const onClickDeleteSelectedCartUnit = (
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    dispatch(removeCartUnits(e.currentTarget))
  }

  const onClickOpenEstimate = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(openSimpleLayer(EnumLayerType.EstimateSheet, e.currentTarget))
    },
    [dispatch],
  )

  return (
    <CartToolbar
      isDomesticBusinessman={isDomesticBusinessman}
      isMobile={isMobile}
      onClickDeleteSelectedCartUnit={onClickDeleteSelectedCartUnit}
      onClickOpenEstimate={onClickOpenEstimate}
    />
  )
}

export default CartToolbarContainer
