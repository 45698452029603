import React, { useEffect, useState } from 'react'

interface ToastProps {
  className?: string
  show: boolean
  duration?: number
  onClose?: () => void
  children: React.ReactNode
}

const empty = (): void => {
  /* empty */
}

const Toast = ({
  className = '',
  show,
  duration = 3000,
  onClose = empty,
  children,
}: ToastProps): JSX.Element => {
  const [showing, setShowing] = useState(false)

  useEffect(() => {
    if (show) {
      setShowing(true)

      const end = window.setTimeout(() => {
        setShowing(false)
        onClose()
      }, duration)

      return (): void => {
        window.clearTimeout(end)
      }
    }
  }, [duration, onClose, show])

  return (
    <div
      className={`box__toast-popup ${className} ${
        showing ? 'box__toast-popup--active' : ''
      }`}
      aria-live="polite"
      style={
        showing
          ? { visibility: 'visible', opacity: 1 }
          : { visibility: 'hidden', opacity: 0 }
      }
    >
      {children}
    </div>
  )
}

export default React.memo(Toast)
