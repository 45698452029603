import React from 'react'
import isEqual from 'react-fast-compare'
import { CouponBoxTableType } from '~/cart/modules/types'
import { currencyAmount } from '~/lib/formatter'

type CouponBoxSummaryProps = {
  summary: {
    itemPrice: number
    totalBranchAdditionalPrice: number
    discountAppliedPrice: number
    totalDiscountPrice: number
  } & Record<CouponBoxTableType, number>
}

const CouponBoxSummary = ({ summary }: CouponBoxSummaryProps): JSX.Element => {
  return (
    <ul className="list__total-price">
      <li className="list-item list-item-basic">
        <em className="text__title">상품가격</em>
        <span className="text__number">
          {currencyAmount(summary.itemPrice)}
        </span>
        {summary.totalBranchAdditionalPrice < 0 && (
          <div className="box__shop-nudge">
            <span className="text__sale">
              지점할인
              {currencyAmount(Math.abs(summary.totalBranchAdditionalPrice))}원
              적용중
            </span>
          </div>
        )}
      </li>

      <li className="list-item list-item-sale sprite__coupon--before">
        <em className="text__title">할인금액</em>
        <span className="text__number">
          {currencyAmount(summary.totalDiscountPrice)}
        </span>
      </li>

      <li className="list-item list-item-total sprite__coupon--before">
        <em className="text__title">총 상품금액</em>
        <span className="text__number">
          {currencyAmount(summary.discountAppliedPrice)}
        </span>
      </li>
    </ul>
  )
}

export default React.memo(CouponBoxSummary, isEqual)
