import React from 'react'
import { useCouponBoxToast } from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToastProvider'

type CouponBoxBodyProps = {
  children: React.ReactNode
}

const CouponBoxBody = ({ children }: CouponBoxBodyProps): JSX.Element => {
  const { toastIds } = useCouponBoxToast()

  return (
    <div
      className={`box__coupon-content${
        toastIds.length > 0 ? ' js-toast-active' : ''
      }`}
    >
      {children}
    </div>
  )
}

export default CouponBoxBody
