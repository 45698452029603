import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

const LayerNudgingSmileCard = ({
  layerId,
  layerKey,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const buyerName = useSelector((state: RootState) => state.buyer.buyerName)
  const smileCardNudgeCoupons = useSelector(
    (state: RootState) => state.cart.smileCardNudgeCoupons,
  )
  useEffect(() => {
    return (): void => {
      CookieHelper.setCookie(
        CookieKeys.NudgingSmileCardClosed,
        'true',
        moment().add(1, 'day').toDate(),
      )
    }
  })

  if (!buyerName) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div
      className="box__layer box__layer--full box__layer__smilecard-coupon"
      id={layerId}
      role="dialog"
    >
      <div className="box__layer-content">
        <div className="box__layer-header">
          <button
            type="button"
            className="button__close sprite__cart--before"
            onClick={onCloseLayer()}
          >
            <span className="for-a11y">레이어 닫기</span>
          </button>
        </div>
        <div className="box__layer-body">
          <div className="section__coupon-nudging">
            <p className="text__coupon-nudging">
              {buyerName} 님을 위한 할인쿠폰을
              <br />
              쿠폰함에서 지금 적용해보세요!
            </p>
            <ul className="list__coupon">
              {smileCardNudgeCoupons &&
                smileCardNudgeCoupons.map((coupon, idx) => {
                  return (
                    <li className="list-item" key={`nudge_coupon_${idx}`}>
                      <span className="text__price sprite__cart">
                        {coupon.discountPrice}
                      </span>
                      {/*<span className="text__condition">*/}
                      {/*  {coupon.discountPrice + 100}원 이상 구매시*/}
                      {/*</span>*/}
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerNudgingSmileCard
