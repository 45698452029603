import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OrderSummaryTitle from '~/cart/gmarket/ko/common/components/OrderSummary/OrderSummaryTitle'
import { openSimpleLayer } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import {
  getIsOverseaShipping,
  getShippingCountry,
} from '~/cart/modules/shipping/reducer'
import { EnumLayerType } from '~/cart/modules/view/types'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

const OrderSummaryTitleContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isOverseaShipping = useSelector((state: RootState) =>
    getIsOverseaShipping(state.shipping),
  )

  const shippingCountry = useSelector(
    (state: RootState) => getShippingCountry(state.shipping),
    shallowEqual,
  )

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  const onClickOpenCountrySelector = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      dispatch(openSimpleLayer(EnumLayerType.CountrySelector, e.currentTarget))
    },
    [dispatch],
  )
  return (
    <OrderSummaryTitle
      isOverseaShipping={isOverseaShipping}
      shippingCountry={shippingCountry}
      currentCartTab={currentCartTab}
      onClickOpenCountrySelector={onClickOpenCountrySelector}
    />
  )
}

export default OrderSummaryTitleContainer
