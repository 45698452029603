import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import logRender from '~/lib/log-render'

const CartError = (): JSX.Element => {
  logRender()
  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  return (
    <div id="cart_null">
      <div className="inner_cont">
        <div className="none_item">
          <strong className="msg">
            <span>일시적인 오류로 장바구니의 상품을</span>{' '}
            <span>호출 할 수 없습니다.</span>
          </strong>
          {currentCartTab === 'SmileDelivery' && (
            <CustomAnchor
              href={domains.SMILE_DELIVERY_HOME}
              className="btn_action"
            >
              <span className="btn_text">스마일배송 상품보기</span>
            </CustomAnchor>
          )}
          {currentCartTab === 'ExpressShop' && (
            <CustomAnchor
              href={domains.EXPRESS_SHOP_HOME}
              className="btn_action"
            >
              <span className="btn_text">당일배송 상품보기</span>
            </CustomAnchor>
          )}
          {currentCartTab === 'All' && (
            <CustomAnchor href={domains.HOME} className="btn_action">
              <span className="btn_text">홈으로 가기</span>
            </CustomAnchor>
          )}
        </div>
      </div>
    </div>
  )
}

export default CartError
