import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import useDeepCompareEffect from '~/lib/deep-compare-hook/useDeepCompareEffect'

interface Prediction {
  direction: PredictedDirection
}

type PredictedDirection = 'up' | 'down'

const preScroll = (direction: PredictedDirection): void => {
  switch (direction) {
    case 'up':
      return window.scrollTo({ top: 0, behavior: 'smooth' })
    case 'down':
      return window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      })
  }
}

const useAnchorScroll = (id: string, prediction?: Prediction): void => {
  const [target, setTarget] = useState<HTMLElement>()

  useDeepCompareEffect(() => {
    if (!isEmpty(id.trim())) {
      const element = document.getElementById(id)
      if (element) {
        setTarget(element)
      } else {
        if (prediction) {
          preScroll(prediction.direction)
        }
      }
    }
  }, [id, prediction])

  useEffect(() => {
    if (target) {
      target.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
  }, [target])
}

export default useAnchorScroll
