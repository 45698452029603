import React from 'react'
import useLoadingDots from '~/cart/hooks/useLoadingDots'

const PC = ({ itemNo }: { itemNo: string }): JSX.Element => {
  return (
    <div className="loading dot_mix_24" aria-describedby={itemNo}>
      <span className="for_a11y">로딩중</span>
    </div>
  )
}

const Mobile = ({ itemNo }: { itemNo: string }): JSX.Element => {
  const type = 'dot_mix_24'
  const [ref] = useLoadingDots(type)

  return (
    <div ref={ref} className={`loading ${type}`} aria-describedby={itemNo}>
      <span className="for_a11y">로딩중</span>
    </div>
  )
}

const CartUnitQtyLoading = ({
  isMobile,
  itemNo,
}: {
  isMobile: boolean
  itemNo: string
}): JSX.Element => {
  return isMobile ? <Mobile itemNo={itemNo} /> : <PC itemNo={itemNo} />
}

export default CartUnitQtyLoading
