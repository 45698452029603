import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

const LayerNudgingCartCountExtended = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()

  const cartSize = useSelector((state: RootState) => state.buyer.cartSize) ?? 0

  const closer = useMemo(() => onCloseLayer(), [onCloseLayer])
  const finallyCloser = useCallback((): void => {
    CookieHelper.setCookie(
      CookieKeys.NudgingCartCountExtended,
      'true',
      moment().add(6, 'month').toDate(),
    )
    closer()
  }, [closer])

  return (
    <div
      id={layerId}
      className="box__layer box__layer--full box__layer__business-purchasing-member"
      role="dialog"
    >
      <div className="box__layer-content">
        <div className="box__layer-header"></div>
        <div className="box__layer-body">
          <div className="section__nudging">
            <p className="text__nudging">
              사업자 회원님,
              <br />
              이제 장바구니에 <strong>{cartSize}개까지</strong>
              <br />
              담을 수 있어요
            </p>{' '}
            <p className="text__nudging--desc">
              🛒 더 커진 장바구니로 편리한 쇼핑하세요!
            </p>
            <div className="box__button">
              <button
                type="button"
                className="button button__default"
                data-montelena-acode={areaCodes.BIZ_CART_EXTENDED_CONFIRM}
                onClick={closer}
              >
                확인했어요!
              </button>
              <button
                type="button"
                className="button button__white"
                data-montelena-acode={areaCodes.BIZ_CART_EXTENDED_CONFIRMED}
                onClick={finallyCloser}
              >
                다시 보지 않기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerNudgingCartCountExtended
