import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Toast from '~/cart/gmarket/ko/common/components/Toast'
import { RootState } from '~/cart/modules/reducers'
import { getCurrentDawnDeliveryCutOffTime } from '~/cart/modules/shipping/reducer'
import logRender from '~/lib/log-render'

const SmileDeliveryCutOffNotiToast = (): JSX.Element => {
  logRender()

  const [isShowing, setShowing] = useState(false)

  const dawnCutOffTime = useSelector((state: RootState) =>
    getCurrentDawnDeliveryCutOffTime(state.shipping),
  )

  // 닫을때 timeout clear 해줘야함
  useEffect(() => {
    if (dawnCutOffTime) {
      const cutOffMoment = moment(dawnCutOffTime)
      if (cutOffMoment.isValid() && cutOffMoment.isAfter(moment())) {
        const key1 = window.setTimeout(() => {
          setShowing(true)
        }, cutOffMoment.diff(moment(), 'millisecond'))

        return (): void => {
          window.clearTimeout(key1)
        }
      }
    }
  }, [dawnCutOffTime])

  return (
    <Toast
      show={isShowing}
      duration={7000}
      onClose={(): void => {
        setShowing(false)
      }}
    >
      <p className="text">
        아차! 새벽배송이 마감되었어요.<span className="text__imoji">😓</span>
        <br />
        걱정마세요!{' '}
        <span className="text__emphasis">
          지금 주문하면 내일 받을 수 있어요!
        </span>
      </p>
    </Toast>
  )
}

export default React.memo(SmileDeliveryCutOffNotiToast)
