import React from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useSelector } from 'react-redux'
import SmileFreshGaugeBar from '~/cart/gmarket/ko/common/components/SmileFreshGaugeBar'
import { SellerGroupView } from '~/cart/modules/cart/types'
import {
  getSelectedSellerGroupSummary,
  RootState,
} from '~/cart/modules/reducers'
import { SmileFreshConditionalFreeBasisCost } from '~/data/consts'
import logRender from '~/lib/log-render'

const SmileFreshGaugeBarContainer = ({
  sellerGroup,
}: {
  sellerGroup: SellerGroupView
}) => {
  logRender()

  const sellerGroupSummary = useSelector(
    (state: RootState) => getSelectedSellerGroupSummary(state, sellerGroup),
    shallowEqual,
  )
  const isShippingFeeLoadedAfterChanged = useSelector(
    (state: RootState) => state.view.isShippingFeeLoadedAfterChanged,
  )

  return (
    <SmileFreshGaugeBar
      isShippingFeeLoadedAfterChanged={isShippingFeeLoadedAfterChanged}
      totalShippingFee={sellerGroupSummary.totalShippingFee}
      selectedCartUnitCount={sellerGroupSummary.cartUnitCount}
      totalItemPrice={sellerGroupSummary.totalItemPrice}
      smileFreshConditionalFreeBasisCost={SmileFreshConditionalFreeBasisCost}
      totalBundleDiscountPrice={sellerGroupSummary.totalBundleDiscountPrice}
    />
  )
}

export default React.memo(SmileFreshGaugeBarContainer, isEqual)
