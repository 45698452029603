import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsUniverseClubNudging } from '~/cart/modules/buyer/reducer'
import { openOneClickJoin } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import logRender from '~/lib/log-render'
import { goSignIn } from '~/lib/utils'

const ExpressShopAddressContainer = (): JSX.Element => {
  logRender()

  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMember = useSelector(
    (state: RootState) => state.buyer.memberType !== 'NonMember',
  )

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  const isUniverseClubNudging = useSelector((state: RootState) =>
    getIsUniverseClubNudging(state),
  )

  const expressShopDeliveryAddress = useSelector(
    (state: RootState) => state.buyer.expressShopDeliveryAddress,
  )

  const onClickOpenOneClickJoin = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!isMember) {
        goSignIn()
        return
      }
      dispatch(openOneClickJoin(e.currentTarget, 'coupon'))
    },
    [dispatch, isMember],
  )

  if (currentCartTab === 'ExpressShop' && expressShopDeliveryAddress) {
    return (
      <div className="box__smiledelivery-wrap box__quick-delivery">
        <div className="box__address-info">
          <div className="user_location">
            <span className="icon sprite__cart">배송 위치</span>
            <span className="user_name">
              {expressShopDeliveryAddress.receiverName}
            </span>
            <span className="bar"></span>
            <span className="user_addr">{`${expressShopDeliveryAddress.address} ${expressShopDeliveryAddress.detailAddress}`}</span>
          </div>
        </div>
        {isUniverseClubNudging && (
          <div className="box__smiledelivery">
            <p className="text__smiledelivery">
              <span className="image__club sprite__cart">
                <span className="for-a11y">유니버스 클럽 로고</span>
              </span>{' '}
              클럽 회원님께만 드리는 15% 쿠폰!
            </p>
            <CustomAnchor
              className="link__free-membershop sprite__cart--after"
              data-montelena-acode={areaCodes.CLUB_NUDGING_CLICK}
              data-montelena-club_nudge_tab={currentCartTab}
              onClick={onClickOpenOneClickJoin}
            >
              무료체험
            </CustomAnchor>
          </div>
        )}
      </div>
    )
  } else {
    return <></>
  }
}

export default ExpressShopAddressContainer
