export const retry = async (
  count: number,
  interval: number,
  callback: () => Promise<boolean>,
  post?: () => Promise<void>,
): Promise<void> => {
  if (count <= 1 || interval <= 0) {
    throw new Error(
      `not supported parameters - count:${count} interval:${interval}`,
    )
  }

  let tryCount = 1
  const execute = async (): Promise<boolean> => {
    if (tryCount++ >= count) {
      return true
    }
    return await callback()
  }
  const stop = async (timerId?: NodeJS.Timer): Promise<void> => {
    if (timerId) {
      clearInterval(timerId)
    }

    if (post) {
      await post()
    }
  }

  const firstCall = await callback()
  if (firstCall) {
    await stop()
    return
  }

  const timer = setInterval(async () => {
    const success = await execute()
    if (success) {
      await stop(timer)
    }
  }, interval)
}
