import moment from 'moment'
import React from 'react'

const BsdBanner = ({
  endDate,
  bigSaleTitle,
}: {
  endDate: DateAsString
  bigSaleTitle: string
}): JSX.Element => {
  const dateText = moment(endDate).subtract(1, 'day').format('M월 D일')
  return (
    <div className="box__price-signal box__price-promotion">
      <p className="text__price-signal">
        {dateText}까지 {bigSaleTitle} 쿠폰으로 구매하세요!
      </p>
    </div>
  )
}

export default BsdBanner
