// These must be the first lines in src/index.js
// organize-imports-ignore
import 'react-app-polyfill/stable'
import 'allsettled-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import configureStore from '~/cart/modules/configure-store'
import domains from '~/data/domains'
import PageInitializer from '~/lib/page-initializer'
import App from './App'
import siteEnv from '~/lib/site-env'

const { store, history } = configureStore()

const script =
  siteEnv.env !== 'production' && /dev=true/.test(window.location.search)
    ? '//script-dev.gmarket.co.kr'
    : domains.SCRIPT
const cssList = [
  `${script}/mobile/css/application/kr/cart/app2.css?timestamp=${new Date().getTime()}`,
]

const scriptList: string[] = [
  `${domains.SCRIPT}/mobile/js/swiper.min.js`,
  // `${domains.SCRIPT}/mobile/js/core/lib/ui/lottie/bodymovin.js`,
  // `${domains.SCRIPT}/mobile/js/application/kr/cart/app2.js`,
]

PageInitializer.init(
  'G마켓 - 장바구니',
  'service__cart',
  cssList,
  scriptList,
).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('wrapper'),
  )
})
