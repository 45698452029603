import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openOneClickJoin } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import AppSchemeHelper from '~/lib/app-scheme-helper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { currencyAmount, formatString } from '~/lib/formatter'
import { goSignIn } from '~/lib/utils'
import UXEHelper from '~/lib/uxe-helper'
import { AppType } from '~/types/enums'

const SmileDeliveryGaugeBar = ({
  isShippingFeeLoadedAfterChanged,
  isFreeShipping,
  isUniverseClub,
  isApp,
  isAndroid,
  appType,
  selectedCartUnitCount,
  totalItemPrice,
  smileDeliveryConditionalFreeBasisCost,
}: {
  isShippingFeeLoadedAfterChanged: boolean
  isFreeShipping: boolean
  isUniverseClub: boolean
  isApp: boolean
  isAndroid: boolean
  appType?: AppType
  selectedCartUnitCount: number
  totalItemPrice: number
  smileDeliveryConditionalFreeBasisCost: number
}): JSX.Element => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMember = useSelector(
    (state: RootState) => state.buyer.memberType !== 'NonMember',
  )

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const isDomesticBusinessman = useSelector(
    (state: RootState) => state.buyer.isDomesticBusinessman,
  )

  const onClickOpenOneClickJoin = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!isMember) {
        goSignIn()
        return
      }
      // TODO 퀵패스 연결
      dispatch(openOneClickJoin(e.currentTarget, 'free-delivery'))
    },
    [dispatch, isMember],
  )

  const iosAppScheme = AppSchemeHelper.getIOSOpenWindowSimpleScheme(
    isApp,
    isAndroid,
    appType,
  )

  const smileDeliveryCategoryLinkUrl =
    iosAppScheme && domains.SMILE_DELIVERY_CATEGORY
      ? formatString(
          iosAppScheme,
          encodeURIComponent(domains.SMILE_DELIVERY_CATEGORY),
        )
      : domains.SMILE_DELIVERY_CATEGORY

  const [viewType, setViewType] = useState<'FREE_SHIPPING' | 'GAUGE' | 'NONE'>(
    'NONE',
  )
  const [gaugeValue, setGaugeValue] = useState<number>(0)

  // 배송비가 로드완료되었을때만 상태를 바꿔주기위함.
  useEffect(() => {
    if (isShippingFeeLoadedAfterChanged) {
      if (selectedCartUnitCount > 0) {
        if (isFreeShipping) {
          setViewType('FREE_SHIPPING')
        } else {
          setViewType('GAUGE')
        }
      } else {
        setViewType('NONE')
      }
      setGaugeValue(
        isFreeShipping && selectedCartUnitCount > 0
          ? smileDeliveryConditionalFreeBasisCost
          : totalItemPrice,
      )
    }
  }, [
    isFreeShipping,
    isShippingFeeLoadedAfterChanged,
    selectedCartUnitCount,
    smileDeliveryConditionalFreeBasisCost,
    totalItemPrice,
  ])

  const prevGaugeValue = useRef(gaugeValue)

  useEffect(() => {
    UXEHelper.moveGaugeBar(prevGaugeValue.current, gaugeValue)
    return (): void => {
      prevGaugeValue.current = gaugeValue
    }
  }, [gaugeValue])

  if (!isUniverseClub) {
    if (viewType === 'FREE_SHIPPING') {
      return (
        <div className="box__nudge-smiledelivery box__nudge-smiledelivery--free">
          <CustomAnchor
            href={smileDeliveryCategoryLinkUrl}
            data-montelena-acode={areaCodes.SMILE_FREE_SHIPPING_BANNER}
            target="_blank"
            className="link__nudge-smiledelivery"
          >
            <span className="text">
              짝짝👏{' '}
              <em className="text__emphasis">
                <span className="box__line">무료배송</span>
              </em>
              으로 받으시네요!&nbsp;
            </span>
            <br />
            <span className="text sprite__cart--after">
              더 담아도 배송비는 없어요
            </span>
          </CustomAnchor>
        </div>
      )
    } else {
      return (
        <>
          {!isDomesticBusinessman && !isMobile && (
            <div className="box__nudge-smiledelivery box__club-smiledelivery--gauge box__nonclub">
              <CustomAnchor
                className="link__nudge-smiledelivery"
                data-montelena-acode={areaCodes.CLUB_NUDGING_CLICK}
                onClick={onClickOpenOneClickJoin}
              >
                <span className="text__cart-gauge sprite__cart--after">
                  <span className="image__club sprite__cart">
                    <span className="for-a11y">유니버스 클럽 로고</span>
                  </span>{' '}
                  클럽은 1.5만원 이상 무료배송!{' '}
                  <strong className="text__emphasis">무료체험</strong>
                </span>
              </CustomAnchor>
            </div>
          )}
        </>
      )
    }
  }

  return (
    <div className="box__nudge-smiledelivery box__club-smiledelivery--gauge">
      {viewType === 'FREE_SHIPPING' ||
      totalItemPrice >= smileDeliveryConditionalFreeBasisCost ? (
        <div
          className="text__cart-gauge"
          data-montelena-acode={areaCodes.SMILE_CLUB_FREE_DELIVERY}
          data-montelena-is_smileclub={isUniverseClub}
          data-montelena-is_free_shipping={isFreeShipping}
          data-montelena-has_selected_cartunit={selectedCartUnitCount > 0}
        >
          짝짝👏 유니버스 클럽으로{' '}
          <strong className="text__emphasis">무료배송</strong> 받으시네요!
        </div>
      ) : (
        <CustomAnchor
          href={smileDeliveryCategoryLinkUrl}
          className="link__nudge-smiledelivery"
          data-montelena-acode={areaCodes.SMILE_FREE_SHIPPING_BANNER}
          target="_blank"
          data-montelena-is_smileclub={isUniverseClub}
          data-montelena-is_free_shipping={isFreeShipping}
          data-montelena-has_selected_cartunit={selectedCartUnitCount > 0}
        >
          {viewType === 'GAUGE' ? (
            <span className="text__cart-gauge sprite__cart--after">
              <strong className="text__emphasis">
                <span className="box__format-amount">
                  <strong className="text__value">
                    {currencyAmount(
                      smileDeliveryConditionalFreeBasisCost - totalItemPrice,
                    )}
                  </strong>
                  <span className="text__unit">원</span>
                </span>
              </strong>{' '}
              더 담으면 무료배송
            </span>
          ) : (
            <span className="text__cart-gauge sprite__cart--after">
              유니버스 클럽은{' '}
              <strong className="text__emphasis">
                <span className="box__format-amount">
                  <strong className="text__value">
                    {currencyAmount(smileDeliveryConditionalFreeBasisCost)}
                  </strong>
                  <span className="text__unit">원</span>
                </span>{' '}
                이상 무료배송
              </strong>
            </span>
          )}
        </CustomAnchor>
      )}
      <div className="box__gauge">
        <span className="for-a11y">
          장바구니에 담긴 스마일배송상품 금액 및 혜택 안내
        </span>
        <div className="box__gauge-bar">
          <div
            className="image__value"
            role="progressbar"
            aria-valuenow={gaugeValue}
            aria-valuemin={0}
            aria-valuemax={smileDeliveryConditionalFreeBasisCost}
            aria-live="polite"
          />
        </div>
      </div>
    </div>
  )
}

export default SmileDeliveryGaugeBar
