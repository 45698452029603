import { DependencyList, useMemo } from 'react'
import useDeepCompareDeps from '~/lib/deep-compare-hook/useDeepCompareDeps'

/**
 * Non-primitive type deps 에 대해 deep compare 를 하는 useMemo hook 입니다.
 * deps 가 Non-primitive type(Object, Function, Array) 일 때만 사용하는 것이 좋습니다.
 * @param factory
 * @param deps
 */
const useDeepCompareMemo = <T>(factory: () => T, deps: DependencyList): T => {
  return useMemo(factory, useDeepCompareDeps(deps))
}

export default useDeepCompareMemo
