import React from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import { getCartUnitListByIdList } from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'
import logRender from '~/lib/log-render'

const ItemBannerContainer = ({
  cartUnitIdList,
}: {
  cartUnitIdList: number[]
}): JSX.Element => {
  logRender()

  const cartUnitList = useSelector((state: RootState) =>
    getCartUnitListByIdList(state.cart, cartUnitIdList),
  )

  const buyBoxes = useSelector((state: RootState) => state.recommend.buyBoxes)

  const cartUnit = cartUnitList.filter(
    (e) =>
      e.priceChangedSignalAvailable &&
      e.signal &&
      e.signal.signalType !== 'NONE',
  )[0]
  if (!cartUnit) {
    return <></>
  }

  const { item, signal } = cartUnit
  const buyBox = buyBoxes[item.itemNo]
  if (
    signal &&
    cartUnit.priceChangedSignalAvailable &&
    cartUnit.isBuyAvailable &&
    signal.signalType &&
    signal.signalType !== 'NONE' &&
    !(buyBox?.type === 'GoodDeal' && buyBox?.status === 'Loaded')
  ) {
    const decreasedPriceAmount =
      signal?.decreasedPriceAmount?.toLocaleString('ko-KR')

    const SignalTitle: { [key: string]: string } = {
      TODAY_LARGE_DROP: `가격인하! 오늘 최대 ${decreasedPriceAmount}원 저렴해졌어요`,
      YDAY_LARGE_DROP: `가격인하! 어제부터 최대 ${decreasedPriceAmount}원 저렴해졌어요`,
      LARGE_DROP: `가격인하! 평소보다 최대 ${decreasedPriceAmount}원 저렴해졌어요`,
      TODAY_SMALL_DROP: '가격인하! 오늘 더 저렴해졌어요',
      YDAY_SMALL_DROP: '가격인하! 어제부터 더 저렴해졌어요',
      SMALL_DROP: '가격인하! 평소보다 더 저렴해졌어요',
    }

    return (
      <div
        className={`box__price-signal ${
          item.bsd && item.bsd.bigSmile ? 'box__price-signal-promotion' : ''
        }`}
      >
        <p className="text__price-signal">{SignalTitle[signal.signalType]}</p>
      </div>
    )
  } else {
    return <></>
  }
}
export default React.memo(ItemBannerContainer, isEqual)
