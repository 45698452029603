import axiosClient from '~/lib/axios-client'

export const fetchGetSmilePayOneClickInfo =
  async (): Promise<smilePayAPI.SmilePayOneClickS> => {
    return axiosClient.post(`/smile/getSmilePayOneClickInfo`)
  }

export const fetchJoinSmileClubByOneClick = async (): Promise<
  Nullable<{
    /**
     * 스마일클럽 회원번호
     */
    masterId?: string
    /**
     * 구독 번호
     */
    subscriptionNo?: number // int64
  }>
> => {
  return axiosClient.post(`/smile/joinSmileClubByOneClick`)
}

export const fetchGetClubWelcomeGift =
  async (): Promise<cartFE.ClubWelcomeGiftResponse> => {
    return axiosClient.post('/smile/getWelcomeGift')
  }
