import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import BsdBanner from '~/cart/gmarket/ko/common/components/BsdBanner'
import BundleDiscountContainer from '~/cart/gmarket/ko/common/containers/CartItemList/BundleDiscountContainer'
import BuyBoxContainer from '~/cart/gmarket/ko/common/containers/CartItemList/BuyBoxContainer'
import CartUnitContainer from '~/cart/gmarket/ko/common/containers/CartItemList/CartUnitContainer'
import ItemBannerContainer from '~/cart/gmarket/ko/common/containers/CartItemList/ItemBannerContainer'
import CartSelector from '~/cart/gmarket/ko/common/containers/CartSelector'
import { getCartUnitById } from '~/cart/modules/cart/reducer'
import { ItemGroupView } from '~/cart/modules/cart/types'
import { getBuyUnavailableInfo, RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import { getParameters } from '~/lib/param-parser'
import UXEHelper from '~/lib/uxe-helper'

const BuyBox = ({ itemGroup }: { itemGroup: ItemGroupView }): JSX.Element => {
  const isLogin = useSelector((state: RootState) => state.buyer.isLogin)

  return isLogin ? <BuyBoxContainer itemGroup={itemGroup} /> : <></>
}

const CartItemGroupContainer = ({
  itemGroup,
}: {
  itemGroup: ItemGroupView
}): JSX.Element => {
  logRender()

  const view = useSelector((state: RootState) => state.view)
  const firstCartUnitId = itemGroup.cartUnitIdList[0]

  const firstCartUnit = useSelector((state: RootState) => {
    const cartUnit = getCartUnitById(state.cart, firstCartUnitId)
    const priceChangedSignalAvailable = itemGroup.cartUnitIdList.filter(
      (cartUnitId) => {
        const cartUnit = getCartUnitById(state.cart, cartUnitId)
        return (
          cartUnit?.priceChangedSignalAvailable &&
          cartUnit.signal &&
          cartUnit.signal.signalType !== 'NONE'
        )
      },
    )
    return cartUnit
      ? {
          cartUnitId: cartUnit.cartUnitId,
          cartUnitType: cartUnit.cartUnitType,
          item: cartUnit.item,
          discount: cartUnit.discounts,
          quantity: cartUnit.quantity,
          isBuyAvailable: cartUnit.isBuyAvailable,
          priceChangedSignalAvailable: priceChangedSignalAvailable.length > 0,
        }
      : undefined
  }, isEqual)

  const signalCartUnitId = itemGroup.cartUnitIdList.find(
    (cartUnitId) => cartUnitId === getParameters().signalCartUnitId,
  )
  const [showSignal, setShowSignal] = useState(
    signalCartUnitId && view.isMobile && view.tenantType === 'Gmarket',
  )

  const buyUnavailableInfo = useSelector((state: RootState) =>
    getBuyUnavailableInfo(state, firstCartUnitId),
  )

  useEffect(() => {
    if (showSignal) {
      UXEHelper.mobileScrollTop('.case_signal')
      setTimeout(() => {
        setShowSignal(false)
      }, 3500)
    }
  }, [showSignal])

  if (!firstCartUnit) {
    return <></>
  }

  const id = itemGroup.key + firstCartUnit.cartUnitId
  const isBigSmile = firstCartUnit.item.bsd && firstCartUnit.item.bsd.bigSmile
  const discount = firstCartUnit.discount.find(
    (discount) => discount.discountType === 'Bundle',
  )

  return (
    <li
      className={`order--idx
        ${discount?.discountPolicy ? 'case_mutiple-purchases' : ''}
        ${buyUnavailableInfo ? 'case_soldout' : ''}
      `}
    >
      {discount && (
        <BundleDiscountContainer
          discount={discount}
          cartUnitIdList={itemGroup.cartUnitIdList}
        />
      )}
      <div className={`item ${showSignal ? 'case_signal' : ''}`}>
        <div className="item_check">
          <CartSelector
            id={id}
            cartUnitIdList={itemGroup.cartUnitIdList}
            data-montelena-acode={areaCodes.SELECT_ITEM}
            aria-label={firstCartUnit.item.itemName}
          />
        </div>
        <div className="item_img">
          <CustomAnchor
            href={formatString(domains.VIP, itemGroup.key)}
            data-montelena-acode={areaCodes.ITEM_IMAGE}
            data-montelena-goodscode={itemGroup.key}
            aria-hidden="true"
            tabIndex={-1}
          >
            {isBigSmile && (
              <span className="box__bigs-tag">
                <img
                  src={`${domains.PICS}/mobile/single/kr/promotion/bigs/tag/tag.png`}
                  className="image"
                  alt="Big Smile Day 상품"
                />
              </span>
            )}
            <img
              src={firstCartUnit.item.itemImageUrl}
              alt="상품이미지"
              onError={(e): void => {
                e.currentTarget.src = domains.ITEM_NO_IMAGE
              }}
            />
          </CustomAnchor>
        </div>
        <div className="item_info">
          {itemGroup.cartUnitIdList.map((cartUnitId, idx) => (
            <CartUnitContainer
              key={cartUnitId}
              cartUnitId={cartUnitId}
              firstCartUnitId={firstCartUnitId}
              idx={idx}
            />
          ))}

          <ItemBannerContainer cartUnitIdList={itemGroup.cartUnitIdList} />

          {!firstCartUnit.priceChangedSignalAvailable &&
            firstCartUnit.isBuyAvailable &&
            isBigSmile &&
            firstCartUnit.item.bsd && (
              <BsdBanner
                endDate={firstCartUnit.item.bsd.endDate}
                bigSaleTitle={firstCartUnit.item.bsd.bigSaleTitle}
              />
            )}
        </div>
      </div>
      <BuyBox itemGroup={itemGroup} />
    </li>
  )
}

export default React.memo(CartItemGroupContainer, isEqual)
