import { parseInt } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BundleDiscountNudging from '~/cart/gmarket/ko/common/components/CartItemList/BundleDiscountNudging'
import CartUnitQtyLoading from '~/cart/gmarket/ko/common/components/CartItemList/CartUnitQtyLoading'
import { CartUnit } from '~/cart/modules/cart/types'
import { setCartUnitQuantityComplex } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomInput from '~/lib/components/CustomInput'
import logRender from '~/lib/log-render'
import UXEHelper from '~/lib/uxe-helper'

const CartUnitQtyControlContainer = ({
  cartUnit,
}: {
  cartUnit: CartUnit
}): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const [quantity, setQuantity] = useState(cartUnit.quantity.toString())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setQuantity(cartUnit.quantity.toString())
  }, [cartUnit.quantity])

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuantity(e.currentTarget.value)
  }

  const setCartUnitQuanity = useCallback(
    async (triggerElement: HTMLElement, qty: number): Promise<void> => {
      if (cartUnit.quantity !== qty) {
        setIsLoading(true)
        const result = await dispatch(
          setCartUnitQuantityComplex(triggerElement, cartUnit.cartUnitId, qty),
        )
        if (!result) {
          setQuantity(cartUnit.quantity.toString())
        }
        setIsLoading(false)
      }
      UXEHelper.announceForAccessibility(qty)
    },
    [cartUnit.cartUnitId, cartUnit.quantity, dispatch],
  )

  const onBlurInput = (e: React.FocusEvent<HTMLInputElement>): Promise<void> =>
    setCartUnitQuanity(e.currentTarget, parseInt(quantity))

  const onClickChangeButton =
    (qty: number) =>
    (e: React.MouseEvent<HTMLButtonElement>): Promise<void> =>
      setCartUnitQuanity(e.currentTarget, qty)

  // TODO 임시
  const bundleDiscountInfo:
    | {
        type: 'N+N' | 'FixedRate' | 'FixedPrice'
        conditionValue: number
        benefitValue: number
        isSucceeded: boolean
      }
    | undefined = undefined

  return (
    <div className={`section item_qty ${isLoading ? 'case_loading' : ''}`}>
      <b className="for_a11y">상품수량</b>
      <div className="item_qty_wrap">
        <CustomInput
          className="item_qty_count"
          id={`qty-${cartUnit.cartUnitId}`}
          type="number"
          min="1"
          max="100"
          pattern="\d*"
          title="상품수량"
          value={quantity.toString()}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          onlyNumber={true}
          aria-describedby={cartUnit.item.itemNo}
          data-montelena-acode={areaCodes.EDIT_QTY}
        />
        <button
          className="btn_plus sprite__cart"
          onClick={onClickChangeButton(cartUnit.quantity + 1)}
          data-montelena-acode={areaCodes.ADD_QTY}
          aria-describedby={cartUnit.item.itemNo}
        >
          <i className="icon sp_cart btn_qty_plus">
            <span className="for_a11y">상품 수 1 증가</span>
          </i>
        </button>
        <button
          className="btn_minus sprite__cart"
          onClick={onClickChangeButton(cartUnit.quantity - 1)}
          data-montelena-acode={areaCodes.SUBTRACT_QTY}
          aria-describedby={cartUnit.item.itemNo}
        >
          <i className="icon sp_cart btn_qty_minus">
            <span className="for_a11y">상품 수 1 감소</span>
          </i>
        </button>
        <CartUnitQtyLoading isMobile={isMobile} itemNo={cartUnit.item.itemNo} />
      </div>
      {bundleDiscountInfo && (
        <BundleDiscountNudging
          id={'bundle-nudging-' + cartUnit.cartUnitId}
          bundleDiscountInfo={bundleDiscountInfo}
        />
      )}
    </div>
  )
}

export default CartUnitQtyControlContainer
