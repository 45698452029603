import React from 'react'
import { ItemAddition } from '~/cart/modules/cart/types'
import { currencyAmount } from '~/lib/formatter'

const CartUnitAddition = ({
  addition,
}: {
  addition: ItemAddition
}): JSX.Element => {
  return (
    <li>
      <span className="add_name">{addition.name}</span>
      <span className="add_value">
        {addition.value}{' '}
        {addition.price !== 0 &&
          `(${addition.price > 0 ? '+' : '-'}${currencyAmount(
            Math.abs(addition.price),
          )}원)`}
      </span>
      <span className="add_count">{addition.quantity + '개'}</span>
    </li>
  )
}

export default CartUnitAddition
