import React, { useState } from 'react'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import SFExpressNotice from '~/cart/gmarket/ko/common/components/OrderSummary/SFExpressNotice'
import {
  OverseaShippingCompany,
  ShippingCountry,
} from '~/cart/modules/shipping/types'
import CustomAnchor from '~/lib/components/CustomAnchor'
import CustomInput from '~/lib/components/CustomInput'
import { OverseaShippingCompanyType } from '~/types/enums'

const OverseaShippingCompanyComponent = ({
  shippingCompanies,
  selectedOverseaShippingCompany,
  selectedCartUnitIdList,
  shippingCountry,
  isMobile,
  onChangeShippingCompany,
  onClickOpenSFExpressNotice,
}: {
  shippingCompanies: Array<
    OverseaShippingCompany & {
      cost: number
      isAllCartUnitUnAvailable: boolean
      isAvailable: boolean
    }
  >
  selectedOverseaShippingCompany: OverseaShippingCompanyType | undefined
  selectedCartUnitIdList: number[]
  shippingCountry: ShippingCountry
  isMobile: boolean
  onChangeShippingCompany: React.ChangeEventHandler<HTMLInputElement>
  onClickOpenSFExpressNotice: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const [isShowingLayer, setShowingLayer] = useState(false)

  return (
    <ul className="oversea_rule_box">
      {shippingCompanies.map((company) => {
        const { shippingCompanyType, shippingCompanyName, cost } = company
        const isSFExpress = shippingCompanyType === 'SFExpress'
        return (
          <li className="list" key={shippingCompanyType}>
            <span
              className="input_custom"
              title={shippingCompanyName + ` 배송선택`}
            >
              <CustomInput<OverseaShippingCompanyType>
                type="radio"
                className="input__radio sprite__cart"
                id={`oversea_delivery_${shippingCompanyType}`}
                name="oversea_delivery"
                value={shippingCompanyType}
                checked={shippingCompanyType === selectedOverseaShippingCompany}
                onChange={onChangeShippingCompany}
                disabled={
                  company.isAllCartUnitUnAvailable || !company.isAvailable
                }
              />
              <label htmlFor={`oversea_delivery_${shippingCompanyType}`}>
                <i className="icon sprite__cart input_radio" />
                <span className="label">{shippingCompanyName}</span>
              </label>
            </span>
            {isSFExpress &&
              (isMobile ? (
                <CustomAnchor
                  target="_blank"
                  title="SF Express 안내"
                  className="btn_sfexpress uxeBlock-ctrl"
                  onClick={onClickOpenSFExpressNotice}
                >
                  <span className="for_a11y">SF Express 안내</span>
                  <i className="icon sprite__cart img_question" />
                </CustomAnchor>
              ) : (
                <CustomAnchor
                  title="SF Express 안내"
                  className="btn_sfexpress uxeBlock-ctrl"
                  onClick={(): void => setShowingLayer(!isShowingLayer)}
                >
                  <span className="for_a11y">SF Express 안내</span>
                  <i className="icon sprite__cart img_question" />
                </CustomAnchor>
              ))}

            {isSFExpress && isShowingLayer && (
              <SFExpressNotice
                shippingCountry={shippingCountry}
                onClickClose={(): void => setShowingLayer(false)}
              />
            )}
            {!company.isAllCartUnitUnAvailable &&
            company.isAvailable &&
            cost > 0 ? (
              <FormatPrice krwAmount={cost} />
            ) : !company.isAllCartUnitUnAvailable &&
              selectedCartUnitIdList.length === 0 &&
              cost === 0 ? (
              <span className="format-price">
                <span className="box__format-amount">
                  <span className="text__sign" />
                  <strong className="text__value text__warning">
                    상품선택
                  </strong>
                </span>
              </span>
            ) : !company.isAllCartUnitUnAvailable &&
              selectedCartUnitIdList.length !== 0 &&
              cost === 0 ? (
              <FormatPrice krwAmount={cost} />
            ) : (
              <span className="format-price">
                <span className="box__format-amount">
                  <span className="text__sign" />
                  <strong className="text__value text__warning">
                    사용불가
                  </strong>
                </span>
              </span>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default OverseaShippingCompanyComponent
