import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { getExtraDiscountCandidates } from '~/cart/modules/unit-coupon/reducer'
import { ExtraDiscountCandidate } from '~/cart/modules/unit-coupon/types'

const useExtraDiscountCandidates = (
  cartUnitId: number,
): { extraDiscountCandidates: ExtraDiscountCandidate[] } => {
  // 다운로드 대상 쿠폰 조회
  const extraDiscountCandidates = useSelector((state: RootState) =>
    getExtraDiscountCandidates(state.unitCoupon, cartUnitId),
  )
  return {
    extraDiscountCandidates,
  }
}

export default useExtraDiscountCandidates
