import React from 'react'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { CartTabType } from '~/types/enums'

const getHomeLink = (cartTab: CartTabType): JSX.Element => {
  if (cartTab === 'SmileDelivery' && domains.SMILE_DELIVERY_HOME) {
    return (
      <CustomAnchor
        className="btn_action"
        href={domains.SMILE_DELIVERY_HOME}
        data-montelena-acode={areaCodes.GO_SMILE_DELIVERY_HOME}
      >
        <span className="btn_text">스마일배송 상품보기</span>
      </CustomAnchor>
    )
  } else if (cartTab === 'SmileFresh' && domains.SMILE_FRESH_HOME) {
    return (
      <CustomAnchor
        className="btn_action"
        href={domains.SMILE_FRESH_HOME}
        data-montelena-acode={areaCodes.GO_SMILE_FRESH_HOME}
      >
        <span className="btn_text">스마일프레시 상품보기</span>
      </CustomAnchor>
    )
  } else if (cartTab === 'ExpressShop' && domains.EXPRESS_SHOP_HOME) {
    return (
      <CustomAnchor
        className="btn_action"
        href={domains.EXPRESS_SHOP_HOME}
        data-montelena-acode={areaCodes.GO_EXPRESS_SHOP_HOME}
      >
        <span className="btn_text">당일배송 상품보기</span>
      </CustomAnchor>
    )
  } else {
    return (
      <CustomAnchor
        className="btn_action"
        href={domains.HOME}
        data-montelena-acode={areaCodes.GO_HOME}
      >
        홈으로 가기
      </CustomAnchor>
    )
  }
}

const EmptyCart = ({
  currentCartTab,
  isMobile,
}: {
  currentCartTab: CartTabType
  isMobile: boolean
}): JSX.Element => {
  return (
    <div id="cart_null">
      <div className="inner_cont">
        <div className="none_item">
          {isMobile && (
            <embed
              type="image/svg+xml"
              className="box__animation"
              src="//pics.gmarket.co.kr/mobile/single/kr/common/neutral_face_with_sweat.svg"
            />
          )}
          <strong className="msg">장바구니에 담긴 상품이 없습니다.</strong>
          {getHomeLink(currentCartTab)}
        </div>
      </div>
    </div>
  )
}

export default EmptyCart
