import { head } from 'lodash'
import React from 'react'
import isEqual from 'react-fast-compare'
import { ExtraDiscountCandidate } from '~/cart/modules/unit-coupon/types'
import CouponBoxTitle from '../CouponBoxTitle'
import CouponBoxExtraDiscount from './CouponBoxExtraDiscount'
import CouponBoxExtraDiscountHeader from './CouponBoxExtraDiscountHeader'

type CouponBoxExtraDiscountBoardProps = {
  isMobile: boolean
  candidates: ExtraDiscountCandidate[]
}

const CouponBoxExtraDiscountBoard = ({
  isMobile,
  candidates,
}: CouponBoxExtraDiscountBoardProps) => {
  const first = head(candidates)

  if (!first) {
    return <></>
  }

  return (
    <div className="box__content box__order-content">
      <CouponBoxTitle isMobile={isMobile}>
        <CouponBoxExtraDiscountHeader extraDiscount={first} />
      </CouponBoxTitle>
      <ul className="list__payment-discount">
        {candidates.map((extraDiscount) => (
          <CouponBoxExtraDiscount
            key={extraDiscount.masterNo}
            extraDiscount={extraDiscount}
          />
        ))}
      </ul>
    </div>
  )
}

export default React.memo(CouponBoxExtraDiscountBoard, isEqual)
