import { isEmpty, reduce, throttle } from 'lodash'
import useDeepCompareCallback from '~/lib/deep-compare-hook/useDeepCompareCallback'
import useDeepCompareMemo from '~/lib/deep-compare-hook/useDeepCompareMemo'
import Montelena, { MontelenaAreaValueType } from '~/lib/montelena'

export interface Impression {
  areaCode: string
  areaValues: MontelenaAreaValueType
  source: string
}

export interface ImpressionDataProps {
  [key: string]: string | number | undefined
}

const dataPrefix = 'data-montelena'

const useImpression = (
  impression: Impression,
): [() => void, ImpressionDataProps] => {
  const throttledLog = useDeepCompareCallback(
    throttle(() => {
      const { areaCode, areaValues, source } = impression

      if (!isEmpty(areaCode)) {
        Montelena.logImpression('IMP_VI', areaCode, areaValues, source)
      }
    }, 500),
    [impression],
  )

  const dataProps = useDeepCompareMemo(() => {
    return reduce(
      impression.areaValues,
      (acc, curr, key) => {
        acc[`${dataPrefix}-${key}`] = curr
        return acc
      },
      {
        [`${dataPrefix}-acode`]: impression.areaCode,
      } as ImpressionDataProps,
    )
  }, [impression.areaValues])

  return [throttledLog, dataProps]
}

export default useImpression
