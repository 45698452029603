import { uniq } from 'lodash'
import React, { useEffect, useRef } from 'react'
import CartBtRecommendItem from '~/cart/gmarket/ko/common/components/CartRecommend/CartBtRecommendItem'
import { FullFilledBtRecommendItem } from '~/cart/modules/recommend/types'
import areaCodes from '~/data/areaCodes'
import Montelena from '~/lib/montelena'
import { groupArrayBySize } from '~/lib/utils'
import UXEHelper from '~/lib/uxe-helper'

const CartBtRecommendItemListTypeB = ({
  seedItemNo,
  recommendItemList,
  onClickGoVIP,
  onClickAddCart,
}: {
  seedItemNo: string
  recommendItemList: FullFilledBtRecommendItem[]
  onClickGoVIP: (
    itemNo: string,
    isAdvertisement: boolean,
    clickSecureUrl: string,
  ) => React.MouseEventHandler<HTMLElement>
  onClickAddCart: (
    itemNo: string,
    isAdvertisement: boolean,
    clickSecureUrl: string,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const swiperRef = useRef<HTMLDivElement>(null)
  const groupedList: FullFilledBtRecommendItem[][] = groupArrayBySize(
    recommendItemList,
    3,
    10,
    false,
  )

  useEffect(() => {
    const options: SwiperOptions = {
      autoHeight: false,
      slidesPerView: 'auto',
      spaceBetween: 0,
    }

    if (swiperRef.current) {
      UXEHelper.initSwiper(swiperRef.current, options)
    }

    if (areaCodes.RECOMMEND_VIEW_TYPE_B) {
      Montelena.logImpression(
        'IMP_VI',
        areaCodes.RECOMMEND_VIEW_TYPE_B,
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          bt_seed_itemno: seedItemNo,
          // eslint-disable-next-line @typescript-eslint/camelcase
          bt_recommend_itemno: JSON.stringify(
            uniq(recommendItemList.map((item) => item.itemNo)).slice(0, 30),
          ), // 최대 30개
        },
        'CartBtRecommendItemListTypeB',
      )
    }
    // [] 로 전달되는 경우 마운트 될 때 한 번만 실행
  }, [groupedList, recommendItemList, seedItemNo])

  return (
    <div className="box__bt-items" id="ui_check-typeB">
      <div className="box__bt-swiper">
        <div
          ref={swiperRef}
          className="swiper-container swiper-container-horizontal"
        >
          <div className="swiper-wrapper">
            {groupedList.map((group, idx) => (
              <div className="swiper-slide" key={`recommend_item_page_${idx}`}>
                <ul className="list">
                  {group.map((item, idx) => (
                    <CartBtRecommendItem
                      key={`recommend_item_${idx}`}
                      item={item}
                      btViewType={'TypeB'}
                      onClickGoVIP={onClickGoVIP}
                      onClickAddCart={onClickAddCart}
                    />
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartBtRecommendItemListTypeB
