import { find, map } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchUnapplyCoupons } from '~/apis/cart'
import { getCartUnitById } from '~/cart/modules/cart/reducer'
import { CartUnit } from '~/cart/modules/cart/types'
import {
  applyUnitCouponComplex,
  clickFakeCoupon,
  openOneClickJoin,
} from '~/cart/modules/complex-actions'
import {
  getCartUnitPriceInfo,
  getUnitCouponBoxViewData,
  RootState,
} from '~/cart/modules/reducers'
import {
  CouponBoxSelectedIssueNoMap,
  CouponBoxTableItemViewData,
  CouponBoxTableType,
  CouponBoxTableViewData,
} from '~/cart/modules/types'
import {
  updatePaymentUnitCoupon,
  updateUnapplyUnitCoupon,
} from '~/cart/modules/unit-coupon/actions'
import {
  NotDownloadedCouponIssueNo,
  NotUseCouponDummyIssueNo,
} from '~/data/consts'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import EnumConverter from '~/lib/enum-converter'

//TODO: onChangeCoupon, onCancelCoupon, onApplyCoupon 등의 커링된 함수들이 부분 완성할 때마다 재생성되지 않도록 최적화 필요
type UnitCouponBox = {
  cartUnit?: CartUnit
  summary: {
    itemPrice: number
    totalBranchAdditionalPrice: number
    discountAppliedPrice: number
    totalDiscountPrice: number
  } & Record<CouponBoxTableType, number>
  selectedCoupon: Record<CouponBoxTableType, number>
  couponBoxViewData: CouponBoxTableViewData[]
  onChangeCoupon: (
    preChanged?: (coupon: CouponBoxTableItemViewData) => boolean,
  ) => (
    key: CouponBoxTableType,
    couponIssueNo: number,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void
  onApplyCoupon: (
    applied: () => void,
    appliedOnAnother?: () => boolean,
    alertPaymentCoupon?: (paymentCouponName: string) => void,
  ) => (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}

const getAppliedIssueNo = (
  couponBoxViewData: CouponBoxTableViewData[],
  couponBoxTableType: CouponBoxTableType,
): number => {
  const tableViewData = find(
    couponBoxViewData,
    (tableViewData) => tableViewData.type === couponBoxTableType,
  )
  return tableViewData
    ? tableViewData.selectedCouponIssueNo
    : NotUseCouponDummyIssueNo
}

const getSummary = (
  totalItemPrice: number,
  totalBranchAdditionalPrice: number,
  couponBoxViewData: CouponBoxTableViewData[],
  selectedCoupon: CouponBoxSelectedIssueNoMap,
): {
  itemPrice: number
  totalBranchAdditionalPrice: number
  discountAppliedPrice: number
  totalDiscountPrice: number
} & Record<CouponBoxTableType, number> => {
  const discountPrices = couponBoxViewData.reduce<
    Record<CouponBoxTableType, number> & { totalDiscountPrice: number }
  >(
    (result, tableViewData) => {
      const appliedCoupon = tableViewData.coupons.find(
        (x) => x.issueNo === selectedCoupon[tableViewData.type],
      )
      const pcsFakeCoupon = tableViewData.coupons.find(
        (x) => x.fakeCouponType === 'PCS',
      )
      if (pcsFakeCoupon) {
        return {
          ...result,
          [tableViewData.type]: pcsFakeCoupon.price,
          totalDiscountPrice: result.totalDiscountPrice + pcsFakeCoupon.price,
        }
      }
      if (appliedCoupon) {
        return {
          ...result,
          [tableViewData.type]: appliedCoupon.price,
          totalDiscountPrice: result.totalDiscountPrice + appliedCoupon.price,
        }
      }
      return result
    },
    {
      [CouponBoxTableType.Buyer]: 0,
      [CouponBoxTableType.Seller]: 0,
      [CouponBoxTableType.Super]: 0,
      [CouponBoxTableType.Bundle]: 0,
      totalDiscountPrice: 0,
    },
  )

  return {
    itemPrice: totalItemPrice,
    totalBranchAdditionalPrice: totalBranchAdditionalPrice,
    discountAppliedPrice: totalItemPrice - discountPrices.totalDiscountPrice,
    ...discountPrices,
  }
}

const checkIncludeClubNudgingInsertCoupon = (
  couponBoxViewData: CouponBoxTableViewData[],
  selectedCouponIssueNoes: number[],
): boolean => {
  return map(couponBoxViewData, 'coupons')
    .flat()
    .some(
      ({ issueNo, isClubNudgingInsertCoupon }) =>
        selectedCouponIssueNoes.includes(issueNo) && isClubNudgingInsertCoupon,
    )
}

const checkIncludeAppliedOnAnotherCoupon = (
  couponBoxViewData: CouponBoxTableViewData[],
  selectedCouponIssueNoes: number[],
): boolean => {
  return map(couponBoxViewData, 'coupons')
    .flat()
    .some(
      ({ issueNo, isAppliedOnAnother }) =>
        selectedCouponIssueNoes.includes(issueNo) && isAppliedOnAnother,
    )
}

const checkPaymentCoupon = (
  couponBoxViewData: CouponBoxTableViewData[],
  selectedCouponIssueNoes: number[],
  alertPaymentCoupon: (paymentCouponName: string) => void,
): void => {
  const selectedPaymentCouponList = map(couponBoxViewData, 'coupons')
    .flat()
    .filter(
      ({ issueNo, paymentCouponName }) =>
        selectedCouponIssueNoes.includes(issueNo) && paymentCouponName,
    )

  selectedPaymentCouponList.forEach(
    ({ paymentCouponName, isNotifiedPaymentCoupon }) => {
      if (paymentCouponName && !isNotifiedPaymentCoupon) {
        alertPaymentCoupon(paymentCouponName)
      }
    },
  )
}

const useUnitCouponBox = (cartUnitId: number): UnitCouponBox => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const cartUnit = useSelector((state: RootState) =>
    getCartUnitById(state.cart, cartUnitId),
  )

  const cartUnitPrice = useSelector(
    (state: RootState) => getCartUnitPriceInfo(state, cartUnitId),
    shallowEqual,
  )

  const couponBoxViewData: CouponBoxTableViewData[] = useSelector(
    (state: RootState) => getUnitCouponBoxViewData(state, cartUnitId),
    isEqual,
  )

  const appliedBuyerCouponIssueNo = getAppliedIssueNo(
    couponBoxViewData,
    CouponBoxTableType.Buyer,
  )

  const appliedSuperCouponIssueNo = getAppliedIssueNo(
    couponBoxViewData,
    CouponBoxTableType.Super,
  )

  const [selectedCoupon, setSelectedCoupon] = useState<
    Record<CouponBoxTableType, number>
  >({
    [CouponBoxTableType.Buyer]: appliedBuyerCouponIssueNo,
    [CouponBoxTableType.Seller]: NotUseCouponDummyIssueNo,
    [CouponBoxTableType.Super]: appliedSuperCouponIssueNo,
    [CouponBoxTableType.Bundle]: NotUseCouponDummyIssueNo,
  })

  useEffect(() => {
    setSelectedCoupon((org) => ({
      ...org,
      [CouponBoxTableType.Buyer]: appliedBuyerCouponIssueNo,
      [CouponBoxTableType.Super]: appliedSuperCouponIssueNo,
    }))
  }, [appliedBuyerCouponIssueNo, appliedSuperCouponIssueNo])

  const summary = useMemo(
    () =>
      getSummary(
        cartUnitPrice.cartUnitItemPrice,
        cartUnitPrice.cartUnitBranchAdditionalTotalPrice,
        couponBoxViewData,
        selectedCoupon,
      ),
    [
      cartUnitPrice.cartUnitItemPrice,
      cartUnitPrice.cartUnitBranchAdditionalTotalPrice,
      couponBoxViewData,
      selectedCoupon,
    ],
  )

  //TODO: 지마켓 글로벌 쿠폰함도 수정할 때, 재사용 가능하게 리팩토링 필요
  const defaultPreChanged = useCallback(
    async (coupon: CouponBoxTableItemViewData): Promise<boolean> => {
      if (coupon.isAppliedOnAnother) {
        if (
          window.confirm(
            '다른 상품에 이미 적용되어있는 쿠폰입니다. 취소 후 이 상품에 적용하시겠습니까?',
          )
        ) {
          const { success } = await fetchUnapplyCoupons({
            couponIssueNoes: [coupon.issueNo],
          })
          if (success) {
            dispatch(
              updateUnapplyUnitCoupon({
                cartUnitId,
                couponIssueNo: coupon.issueNo,
              }),
            )
          }
        } else {
          return false
        }
      }

      if (coupon.paymentCouponName) {
        window.alert(
          coupon.paymentCouponName +
            ' 결제수단만 사용가능한 쿠폰입니다. 주문결제시 결제제한이 적용됩니다.',
        )
        if (!coupon.isNotifiedPaymentCoupon) {
          dispatch(
            updatePaymentUnitCoupon({
              cartUnitId,
              couponIssueNo: coupon.issueNo,
            }),
          )
        }
      }
      return true
    },
    [cartUnitId, dispatch],
  )

  const onChangeCoupon = useCallback(
    (
        preChanged: (
          coupon: CouponBoxTableItemViewData,
        ) => Promise<boolean> | boolean = defaultPreChanged,
      ) =>
      (key: CouponBoxTableType, couponIssueNo: number) =>
      async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const coupon = couponBoxViewData
          .find((x) => x.type === key)
          ?.coupons.find(
            ({ issueNo }) =>
              issueNo === couponIssueNo &&
              issueNo !== NotDownloadedCouponIssueNo,
          )

        if (coupon) {
          if (coupon.isFakeCoupon) {
            if (coupon.fakeCouponType) {
              dispatch(clickFakeCoupon(e.currentTarget, coupon.fakeCouponType))
            }
          }

          const preChangedResult = await preChanged(coupon)

          if (!preChangedResult) {
            return
          }

          setSelectedCoupon({
            ...selectedCoupon,
            [key]: coupon.issueNo,
          })
        }
      },
    [couponBoxViewData, defaultPreChanged, dispatch, selectedCoupon],
  )

  const onCancelCoupon = useCallback(
    (key: CouponBoxTableType) =>
      (e: React.MouseEvent<HTMLButtonElement>): void => {
        setSelectedCoupon({
          ...selectedCoupon,
          [key]: NotUseCouponDummyIssueNo,
        })
        // 취소 버튼이 radio 버튼을 바라보는 label 안에있어서 막아주지 않으면 다시 선택된다
        e.preventDefault()
        // e.stopPropagation()
        // e.nativeEvent.stopImmediatePropagation()
      },
    [selectedCoupon],
  )

  const onApplyCoupon = useCallback(
    (
        applied: () => void,
        appliedOnAnother: () => boolean = (): boolean => true,
        alertPaymentCoupon?: (paymentCouponName: string) => void,
      ) =>
      async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        const selectedCouponIssueNoes = map(
          selectedCoupon,
          (issueNo, type) => ({
            couponBoxTableType: EnumConverter.toEnum(CouponBoxTableType, type),
            issueNo,
          }),
        )
          .filter(
            (x) =>
              x.couponBoxTableType &&
              ['Buyer', 'Super'].includes(x.couponBoxTableType),
          )
          .map((x) => x.issueNo)

        if (
          checkIncludeClubNudgingInsertCoupon(
            couponBoxViewData,
            selectedCouponIssueNoes,
          )
        ) {
          dispatch(openOneClickJoin(e.currentTarget, 'coupon'))
          return
        }

        if (
          checkIncludeAppliedOnAnotherCoupon(
            couponBoxViewData,
            selectedCouponIssueNoes,
          )
        ) {
          if (!appliedOnAnother()) {
            return
          }
        }

        // 결제수단 제한 쿠폰 확인
        if (alertPaymentCoupon) {
          checkPaymentCoupon(
            couponBoxViewData,
            selectedCouponIssueNoes,
            alertPaymentCoupon,
          )
        }
        const isSuccess = await dispatch<Promise<boolean | void>>(
          applyUnitCouponComplex(
            cartUnitId,
            selectedCouponIssueNoes,
            e.currentTarget,
          ),
        )
        if (isSuccess) {
          applied()
        }
      },
    [selectedCoupon, couponBoxViewData, dispatch, cartUnitId],
  )

  return {
    cartUnit,
    summary,
    selectedCoupon,
    couponBoxViewData,
    onChangeCoupon,
    onCancelCoupon,
    onApplyCoupon,
  }
}

export default useUnitCouponBox
