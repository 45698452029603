import React from 'react'

type CouponStatefulDisplayProps = {
  pending?: boolean
  failure?: boolean
  children: React.ReactNode
}

const CouponStateDisplay = ({
  pending = false,
  failure = false,
  children,
}: CouponStatefulDisplayProps): JSX.Element => {
  if (pending) {
    return (
      <span className="image__loading dot_mix_24" aria-label="로딩중"></span>
    )
  }

  if (failure) {
    return (
      <span
        className="image__fail sprite__coupon"
        aria-label="다운로드 실패"
      ></span>
    )
  }

  return <>{children}</>
}

export default CouponStateDisplay
