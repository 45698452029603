import React from 'react'

const CartBtRecommendItemEmpty = (): JSX.Element => {
  return (
    <div className="box__bt-items" id="ui_check-empty">
      <div className="box__items-empty">
        <embed
          type="image/svg+xml"
          className="box__animation"
          src="//mockupdev.gmarket.co.kr/mobile/images/single/en/common/neutral_face_with_sweat.svg"
        />
        <p className="text">추천할 상품이 없습니다.</p>
        <span className="text__small">다른 상품을 확인해 보세요!</span>
      </div>
    </div>
  )
}

export default CartBtRecommendItemEmpty
