import { last, reduce } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import { HitBuyBox, HitBuyBoxMap } from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'

const voidFunc = (): void => undefined

/**
 * 히트된 바이박스 hook
 * @param onHit - 바이박스 추천 히트 callback
 * @return [히트된 바이박스 목록, 히트된 바이박스 상품번호 목록, 히트된 바이박스의 타겟 상품번호 목록]
 */
const useHitBuyBox = (
  onHit: (hit: HitBuyBox) => void = voidFunc,
): [HitBuyBoxMap, string[], string[]] => {
  const hitBuyBoxes = useSelector(
    (state: RootState) => state.recommend.hitBuyBoxes,
  )

  const { hitBuyBoxItems, hitFromItems } = useMemo(() => {
    return reduce(
      hitBuyBoxes,
      ({ hitBuyBoxItems, hitFromItems }, curr) => ({
        hitBuyBoxItems: [...hitBuyBoxItems, ...curr.flatMap((b) => b.itemNo)],
        hitFromItems: [...hitFromItems, ...curr.flatMap((b) => b.fromItemNo)],
      }),
      {
        hitBuyBoxItems: [] as string[],
        hitFromItems: [] as string[],
      },
    )
  }, [hitBuyBoxes])

  const onHitRef = useRef<(hit: HitBuyBox) => void>(onHit)

  const lastHit = useSelector((state: RootState) => {
    const { itemNo, index = -1 } = last(state.recommend.hitBuyBoxIndexes) ?? {}
    if (!itemNo || index < 0) {
      return undefined
    }
    return state.recommend.hitBuyBoxes[itemNo][index]
  }, isEqual)

  useEffect(() => {
    onHitRef.current = onHit
  }, [onHit])

  useEffect(() => {
    if (lastHit) {
      onHitRef.current(lastHit)
    }
  }, [lastHit])

  return [hitBuyBoxes, hitBuyBoxItems, hitFromItems]
}

export default useHitBuyBox
