import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { CouponDownloadState } from '~/cart/modules/types'
import { DownloadableUnitCoupon } from '~/cart/modules/unit-coupon/types'

const checkDownloadState = (target: CouponDownloadState) => (
  coupon: DownloadableUnitCoupon,
): boolean => coupon.downloadState === target

const not = (predicate: (target: DownloadableUnitCoupon) => boolean) => (
  coupon: DownloadableUnitCoupon,
): boolean => !predicate(coupon)

const loadingState = checkDownloadState(CouponDownloadState.Loading)
const autoSuccessState = checkDownloadState(CouponDownloadState.AutoSuccess)
const failState = checkDownloadState(CouponDownloadState.Fail)
const downloadableState = not(
  checkDownloadState(CouponDownloadState.Terminated),
)

const useUnitCouponDownloader = () => {
  // 다운로드 쿠폰 조회
  const downloadableUnitCoupons = useSelector((state: RootState) =>
    state.unitCoupon.downloadableUnitCoupons.filter(downloadableState),
  )

  const loading = useMemo(() => {
    return downloadableUnitCoupons.some(loadingState)
  }, [downloadableUnitCoupons])

  const fails = useMemo(() => {
    return downloadableUnitCoupons.filter(failState)
  }, [downloadableUnitCoupons])

  const autoSucceed = useMemo(() => {
    return downloadableUnitCoupons.every(autoSuccessState)
  }, [downloadableUnitCoupons])

  return {
    downloadableUnitCoupons,
    loading,
    autoSucceed,
    fails,
  }
}

export default useUnitCouponDownloader
