import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useHitBuyBox from '~/cart/hooks/useHitBuyBox'
import { Impression } from '~/cart/hooks/useImpression'
import useOnMountImpression from '~/cart/hooks/useOnMountImpression'
import { HitBuyBox } from '~/cart/modules/recommend/types'
import { getSignalTooltipTitle, RootState } from '~/cart/modules/reducers'
import { RecommendedUnitCouponNo } from '~/cart/modules/unit-coupon/types'
import areaCodes from '~/data/areaCodes'
import logRender from '~/lib/log-render'

/**
 * 말풍선 툴팁 넛징 타겟 여부
 * 추천 받은 쿠폰중 아직 발급되지 않은 쿠폰이 있는 경우에 넛징 타겟
 * @param recommendedCoupons
 */
const isNudgeTarget = (
  recommendedCoupons: RecommendedUnitCouponNo[],
): boolean => {
  return recommendedCoupons.some((coupon) => coupon.couponPolicyNo)
}

const SignalBox = ({
  tootipTitle,
  imp,
}: {
  tootipTitle: string
  imp: Impression
}): JSX.Element => {
  useOnMountImpression(imp)

  return (
    <div className="box__coupon-tooltip">
      <p className="text__tooltip">{tootipTitle}</p>
    </div>
  )
}

const ForBuyBox = ({ imp }: { imp: Impression }): JSX.Element => {
  const tab = useSelector((state: RootState) => state.cart.currentCartTab)

  const areaCode = useMemo(() => {
    const code = areaCodes?.TOOLTIP_RECOMMENDED_COUPON_FOR_BUY_BOX ?? {}

    return code[tab] ?? ''
  }, [tab])

  useOnMountImpression(imp)

  useOnMountImpression({
    areaCode,
    areaValues: {},
    source: 'CouponRecommendationTooltipContainer_ForBuyBox',
  })

  return (
    <div className="box__coupon-tooltip">
      <p className="text__tooltip">쿠폰적용하고 최저가를 만나보세요!</p>
    </div>
  )
}

const CouponRecommendationTooltipContainer = ({
  cartUnitId,
  isSignal,
  isCouponApplied,
  disable,
  children,
}: {
  cartUnitId: number
  isSignal: boolean
  isCouponApplied: boolean
  disable?: boolean
  children: React.ReactNode
}): JSX.Element => {
  logRender()

  const [showTooltip, setShowTooltip] = useState(false)

  const [fromBuyBox, setFromBuyBox] = useState(false)

  const recommendedCoupons = useSelector(
    (state: RootState) => state.unitCoupon.recommendedCoupons[cartUnitId],
  )

  const issuedCoupon = useSelector(
    (state: RootState) => state.unitCoupon.issuedCoupons[cartUnitId],
  )

  const signalTooltipTitle = getSignalTooltipTitle(
    isCouponApplied,
    issuedCoupon,
    recommendedCoupons,
  )

  const imp = useMemo(() => {
    return (
      recommendedCoupons
        ? {
            areaCode: '200010207',
            areaValues: {
              ['coupon_master_nos']: JSON.stringify(
                recommendedCoupons.map(({ couponPolicyNo }) => couponPolicyNo),
              ),
            },
            source: 'CouponRecommendationTooltipContainer',
          }
        : {}
    ) as Impression
  }, [recommendedCoupons])

  useEffect(() => {
    setShowTooltip(disable ? false : isNudgeTarget(recommendedCoupons || []))
  }, [recommendedCoupons, issuedCoupon, disable])

  const onHit = useCallback(
    ({ toCartUnitId }: HitBuyBox) => {
      if (toCartUnitId === cartUnitId) {
        setFromBuyBox(true)
      }
    },
    [cartUnitId],
  )

  useHitBuyBox(onHit)

  return (
    <div className="section item_coupon">
      {showTooltip && fromBuyBox && <ForBuyBox imp={imp} />}
      {!fromBuyBox && isSignal && signalTooltipTitle && (
        <SignalBox tootipTitle={signalTooltipTitle} imp={imp} />
      )}
      {children}
    </div>
  )
}

export default CouponRecommendationTooltipContainer
