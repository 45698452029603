import React from 'react'
import CouponReApplyNotiToast from '~/cart/gmarket/ko/common/containers/Toast/CouponReApplyNotiToast'
import HitBuyBoxToast from '~/cart/gmarket/ko/common/containers/Toast/HitBuyBoxToast'
import SmileDeliveryCutOffNotiToast from '~/cart/gmarket/ko/common/containers/Toast/SmileDeliveryCutOffNotiToast'
import logRender from '~/lib/log-render'

const ToastContainer = (): JSX.Element => {
  logRender()

  return (
    <>
      <SmileDeliveryCutOffNotiToast />
      <CouponReApplyNotiToast />
      <HitBuyBoxToast />
    </>
  )
}

export default ToastContainer
