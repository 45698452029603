import React from 'react'
import CartSelector from '~/cart/gmarket/ko/common/containers/CartSelector'
import UniverseClubNudgingContainer from '~/cart/gmarket/ko/common/containers/UniverseClubNudgingContainer'
import areaCodes from '~/data/areaCodes'

const CartToolbar = ({
  isDomesticBusinessman,
  isMobile,
  onClickDeleteSelectedCartUnit,
  onClickOpenEstimate,
}: {
  isDomesticBusinessman: boolean
  isMobile: boolean
  onClickDeleteSelectedCartUnit: React.MouseEventHandler<HTMLElement>
  onClickOpenEstimate: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div className="cart_option" id="cart_option">
      <div className="inner_cont">
        <div className="cart_option_area">
          {!isMobile && <UniverseClubNudgingContainer />}
          <ul className="cart_option_box">
            <li className="select_all">
              <CartSelector
                data-montelena-acode={areaCodes.SELECT_ALL}
                id="item_all_select"
                showingTitle={true}
              >
                전체선택
              </CartSelector>
            </li>
            <li>
              <button
                data-montelena-acode={areaCodes.DELETE_SELECT}
                className="btn_del"
                onClick={onClickDeleteSelectedCartUnit}
              >
                <span>선택삭제</span>
              </button>
            </li>
            {isDomesticBusinessman && !isMobile && (
              <li>
                <button
                  className="btn_print"
                  onClick={onClickOpenEstimate}
                  data-montelena-acode={areaCodes.PRINT_ESTIMATE}
                >
                  <span>견적서 출력</span>
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CartToolbar
