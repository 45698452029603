import React, { useEffect, useMemo } from 'react'
import HeaderHelper from '~/lib/header-helper'
import PageInitializer from '~/lib/page-initializer'
import UXEHelper from '~/lib/uxe-helper'
const Footer = (): JSX.Element => {
  const headerHelper = useMemo(() => new HeaderHelper(), [])
  useEffect(() => {
    headerHelper.renderFooter()
    return (): void => {
      headerHelper.removeFooter()
    }
  }, [headerHelper])
  return (
    <div
      id="footer"
      onClick={UXEHelper.onMultipleClick('goOldButtonTrigger', 10, () => {
        console.log('triggered')
        PageInitializer.setEnvTagAndGoOldButton(true)
      })}
    />
  )
}

export default React.memo(Footer)
