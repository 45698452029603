import React from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import UnitCouponBoxMobileContainer from '../../../common/containers/CouponBox/UnitCouponBoxMobileContainer'

type LayerCouponBoxProps = LayerProps & {
  detailData?: {
    cartUnitId: number
  }
}

const LayerUnitCouponBox = ({
  layerId,
  layerKey,
  onCloseLayer,
  detailData,
}: LayerCouponBoxProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  if (!detailData) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  const { cartUnitId } = detailData

  return (
    <div>
      <UnitCouponBoxMobileContainer
        layerId={layerId}
        cartUnitId={cartUnitId}
        onCloseLayer={onCloseLayer()}
      />
    </div>
  )
}

export default LayerUnitCouponBox
