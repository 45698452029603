import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { formatAsDom } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

type LayerConfirmAuthenticationProp = LayerProps & {
  onCloseLayerAsync: (closeImmediately?: boolean) => Promise<void>
  detailData?: {
    message: string
    onConfirm: (isConfirm: boolean) => void
  }
}

const LayerConfirmAuthentication = ({
  layerId,
  layerKey,
  onCloseLayerAsync,
  detailData,
}: LayerConfirmAuthenticationProp): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const onClickConfirmButton = useCallback(
    (isConfirm: boolean) => async (): Promise<void> => {
      await onCloseLayerAsync(true)
      if (detailData) {
        detailData.onConfirm(isConfirm)
      }
    },
    [detailData, onCloseLayerAsync],
  )

  if (!detailData) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div className="nv-layer" id={layerId} style={{ display: 'block' }}>
      <div className="nv-layer-bg" onClick={onClickConfirmButton(false)} />
      <div className="nv-layer-cont">
        <div className="box__layer-authenticate">
          <strong className="title">본인인증</strong>

          <p className="text__message">{formatAsDom(detailData.message)}</p>
          <div className="box__button">
            <button
              className="button__confirm"
              onClick={onClickConfirmButton(true)}
            >
              본인인증하기
            </button>
            <button
              type="button"
              className="button__cancel"
              onClick={onClickConfirmButton(false)}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerConfirmAuthentication
