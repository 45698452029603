import React from 'react'
import { CartUnitCounts } from '~/cart/modules/cart/types'
import areaCodes from '~/data/areaCodes'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { CartTabType } from '~/types/enums'

const CartTab = ({
  currentCartTab,
  onClickCartTab,
  cartUnitCounts,
}: {
  currentCartTab: CartTabType
  onClickCartTab: (cartTab: CartTabType) => React.MouseEventHandler<HTMLElement>
  cartUnitCounts: CartUnitCounts
}): JSX.Element => {
  return (
    <div className="cart_tab">
      <div className="inner_cont">
        <ul className="cart_type_group" role="tablist">
          <li
            className={`tab ${currentCartTab === 'All' ? 'active' : ''}`}
            role="none"
          >
            <CustomAnchor
              onClick={onClickCartTab('All')}
              data-montelena-acode={areaCodes.ALL_CART_TAB}
              role="tab"
              aria-selected={currentCartTab === 'All'}
            >
              <strong className="tab_title">전체</strong>
              <span className="for_a11y">장바구니 갯수</span>
              <span className="count">
                <em className="num">{cartUnitCounts['All']}</em>
                <span className="for_a11y">개</span>
              </span>
            </CustomAnchor>
          </li>
          <li
            className={`tab ${currentCartTab === 'SmileFresh' ? 'active' : ''}`}
            role="none"
          >
            <CustomAnchor
              onClick={onClickCartTab('SmileFresh')}
              data-montelena-acode={areaCodes.SMILE_FRESH_CART_TAB}
              role="tab"
              aria-selected={currentCartTab === 'SmileFresh'}
            >
              <strong className="tab_title">스마일프레시</strong>
              <span className="for_a11y">장바구니 갯수</span>
              <span className="count">
                <em className="num">{cartUnitCounts['SmileFresh']}</em>
                <span className="for_a11y">개</span>
              </span>
            </CustomAnchor>
          </li>
          <li
            className={`tab ${
              currentCartTab === 'SmileDelivery' ? 'active' : ''
            }`}
            role="none"
          >
            <CustomAnchor
              onClick={onClickCartTab('SmileDelivery')}
              data-montelena-acode={areaCodes.SMILE_DELIVERY_CART_TAB}
              role="tab"
              aria-selected={currentCartTab === 'SmileDelivery'}
            >
              <strong className="tab_title">스마일배송</strong>
              <span className="for_a11y">장바구니 갯수</span>
              <span className="count">
                <em className="num">{cartUnitCounts['SmileDelivery']}</em>
                <span className="for_a11y">개</span>
              </span>
            </CustomAnchor>
          </li>
          <li
            className={`tab ${
              currentCartTab === 'ExpressShop' ? 'active' : ''
            }`}
            role="none"
          >
            <CustomAnchor
              onClick={onClickCartTab('ExpressShop')}
              data-montelena-acode={areaCodes.EXPRESS_SHOP_CART_TAB}
              role="tab"
              aria-selected={currentCartTab === 'ExpressShop'}
            >
              <strong className="tab_title">당일배송</strong>
              <span className="for_a11y">장바구니 갯수</span>
              <span className="count">
                <em className="num">{cartUnitCounts['ExpressShop']}</em>
                <span className="for_a11y">개</span>
              </span>
            </CustomAnchor>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CartTab
