import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import CouponBoxToast from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToast'
import useUnitCouponDownloader from '~/cart/hooks/useUnitCouponDownloader'
import Montelena from '~/lib/montelena/montelena'

const FailCouponToast = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}): JSX.Element => {
  const { loading, fails } = useUnitCouponDownloader()

  const onToast = useCallback(() => {
    if (isEmpty(fails) || loading) {
      return
    }

    fails.forEach(({ couponPolicyNo, couponType, failedLifeCycle }) => {
      // 쿠폰 선택영역에 다운로드 실패 안내
      Montelena.logImpression(
        'IMP_VI',
        '200010209',
        {
          ['buyer_coupon_master_no_1']:
            couponType === 'BuyerCoupon' ? couponPolicyNo : '',
          ['super_coupon_master_no_2']:
            couponType === 'SuperCoupon' ? couponPolicyNo : '',
        },
        'CouponDownloadFailure',
      )

      if (failedLifeCycle !== 'permanent') {
        // 쿠폰 다운로드 실패시 노출되는 토스트 얼럿
        Montelena.logImpression(
          'IMP_VI',
          '200010220',
          {
            ['buyer_coupon_master_no_1']:
              couponType === 'BuyerCoupon' ? couponPolicyNo : '',
            ['super_coupon_master_no_2']:
              couponType === 'SuperCoupon' ? couponPolicyNo : '',
          },
          'FailCouponToast',
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <CouponBoxToast id="fail-coupon" onToast={onToast}>
      <p className="text">놓친 쿠폰을 받으세요!</p>
      <button
        type="button"
        className="button"
        onClick={onClick}
        disabled={loading}
        data-montelena-acode="200010211"
      >
        쿠폰받기
      </button>
    </CouponBoxToast>
  )
}

export default FailCouponToast
