import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import useAnchorScroll from '~/cart/hooks/useAnchorScroll'
import useBuyBox from '~/cart/hooks/useBuyBox'
import useCartTabTransition from '~/cart/hooks/useCartTabTransition'
import useHitBuyBox from '~/cart/hooks/useHitBuyBox'
import useRecommendedItem from '~/cart/hooks/useRecommendedItem'
import { getCartUnitById } from '~/cart/modules/cart/reducer'
import { HitBuyBox, RecommendedItemMap } from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'
import { ElementIds } from '~/data/consts'
import useDeepCompareCallback from '~/lib/deep-compare-hook/useDeepCompareCallback'
import useDeepCompareEffect from '~/lib/deep-compare-hook/useDeepCompareEffect'
import logRender from '~/lib/log-render'
import { delay } from '~/lib/utils'

const useBuyBoxRecommendation = (): void => {
  const [, recommendBuyBox, , , clear] = useBuyBox()
  const [, findItems] = useRecommendedItem()
  const recommend = useDeepCompareCallback(async () => {
    recommendBuyBox(async (loaded: string[]): Promise<RecommendedItemMap> => {
      return await findItems(loaded)
    })
  }, [recommendBuyBox, findItems])
  const debounced = useCallback(
    debounce(async (action: () => Promise<void>) => {
      await action()
    }, 1000),
    [],
  )
  useEffect(() => {
    debounced(recommend)
  }, [debounced, recommend])

  const tab = useSelector((state: RootState) => state.cart.currentCartTab)
  useDeepCompareEffect(() => {
    void clear()
  }, [tab])
}

const useAutoPositionForHitBuyBox = (): void => {
  const [toCartUnitId, setToCartUnitId] = useState(0)

  const onHit = useCallback(({ toCartUnitId }: HitBuyBox) => {
    setToCartUnitId(toCartUnitId)
  }, [])
  useHitBuyBox(onHit)

  const [anchor, setAnchor] = useState('')
  const [transition, setTransition] = useState(0)
  const addedCartUnitId = useSelector((state: RootState) => {
    return toCartUnitId > 0
      ? getCartUnitById(state.cart, toCartUnitId)?.cartUnitId ?? 0
      : 0
  }, isEqual)
  useEffect(() => {
    if (addedCartUnitId > 0) {
      setTransition(addedCartUnitId)
      delay(500).then(() => {
        setAnchor(`${ElementIds.CartUnit}${addedCartUnitId}`)
      })
    }
  }, [addedCartUnitId])
  useCartTabTransition(transition)
  useAnchorScroll(anchor)
}

const BuyBoxRecommendationContainer = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  logRender()

  useBuyBoxRecommendation()
  useAutoPositionForHitBuyBox()

  return <>{children}</>
}

export default BuyBoxRecommendationContainer
