import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import CouponBoxToast from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToast'
import useUnitCouponDownloader from '~/cart/hooks/useUnitCouponDownloader'
import Montelena from '~/lib/montelena'

const AutoCouponToast = (): JSX.Element => {
  const {
    loading,
    autoSucceed,
    downloadableUnitCoupons,
  } = useUnitCouponDownloader()

  const onToast = useCallback(() => {
    if (isEmpty(downloadableUnitCoupons) || loading) {
      return
    }
    if (autoSucceed) {
      const buyerCoupon = downloadableUnitCoupons.find(
        ({ couponType }) => couponType === 'BuyerCoupon',
      )

      const superCoupon = downloadableUnitCoupons.find(
        ({ couponType }) => couponType === 'SuperCoupon',
      )

      // 자동발급된 쿠폰안내 토스트 얼럿
      Montelena.logImpression(
        'IMP_VI',
        '200010215',
        {
          ['buyer_coupon_master_no_1']: buyerCoupon
            ? buyerCoupon.couponPolicyNo
            : '',
          ['super_coupon_master_no_2']: superCoupon
            ? superCoupon.couponPolicyNo
            : '',
        },
        'AutoCouponToast',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <CouponBoxToast id="auto-coupon" onToast={onToast} autoClose={true}>
      <p className="text">놓친 쿠폰을 자동 발급해 드렸어요!</p>
    </CouponBoxToast>
  )
}

export default AutoCouponToast
