import React, { useEffect, useMemo, useState } from 'react'
import HeaderHelper from '~/lib/header-helper'

type HeaderProps = {
  title: string
  initialTitle: string
  showingAppHeader: boolean
  closeUrl?: string
  isCoreApp?: boolean
}
const Header = ({
  title,
  initialTitle,
  showingAppHeader,
  closeUrl,
  isCoreApp,
}: HeaderProps): JSX.Element => {
  const headerHelper = useMemo(() => new HeaderHelper(), [])

  const [isHeaderRendered, setHeaderRendered] = useState(false)

  useEffect(() => {
    headerHelper.renderHeader(initialTitle).then(() => {
      setHeaderRendered(true)
    })
    return (): void => {
      headerHelper.removeHeader()
      setHeaderRendered(false)
    }
  }, [headerHelper, initialTitle])

  useEffect(() => {
    if (isHeaderRendered) {
      headerHelper.setHeaderTitle(title)
    }
  }, [headerHelper, isHeaderRendered, title])

  useEffect(() => {
    if (isHeaderRendered) {
      if (isCoreApp) {
        if (showingAppHeader) {
          headerHelper.showAppHeader(title)
        } else {
          headerHelper.hideAppHeader(title)
        }
      }
    }
  }, [headerHelper, isCoreApp, isHeaderRendered, showingAppHeader, title])

  useEffect(() => {
    headerHelper.setCloseUrl(closeUrl)
  }, [closeUrl, headerHelper])

  return <div id="header" />
}

export default React.memo(Header)
