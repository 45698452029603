import moment from 'moment'
import React, { useState } from 'react'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import { TotalCashbackSummaryType } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import tenantConstants from '~/data/checkout-constants'
import { CartTabType } from '~/types/enums'

const OrderSummaryBenefit = ({
  cashbackSummary,
  currentCartTab,
}: {
  cashbackSummary: TotalCashbackSummaryType
  currentCartTab: CartTabType
}): JSX.Element => {
  const [isShowingBenefit, setShowingBenefit] = useState(false)
  const [isShowingSsgPointBenefit, setShowingSsgPointBenefit] = useState(false)

  return (
    <div className="box__order-benefit">
      <div className="box__benefit box__benefit--cash">
        {cashbackSummary.totalCashbackAmount > 0 && (
          <div className="box__benefit-summery">
            <span className="text__label">스마일캐시 적립</span>
            <span className="box__value">
              <button
                className={`button__detail sprite__cart--after ${
                  isShowingBenefit ? 'button__detail--active' : ''
                }`}
                aria-expanded={isShowingBenefit}
                aria-controls="smilecash_detail"
                title="적립내역 간단히 보기"
                onClick={(): void => setShowingBenefit(!isShowingBenefit)}
                data-montelena-acode={areaCodes.SMILE_CASH_DETAIL}
              >
                <span className="format-price">
                  <span className="box__format-amount">
                    <FormatPrice
                      krwAmount={cashbackSummary.totalCashbackAmount}
                    />
                  </span>
                </span>
              </button>
            </span>
          </div>
        )}
        {cashbackSummary.appliedClubDayCashback > 0 &&
          cashbackSummary.clubDayCashbackRate > 0 &&
          currentCartTab === 'SmileDelivery' && (
            <div className="box__clubday">
              <p className="text__clubday">
                <span className="sprite__cart image__smileclub">멤버십</span>{' '}
                {moment().locale(tenantConstants.MomentLocale).format('ddd')}
                요일은 클럽데이! 오늘만{' '}
                <strong className="text__emphasis">
                  {cashbackSummary.clubDayCashbackRate}% 더 적립
                </strong>
                해드려요!
              </p>
            </div>
          )}
        {isShowingBenefit && (
          <div className="box__benefit-detail" aria-hidden={!isShowingBenefit}>
            <ul className="list__detail">
              {cashbackSummary.appliedClubDayCashback > 0 &&
                cashbackSummary.clubDayCashbackRate > 0 && (
                  <li className="list list-clubday">
                    <span className="label">
                      클럽데이 {cashbackSummary.clubDayCashbackRate}% 추가 적립
                    </span>
                    <FormatPrice
                      krwAmount={cashbackSummary.appliedClubDayCashback}
                    />
                  </li>
                )}
              {cashbackSummary.appliedEventCashback > 0 && (
                <li className="list-item">
                  <span className="label">이벤트 적립</span>
                  <span className="format-price">
                    <span className="box__format-amount">
                      <FormatPrice
                        krwAmount={cashbackSummary.appliedEventCashback}
                      />
                    </span>
                  </span>
                </li>
              )}
              {cashbackSummary.sellerCashback > 0 && (
                <>
                  <li className="list-item">
                    <span className="label">판매자 제공 적립</span>
                    <span className="format-price">
                      <span className="box__format-amount">
                        <FormatPrice
                          krwAmount={cashbackSummary.sellerCashback}
                        />
                      </span>
                    </span>
                  </li>
                  {((cashbackSummary.appliedClubDayCashback > 0 &&
                    cashbackSummary.clubDayCashbackRate > 0) ||
                    cashbackSummary.appliedEventCashback > 0) && (
                    <li className="list-item use_info">
                      <span className="label">
                        스마일페이로 결제 시 (
                        <span className="date">
                          {moment
                            .unix(cashbackSummary.useEnableDateUnix)
                            .format('M/D')}
                        </span>
                        부터 사용가능)
                      </span>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        )}
      </div>
      {cashbackSummary.appliedSsgPointCashback > 0 && (
        <div className="box__benefit box__benefit--point">
          <div className="box__benefit-summery">
            <span className="text__label">신세계포인트 적립</span>
            <span className="box__value">
              <button
                className={`button__detail sprite__cart--after ${
                  isShowingSsgPointBenefit ? 'button__detail--active' : ''
                }`}
                aria-expanded={isShowingSsgPointBenefit}
                title="적립내역 간단히 보기"
                onClick={(): void =>
                  setShowingSsgPointBenefit(!isShowingSsgPointBenefit)
                }
              >
                <span className="format-price">
                  <span className="box__format-amount">
                    <FormatPrice
                      krwAmount={cashbackSummary.appliedSsgPointCashback}
                    />
                  </span>
                </span>
              </button>
            </span>
          </div>
          <div
            className="box__benefit-detail"
            aria-hidden={!isShowingSsgPointBenefit}
          >
            <ul className="list__detail">
              <li className="list-item">
                <span className="label">멤버십 적립</span>
                <span className="format-price">
                  <span className="box__format-amount">
                    <FormatPrice
                      krwAmount={cashbackSummary.appliedSsgPointCashback}
                    />
                  </span>
                </span>
              </li>
              <li className="list-item use_info">
                {moment(cashbackSummary.ssgCashbackDate).isValid() && (
                  <span className="label">
                    스마일페이로 결제 시 (
                    <span className="date">
                      {moment(cashbackSummary.ssgCashbackDate).format('M/D')}
                    </span>{' '}
                    이후 적립예정)
                  </span>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrderSummaryBenefit
