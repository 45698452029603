import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchGetRecommendedCoupons } from '~/apis/coupon'
import { CartUnit, CartUnitPriceType } from '~/cart/modules/cart/types'
import {
  getCartUnitList,
  getCartUnitPriceInfo,
  getSelectedCartUnitList,
  RootState,
} from '~/cart/modules/reducers'
import { CouponDownloadState } from '~/cart/modules/types'
import {
  clearRecommendedUnitCoupons,
  updateIssuedUnitCoupons,
  updateRecommendedUnitCoupons,
} from '~/cart/modules/unit-coupon/actions'
import { getAllAppliedUnitCouponList } from '~/cart/modules/unit-coupon/reducer'
import {
  IssuedUnitCouponMap,
  RecommendedUnitCouponMap,
  UnitCoupon,
} from '~/cart/modules/unit-coupon/types'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import useDeepCompareCallback from '~/lib/deep-compare-hook/useDeepCompareCallback'
import useDeepCompareMemo from '~/lib/deep-compare-hook/useDeepCompareMemo'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'

type UnitCouponRecommendationTarget = {
  cartUnitIds: number[]
}

type CartUnitAndPriceMap = {
  [cartUnitId: number]: CartUnit & {
    summary: CartUnitPriceType
  }
}

/**
 * 구매 가능하고 유닛 쿠폰 사용이 가능한 cartUnit 과 그 가격 정보 목록 조회
 */
const getBuyAvailableCartUnitAndPriceMap = (
  state: RootState,
): CartUnitAndPriceMap => {
  return getCartUnitList(state)
    .filter((unit) => unit.isCouponUsable)
    .reduce((acc, curr) => {
      acc[curr.cartUnitId] = {
        ...curr,
        summary: getCartUnitPriceInfo(state, curr.cartUnitId),
      }

      return acc
    }, {} as CartUnitAndPriceMap)
}

/**
 * 선택된 장바구니 항목에 적용된 쿠폰 목록
 */
const getCouponsOnSelected = (state: RootState): UnitCoupon[] => {
  const selectedCartUnitIds = getSelectedCartUnitList(state).map(
    (cartUnit) => cartUnit.cartUnitId,
  )

  return getAllAppliedUnitCouponList(state.unitCoupon, (cartUnitId) =>
    selectedCartUnitIds.includes(cartUnitId),
  )
}

/**
 * 자동 or 수동으로 다운로드 완료된 쿠폰 정책번호 목록 조회
 */
const getDownloadedCoupons = (state: RootState): number[] => {
  return state.unitCoupon.downloadableUnitCoupons
    .filter(
      ({ downloadState }) =>
        downloadState === CouponDownloadState.AutoSuccess ||
        downloadState === CouponDownloadState.ManualSuccess,
    )
    .map(({ couponPolicyNo }) => couponPolicyNo)
}

const mapToRecommendationTarget =
  (selectedCartUnitAndPriceMap: CartUnitAndPriceMap) =>
  (cartUnitId: number): cartFE.RecommendationTarget => {
    const {
      item,
      cartUnitType,
      expressShopBranch,
      seller,
      summary,
      quantity,
      shippingPolicyId,
      partnershipCode,
    } = selectedCartUnitAndPriceMap[cartUnitId]

    const {
      itemNo,
      itemSellPrice = 0,
      largeCategoryCode,
      mediumCategoryCode,
      smallCategoryCode,
      isZeroPrice,
      isMoneyCategory,
      isSmileDelivery,
      brandId,
    } = item

    return {
      key: cartUnitId.toString(),
      itemNo,
      itemPrice: itemSellPrice,
      sellerKey: seller.sellerKey,
      category: {
        large: largeCategoryCode,
        medium: mediumCategoryCode,
        small: smallCategoryCode,
      },
      shippingPolicyId: shippingPolicyId,
      attribute: {
        isZeroPrice,
        isMoneyCategory,
        isSmileDelivery,
      },
      brandId,
      options: [
        {
          additionTotalAmount: summary.cartUnitAdditionsTotalPrice,
          branchAdditionalPrice: summary.cartUnitBranchAdditionalPrice,
          optionsPrice: summary.cartUnitOptionsAdditionalPrice,
          quantity: quantity || 0,
          branchCode:
            cartUnitType === 'ExpressShop'
              ? expressShopBranch?.branchCode || seller.sellerKey
              : '',
        },
      ],
      jaehuId: partnershipCode,
    }
  }

const reduceToIssuedUnitCouponMap = (
  acc: IssuedUnitCouponMap,
  curr: cartFE.GetRecommendedCouponsResponse,
): IssuedUnitCouponMap => {
  const { key, issuedCoupons } = curr
  const cartUnitId = parseInt(key)

  if (issuedCoupons) {
    acc[cartUnitId] = issuedCoupons.map((coupon) => coupon)
  }
  return acc
}

const reduceToRecommendedUnitCouponMap = (
  acc: RecommendedUnitCouponMap,
  curr: cartFE.GetRecommendedCouponsResponse,
): RecommendedUnitCouponMap => {
  const { key, recommendedCoupons } = curr
  const cartUnitId = parseInt(key)

  if (recommendedCoupons && recommendedCoupons.length > 0) {
    acc[cartUnitId] = recommendedCoupons.map((coupon) => {
      const { couponPolicyNo } = coupon
      return {
        couponPolicyNo,
      }
    })
  }

  return acc
}

const useUnitCouponRecommendation = (
  selector: (
    target: UnitCouponRecommendationTarget,
  ) => UnitCouponRecommendationTarget,
): [UnitCouponRecommendationTarget, () => Promise<void>] => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const buyAvailableCartUnitAndPriceMap = useSelector(
    getBuyAvailableCartUnitAndPriceMap,
    isEqual,
  )

  const couponsOnSelected = useSelector(getCouponsOnSelected, isEqual)

  const downloadedCoupons = useSelector(getDownloadedCoupons, shallowEqual)

  const targetedCartUnitIds = useMemo(
    () => Object.keys(buyAvailableCartUnitAndPriceMap).map(Number),
    [buyAvailableCartUnitAndPriceMap],
  )

  /**
   * 장바구니 유닛 쿠폰 추천 목록 조회
   * @param {number[]} cartUnitIds - 추천 대상 CartUnitId 목록
   */
  const recommend = useDeepCompareCallback(
    async (cartUnitIds: number[]): Promise<void> => {
      if (isEmpty(cartUnitIds)) {
        dispatch(clearRecommendedUnitCoupons())
        return
      }

      const targets = cartUnitIds.map(
        mapToRecommendationTarget(buyAvailableCartUnitAndPriceMap),
      )

      const couponRecommendations = await fetchGetRecommendedCoupons({
        targets: targets,
        couponsOnNotTargeted: couponsOnSelected.map(
          ({ couponPolicyNo, couponIssueNo, couponPrice }) => ({
            policyNo: couponPolicyNo,
            issueNo: couponIssueNo,
            appliedPrice: couponPrice,
          }),
        ),
      })

      dispatch(
        updateIssuedUnitCoupons(
          couponRecommendations.reduce(
            reduceToIssuedUnitCouponMap,
            {} as IssuedUnitCouponMap,
          ),
        ),
      )
      dispatch(
        updateRecommendedUnitCoupons(
          couponRecommendations.reduce(
            reduceToRecommendedUnitCouponMap,
            {} as RecommendedUnitCouponMap,
          ),
        ),
      )
    },
    [
      dispatch,
      buyAvailableCartUnitAndPriceMap,
      couponsOnSelected,
      downloadedCoupons,
    ],
  )

  const target = useMemo(
    () =>
      selector({
        cartUnitIds: targetedCartUnitIds,
      }),
    [selector, targetedCartUnitIds],
  )

  return useDeepCompareMemo(
    () => [
      target,
      (): Promise<void> => {
        const { cartUnitIds } = target
        return recommend(cartUnitIds).catch(ERROR_HANDLER_DO_NOTHING())
      },
    ],
    [target, recommend],
  )
}

export default useUnitCouponRecommendation
