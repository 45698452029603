import { compact, map, pickBy } from 'lodash'
import { createReducer } from 'typesafe-actions'
import { CouponDownloadMode, CouponDownloadState } from '~/cart/modules/types'
import {
  APPLY_UNIT_COUPONS,
  CLEAR_ALL_UNIT_COUPONS,
  CLEAR_RECOMMENDED_UNIT_COUPONS,
  SET_CLUB_WELCOME_GIFT,
  SET_COUPON_BOX_OPENED_CART_UNIT_ID,
  SET_DOWNLOADABLE_UNIT_COUPON_STATE,
  SET_DOWNLOADED_UNIT_COUPON_ISSUE_NO,
  SET_EXTRA_DISCOUNT_CANDIDATES,
  SET_TOTAL_UNIT_COUPON_COUNT,
  SET_UNIT_COUPON_BOX_OPENED,
  SET_UNIT_COUPON_DOWNLOADED,
  SET_UNIT_COUPON_LOADED,
  UPDATE_AVAILABLE_UNIT_COUPON,
  UPDATE_AVAILABLE_UNIT_COUPON_MULTI,
  UPDATE_DOWNLOADABLE_UNIT_COUPONS,
  UPDATE_ISSUED_UNIT_COUPONS,
  UPDATE_PAYMENT_UNIT_COUPON,
  UPDATE_RECOMMENDED_UNIT_COUPONS,
  UPDATE_UNAPPLY_UNIT_COUPON,
} from './actions'
import {
  DownloadableUnitCoupon,
  DownloadableUnitCouponFailedLifeCycle,
  DownloadableUnitCouponFailedReason,
  ExtraDiscountCandidate,
  UnitCoupon,
  UnitCouponAction,
  UnitCouponApplicationMap,
  UnitCouponState,
} from './types'

// 초기 상태 선언
const initialState: UnitCouponState = {
  unitCouponApplications: {},
  availableUnitCoupons: [],
  totalCouponCount: 0,
  couponLoadedCartUnitIds: [],
  recommendedCoupons: {},
  issuedCoupons: {},
  downloadableUnitCoupons: [],
  unitExtraDiscountCandidates: [],
  clubWelcomeGift: 0,
}

export const getAvailableUnitCouponList = (
  state: UnitCouponState,
  cartUnitId: number,
): UnitCoupon[] => {
  const coupons = state.availableUnitCoupons.find(
    (x) => x.cartUnitId === cartUnitId,
  )
  if (coupons && coupons.unitCouponList) {
    return coupons.unitCouponList
  } else {
    return []
  }
}

export const getAllUnitCouponApplicationList = (
  state: UnitCouponState,
  predicate: (
    cartUnitId: number,
    couponIssueNo: string,
  ) => boolean = (): boolean => true,
): UnitCouponApplicationMap => {
  return pickBy(
    state.unitCouponApplications,
    (cartUnitId = 0, couponIssueNo) =>
      cartUnitId > 0 && predicate(cartUnitId, couponIssueNo),
  )
}

export const getAllAppliedUnitCouponList = (
  state: UnitCouponState,
  predicate?: (cartUnitId: number, couponIssueNo: string) => boolean,
): (UnitCoupon & { cartUnitId: number })[] => {
  return map(
    getAllUnitCouponApplicationList(state, predicate),
    (cartUnitId, couponIssueNoString) => {
      return {
        cartUnitId: cartUnitId || 0,
        couponIssueNo: parseInt(couponIssueNoString),
      }
    },
  ).flatMap((x) => {
    const unitCoupon = getAvailableUnitCouponList(state, x.cartUnitId).find(
      (coupon) => coupon.couponIssueNo === x.couponIssueNo,
    )
    if (unitCoupon) {
      return [{ ...unitCoupon, cartUnitId: x.cartUnitId }]
    } else {
      return []
    }
  })
}

export const getAllAppliedUnitCouponNoList = (
  state: UnitCouponState,
  predicate?: (cartUnitId: number, couponIssueNo: string) => boolean,
): number[] => {
  return compact(
    map(
      getAllUnitCouponApplicationList(state, predicate),
      (_, couponIssueNoString) => parseInt(couponIssueNoString),
    ),
  )
}

export const getAppliedUnitCouponList = (
  state: UnitCouponState,
  cartUnitId: number,
): (UnitCoupon & { cartUnitId: number })[] => {
  return getAllAppliedUnitCouponList(state, (unitId) => unitId === cartUnitId)
}

export const getUnitCouponAppliedCartUnitId = (
  state: UnitCouponState,
  couponIssueNo: number,
): number | undefined => {
  return state.unitCouponApplications[couponIssueNo]
}

export const getUnitCoupon = (
  state: UnitCouponState,
  cartUnitId: number,
  couponIssueNo: number,
): UnitCoupon | undefined => {
  const available = state.availableUnitCoupons.find(
    (x) => x.cartUnitId === cartUnitId,
  )
  if (available) {
    return available.unitCouponList.find(
      (x) => x.couponIssueNo === couponIssueNo,
    )
  }
}

export const getAllAppliedCoupons = (state: UnitCouponState): UnitCoupon[] =>
  map(state.unitCouponApplications, (cartUnitId, couponIssueNoString) => {
    return {
      cartUnitId: cartUnitId || 0,
      couponIssueNo: parseInt(couponIssueNoString),
    }
  }).flatMap((x) => {
    const unitCoupon = getAvailableUnitCouponList(state, x.cartUnitId).find(
      (coupon) => coupon.couponIssueNo === x.couponIssueNo,
    )
    if (unitCoupon) {
      return [unitCoupon]
    } else {
      return []
    }
  })

export const getIsUnitCouponApplied = (
  state: UnitCouponState,
  cartUnitId: number,
): boolean =>
  Object.values(state.unitCouponApplications).some((x) => x === cartUnitId)

export const getIsCartUnitCouponBoxOpened = (
  state: UnitCouponState,
  cartUnitId: number,
): boolean => state.couponBoxOpenedCartUnitId === cartUnitId

export const updateAvailableUnitCouponList = (
  state: UnitCouponState,
  cartUnitId: number,
  couponIssueNo: number,
  couponPolicyNo: number,
  couponDownloadMode: CouponDownloadMode,
): UnitCoupon[] => {
  const unitCoupons = getAvailableUnitCouponList(state, cartUnitId)

  if (!unitCoupons) {
    return []
  }

  return unitCoupons.map((unitCoupon) => {
    if (unitCoupon.couponPolicyNo === couponPolicyNo && unitCoupon.issuable) {
      return {
        ...unitCoupon,
        couponIssueNo,
        issuable: false,
        isAutoDownloadedCoupon: couponDownloadMode === CouponDownloadMode.Auto,
      }
    } else {
      return unitCoupon
    }
  })
}

export const updateUnitCouponDownloaded = (
  state: UnitCouponState,
  cartUnitId: number,
  couponPolicyNo: number,
  isDownloading: boolean,
): UnitCoupon[] => {
  const unitCoupons = getAvailableUnitCouponList(state, cartUnitId)

  if (!unitCoupons) {
    return []
  }

  return unitCoupons.map((unitCoupon) => {
    if (isDownloading) {
      if (unitCoupon.couponPolicyNo === couponPolicyNo && unitCoupon.issuable) {
        return {
          ...unitCoupon,
          isDownloading,
        }
      }
    } else {
      if (unitCoupon.couponPolicyNo === couponPolicyNo) {
        return {
          ...unitCoupon,
          isDownloading,
        }
      }
    }

    return unitCoupon
  })
}

const updateDownloadableUnitCouponList = (
  state: UnitCouponState,
  couponPolicyNo: number,
  downloadState: CouponDownloadState,
  failedReason?: DownloadableUnitCouponFailedReason,
  failedLifeCycle?: DownloadableUnitCouponFailedLifeCycle,
): DownloadableUnitCoupon[] => {
  const downloadableUnitCoupons = state.downloadableUnitCoupons

  if (!downloadableUnitCoupons) {
    return []
  }
  return downloadableUnitCoupons.map((unitCoupon) => {
    if (unitCoupon.couponPolicyNo === couponPolicyNo) {
      return {
        ...unitCoupon,
        downloadState,
        failedReason,
        failedLifeCycle,
      }
    } else {
      return unitCoupon
    }
  })
}

const updateUnapplyUnitCoupon = (
  state: UnitCouponState,
  cartUnitId: number,
  couponIssueNo: number,
): UnitCoupon[] => {
  const unitCoupons = getAvailableUnitCouponList(state, cartUnitId)

  if (!unitCoupons) {
    return []
  }

  return unitCoupons.map((unitCoupon) => {
    if (unitCoupon.couponIssueNo === couponIssueNo) {
      return {
        ...unitCoupon,
        appliedOnAnother: false,
        appliedPriceOnAnother: 0,
      }
    } else {
      return unitCoupon
    }
  })
}

const updatePaymentUnitCoupon = (
  state: UnitCouponState,
  cartUnitId: number,
  couponIssueNo: number,
): UnitCoupon[] => {
  const unitCoupons = getAvailableUnitCouponList(state, cartUnitId)

  if (!unitCoupons) {
    return []
  }

  return unitCoupons.map((unitCoupon) => {
    if (unitCoupon.couponIssueNo === couponIssueNo) {
      return {
        ...unitCoupon,
        isNotifiedPaymentCoupon: true,
      }
    } else {
      return unitCoupon
    }
  })
}

// 다운로드 대상 쿠폰 리스트
export const getDownloadTargetUnitCouponList = (
  state: UnitCouponState,
): DownloadableUnitCoupon[] => {
  return state.downloadableUnitCoupons.filter(
    (unitCoupon) =>
      unitCoupon.downloadState === CouponDownloadState.Loading ||
      unitCoupon.downloadState === CouponDownloadState.Fail,
  )
}

export const getExtraDiscountCandidates = (
  state: UnitCouponState,
  cartUnitId: number,
): ExtraDiscountCandidate[] =>
  state.unitExtraDiscountCandidates.find(
    (unitExtraDiscountCandidate) =>
      unitExtraDiscountCandidate.cartUnitId === cartUnitId,
  )?.extraDiscountCandidates || []

// 리듀서 작성
const unitCoupon = createReducer<UnitCouponState, UnitCouponAction>(
  initialState,
  {
    [UPDATE_AVAILABLE_UNIT_COUPON]: (state, { payload }) => ({
      ...state,
      availableUnitCoupons: [
        ...state.availableUnitCoupons.filter(
          (x) => x.cartUnitId !== payload.cartUnitId,
        ),
        payload,
      ],
    }),
    [UPDATE_AVAILABLE_UNIT_COUPON_MULTI]: (state, { payload }) => ({
      ...state,
      availableUnitCoupons: [
        ...state.availableUnitCoupons.filter((x) =>
          payload.some((y) => x.cartUnitId !== y.cartUnitId),
        ),
        ...payload,
      ],
    }),
    [APPLY_UNIT_COUPONS]: (state, { payload }) => {
      const newApplications = { ...state.unitCouponApplications }
      payload.forEach(({ couponIssueNo, cartUnitId }) => {
        newApplications[couponIssueNo] = cartUnitId
      })
      return {
        ...state,
        unitCouponApplications: newApplications,
      }
    },
    [CLEAR_ALL_UNIT_COUPONS]: (state, { payload: { cartUnitId } }) => {
      const newApplications = { ...state.unitCouponApplications }
      const appliedUnitCouponIssueNoList = getAppliedUnitCouponList(
        state,
        cartUnitId,
      ).map((x) => x.couponIssueNo)
      appliedUnitCouponIssueNoList.forEach((issueNo) => {
        delete newApplications[issueNo]
      })
      return {
        ...state,
        unitCouponApplications: newApplications,
      }
    },
    [SET_TOTAL_UNIT_COUPON_COUNT]: (state, { payload: totalCouponCount }) => ({
      ...state,
      totalCouponCount,
    }),
    [SET_UNIT_COUPON_BOX_OPENED]: (state, { payload: cartUnitId }) => ({
      ...state,
      couponBoxOpenedCartUnitId: cartUnitId,
    }),
    [SET_UNIT_COUPON_LOADED]: (
      state,
      { payload: { cartUnitId, isLoaded } },
    ) => ({
      ...state,
      couponLoadedCartUnitIds: state.couponLoadedCartUnitIds
        .filter((x) => x !== cartUnitId)
        .concat(isLoaded ? [cartUnitId] : []),
    }),
    [SET_COUPON_BOX_OPENED_CART_UNIT_ID]: (state, { payload }) => ({
      ...state,
      couponBoxOpenedCartUnitId: payload,
    }),
    [UPDATE_RECOMMENDED_UNIT_COUPONS]: (state, { payload }) => ({
      ...state,
      recommendedCoupons: payload,
    }),
    [UPDATE_ISSUED_UNIT_COUPONS]: (state, { payload }) => ({
      ...state,
      issuedCoupons: payload,
    }),
    [CLEAR_RECOMMENDED_UNIT_COUPONS]: (state) => ({
      ...state,
      recommendedCoupons: {},
    }),
    [SET_DOWNLOADED_UNIT_COUPON_ISSUE_NO]: (state, { payload }) => {
      return {
        ...state,
        availableUnitCoupons: [
          ...state.availableUnitCoupons.filter(
            (x) => x.cartUnitId !== payload.cartUnitId,
          ),
          {
            cartUnitId: payload.cartUnitId,
            unitCouponList: updateAvailableUnitCouponList(
              state,
              payload.cartUnitId,
              payload.couponIssueNo,
              payload.couponPolicyNo,
              payload.couponDownloadMode,
            ),
          },
        ],
      }
    },

    [SET_UNIT_COUPON_DOWNLOADED]: (state, { payload }) => {
      return {
        ...state,
        availableUnitCoupons: [
          ...state.availableUnitCoupons.filter(
            (x) => x.cartUnitId !== payload.cartUnitId,
          ),
          {
            cartUnitId: payload.cartUnitId,
            unitCouponList: updateUnitCouponDownloaded(
              state,
              payload.cartUnitId,
              payload.couponPolicyNo,
              payload.isDownloading,
            ),
          },
        ],
      }
    },
    [UPDATE_DOWNLOADABLE_UNIT_COUPONS]: (state, { payload }) => ({
      ...state,
      downloadableUnitCoupons: payload,
    }),

    [SET_DOWNLOADABLE_UNIT_COUPON_STATE]: (state, { payload }) => ({
      ...state,
      downloadableUnitCoupons: updateDownloadableUnitCouponList(
        state,
        payload.couponPolicyNo,
        payload.downloadState,
        payload.failedReason,
        payload.failedLifeCycle,
      ),
    }),

    [UPDATE_UNAPPLY_UNIT_COUPON]: (state, { payload }) => ({
      ...state,
      availableUnitCoupons: [
        ...state.availableUnitCoupons.filter(
          (x) => x.cartUnitId !== payload.cartUnitId,
        ),
        {
          cartUnitId: payload.cartUnitId,
          unitCouponList: updateUnapplyUnitCoupon(
            state,
            payload.cartUnitId,
            payload.couponIssueNo,
          ),
        },
      ],
    }),

    [UPDATE_PAYMENT_UNIT_COUPON]: (state, { payload }) => ({
      ...state,
      availableUnitCoupons: [
        ...state.availableUnitCoupons.filter(
          (x) => x.cartUnitId !== payload.cartUnitId,
        ),
        {
          cartUnitId: payload.cartUnitId,
          unitCouponList: updatePaymentUnitCoupon(
            state,
            payload.cartUnitId,
            payload.couponIssueNo,
          ),
        },
      ],
    }),

    [SET_EXTRA_DISCOUNT_CANDIDATES]: (state, { payload }) => ({
      ...state,
      unitExtraDiscountCandidates: [
        ...state.unitExtraDiscountCandidates.filter(
          (unitExtraDiscountCandidate) =>
            unitExtraDiscountCandidate.cartUnitId !== payload.cartUnitId,
        ),
        payload,
      ],
    }),

    [SET_CLUB_WELCOME_GIFT]: (state, { payload: welcomeGift }) => ({
      ...state,
      clubWelcomeGift: welcomeGift,
    }),
  },
)

export default unitCoupon
