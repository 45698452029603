import React, { useEffect, useMemo } from 'react'
import BuyBoxCard from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxCard'
import { useBuyBoxContext } from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxContext'
import useBuyBoxImpression from '~/cart/hooks/useBuyBoxImpression'
import { BuyBoxItem } from '~/cart/modules/recommend/types'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'

interface LowestPriceBuyBoxProps {
  title: string
  item: BuyBoxItem
}

const LowestPriceBuyBox = ({
  title,
  item,
}: LowestPriceBuyBoxProps): JSX.Element => {
  const { tab, buyBox, appearedItems } = useBuyBoxContext()

  const { itemNo, couponAppliedPrice, imageUrl } = item

  const appeared = useMemo(
    () => appearedItems.includes(itemNo),
    [appearedItems, itemNo],
  )

  const [logImp, dataProps] = useBuyBoxImpression(
    'Card',
    { tab, itemNo, buyBox },
    'LowestPriceBuyBox',
  )

  useEffect(() => {
    if (appeared) {
      logImp()
    }
  }, [appeared, logImp])

  return (
    <BuyBoxCard>
      <BuyBoxCard.Title title={title} />
      <CustomAnchor
        href={formatString(domains.VIP, itemNo)}
        className="link__item-info"
        {...dataProps}
      >
        <BuyBoxCard.Thumbnail img={imageUrl} />
        <BuyBoxCard.LowestPrice price={couponAppliedPrice} />
      </CustomAnchor>
    </BuyBoxCard>
  )
}

export default React.memo(LowestPriceBuyBox)
