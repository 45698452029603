import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useBuyBoxContext } from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxContext'
import BuyBoxSection from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxSection'
import useBuyBox from '~/cart/hooks/useBuyBox'
import { ItemGroupView } from '~/cart/modules/cart/types'
import { BuyBoxItem } from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'
import domains from '~/data/domains'
import Filter from '~/lib/components/Filter'
import { formatString } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import { delay } from '~/lib/utils'

interface BuyBoxContainerProps {
  itemGroup: ItemGroupView
}

const hrefVIP = (itemNo: string): void => {
  window.location.href = formatString(domains.VIP, itemNo)
}

const Card = ({ buyBoxItem }: { buyBoxItem: BuyBoxItem }): JSX.Element => {
  const { buyBox } = useBuyBoxContext()

  return (
    <Filter selected={buyBox.type}>
      <Filter.In list={['GoodDeal']}>
        <BuyBoxSection.GoodDeal item={buyBoxItem} />
      </Filter.In>
      <Filter.In list={['SoldOut']}>
        <BuyBoxSection.SoldOut item={buyBoxItem} />
      </Filter.In>
    </Filter>
  )
}

const Button = ({ buyBoxItem }: { buyBoxItem: BuyBoxItem }): JSX.Element => {
  const { buyBox } = useBuyBoxContext()

  const [, , addCart, replaceCart] = useBuyBox()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const command = useMemo(
    () => (buyBox.type === 'SoldOut' ? replaceCart : addCart),
    [addCart, buyBox.type, replaceCart],
  )

  return (
    <Filter selected={buyBox.action}>
      <Filter.In list={['ReplaceCart']}>
        <BuyBoxSection.AddCartButton
          itemNo={buyBoxItem.itemNo}
          label={isMobile ? '담기' : '장바구니 담기'}
          onClick={async (): Promise<void> => {
            await command(buyBox.fromItemNo, buyBoxItem.itemNo, async () => {
              //너무 빠른 장바구니 state 업데이트로 고객이 불안정한 장바구니 목록 변경을 경험하게 되므로.
              await delay(200)
            })
          }}
        />
      </Filter.In>
      <Filter.In list={['SelectOption']}>
        <BuyBoxSection.SelectOptionButton
          itemNo={buyBoxItem.itemNo}
          onClick={(): void => {
            hrefVIP(buyBoxItem.itemNo)
          }}
        />
      </Filter.In>
    </Filter>
  )
}

const BuyBoxContainer = ({ itemGroup }: BuyBoxContainerProps): JSX.Element => {
  logRender()

  const tab = useSelector((state: RootState) => state.cart.currentCartTab)

  const [buyBoxMap] = useBuyBox()

  const buyBox = useMemo(() => {
    const box = buyBoxMap[itemGroup.key]
    const isTarget = itemGroup.cartUnitIdList.every((id) =>
      box?.fromCartUnitIds.includes(id),
    )
    return isTarget ? box : undefined
  }, [buyBoxMap, itemGroup])

  if (!buyBox) {
    return <></>
  }

  return (
    <BuyBoxSection tab={tab} buyBox={buyBox}>
      {buyBox.items.map((item) => (
        <BuyBoxSection.IntersectionObserved
          itemNo={item.itemNo}
          key={`buy_box-${item.itemNo}`}
        >
          <Card buyBoxItem={item} />
          <Button buyBoxItem={item} />
        </BuyBoxSection.IntersectionObserved>
      ))}
    </BuyBoxSection>
  )
}

export default BuyBoxContainer
