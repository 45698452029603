import { map } from 'lodash'
import React, { useEffect } from 'react'
import { fetchClientLog } from '~/apis/log'

type GatewayFormProps = {
  target?: string
  src: string
  formRef: React.RefObject<HTMLFormElement>
  formData: GatewayFormData
  acceptCharset?: string
  method?: string
}
const GatewayForm = ({
  target,
  src,
  formRef,
  formData,
  acceptCharset,
  method,
}: GatewayFormProps): JSX.Element => {
  useEffect(() => {
    fetchClientLog('gateway form submit - ' + src, formData).catch(() => {
      // DO NOTHING
    })

    // 변경사항 상관 안하고 무조건 처음값으로만 로그남기기 위함
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      ref={formRef}
      action={src}
      target={target}
      acceptCharset={acceptCharset || 'UTF-8'}
      method={method || 'post'}
    >
      {map(formData, (value, key) => {
        if (value instanceof Array) {
          return map(value, (val, idx) => (
            <input
              key={`${target}_${key}_${idx}`}
              name={key}
              value={
                typeof val === 'number' || typeof val === 'string' ? val : ''
              }
              type="hidden"
            />
          ))
        } else {
          return (
            <input
              key={`${target}_${key}`}
              name={key}
              value={value !== undefined && value !== null ? value : ''}
              type="hidden"
            />
          )
        }
      })}
    </form>
  )
}

export default GatewayForm
