import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { joinSmileClubByOneClick } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import AppSchemeHelper from '~/lib/app-scheme-helper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import UXEHelper from '~/lib/uxe-helper'
import { LayerProps } from '~/types/common'

const LayerSmileClubOneClickJoin = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const isApp = useSelector((state: RootState) => state.view.isApp)
  const isAndroid = useSelector((state: RootState) => state.view.isAndroid)
  const appType = useSelector((state: RootState) => state.view.appInfo?.appType)

  const [timeoutKey, setTimeoutKey] = useState<number>()

  useEffect(() => {
    UXEHelper.oneClickSmileClubLayerFunc('scene', 0, 'layer')
    return (): void => {
      UXEHelper.oneClickSmileClubLayerFunc('close')
    }
    // [] 로 전달되는 경우 마운트 될 때 한 번만 실행
  }, [])

  // 닫을때 timeout clear 해줘야함
  useEffect(() => {
    return (): void => {
      if (timeoutKey) {
        window.clearTimeout(timeoutKey)
      }
    }
  }, [timeoutKey])

  const onClickJoinSmileClub = useCallback(async (): Promise<void> => {
    UXEHelper.oneClickSmileClubLayerFunc('scene', 1, 'layer')
    if (await dispatch(joinSmileClubByOneClick('FakeCouponLayer'))) {
      UXEHelper.oneClickSmileClubLayerFunc('scene', 2, 'layer')
      // 가입 완료 후 3초 후 레이어 닫기
      setTimeoutKey(
        window.setTimeout(() => {
          onCloseLayer()()
        }, 3000),
      )
    } else {
      UXEHelper.oneClickSmileClubLayerFunc('scene', 0, 'layer')
    }
  }, [dispatch, onCloseLayer])

  const [iframeUrl, setIframeUrl] = useState<string>()

  const onClickOpenTerms = useCallback(
    (url: string) => (): void => {
      if (isMobile) {
        const appScheme = AppSchemeHelper.getOpenWindowScheme(
          isApp,
          isAndroid,
          appType,
        )
        if (appScheme) {
          window.location.href = formatString(appScheme, url)
        } else {
          window.open(url)
        }
      } else {
        setIframeUrl(url)
      }
    },
    [appType, isAndroid, isApp, isMobile],
  )

  return (
    <div className="nv-layer" id={layerId}>
      <div className="nv-layer-bg" onClick={onCloseLayer()} />
      <div className="nv-layer-cont">
        <div className="box__oneclick-smileclub box__layer-oneclick-smileclub">
          <button
            type="button"
            className="button__closed sprite__oneclick"
            onClick={onCloseLayer()}
            data-montelena-acode={areaCodes.CLOSE_ONE_CLICK_COUPON}
          >
            <span className="for-a11y">닫기</span>
          </button>
          <div className="box__scene box__scene--default">
            <p className="text__smileclub">
              <em className="text__emphasis">스마일클럽 무료이용</em>을 시작하면
              <br />
              할인 쿠폰이 적용됩니다.
            </p>
            <button
              className="button__smileclub-start"
              onClick={onClickJoinSmileClub}
              data-montelena-acode={areaCodes.JOIN_ONE_CLICK_ON_COUPON}
            >
              무료 이용 시작하기
            </button>
          </div>
          <div className="box__scene box__scene--loading">
            <p className="text__smileclub">
              <em className="text__emphasis">스마일클럽 무료이용</em>을 시작하면
              <br />
              할인 쿠폰이 적용됩니다.
            </p>
            <button className="button__smileclub-loading">
              <span className="sprite__oneclick text__button">
                스마일클럽 가입중입니다.
              </span>
            </button>
          </div>
          <div className="box__scene box__scene--success">
            <p className="text__smileclub text__smileclub1">
              스마일클럽 무료 이용이 시작되었어요.
            </p>
            <p className="text__smileclub text__smileclub2">
              이제, 바로 쿠폰을 사용하세요!
            </p>
            <button
              className="button__smileclub-confirm"
              onClick={onCloseLayer()}
              data-montelena-acode={areaCodes.COMPLETE_JOIN_ONE_CLICK_ON_COUPON}
            >
              <span className="sprite__oneclick text__button">확인</span>
            </button>
          </div>
          <div className="box__notice">
            <ul className="list__notice">
              <li className="list-item__notice">
                무료 이용 중, 언제든지 해지할 수 있어요.
              </li>
              <li className="list-item__notice">
                한달 후, <em className="text__emphasis">스마일캐시 35,000원</em>
                을 매년 드려요. (연회비 3만원 결제시)
              </li>
              <li className="list-item__notice">
                스마일페이에 등록된 카드로 매년 연회비가 자동결제 됩니다.
              </li>
              <li className="list-item__notice">
                스마일클럽 신청은{' '}
                <CustomAnchor
                  onClick={onClickOpenTerms(
                    `${domains.SMILE_CLUB}/agreement/view/club`,
                  )}
                  className="link__layer-terms"
                  data-summery="스마일클럽 이용약관"
                  data-montelena-acode={
                    areaCodes.ONE_CLICK_TERMS_CLUB_ON_COUPON
                  }
                >
                  스마일클럽 이용약관
                </CustomAnchor>
                과{' '}
                <CustomAnchor
                  onClick={onClickOpenTerms(
                    `${domains.SMILE_CLUB}/agreement/view/personal`,
                  )}
                  className="link__layer-terms"
                  data-summery="개인정보 수집 및 이용"
                  data-montelena-acode={
                    areaCodes.ONE_CLICK_TERMS_PERSONAL_ON_COUPON
                  }
                >
                  개인정보 수집/이용
                </CustomAnchor>{' '}
                및{' '}
                <CustomAnchor
                  onClick={onClickOpenTerms(
                    `${domains.SMILE_CLUB}/agreement/view/third`,
                  )}
                  className="link__layer-terms"
                  data-summery="개인정보 제3자 제공동의"
                >
                  개인정보 제3자 제공동의 약관
                </CustomAnchor>
                에 동의하는 것으로 간주됩니다.
              </li>
              <li className="list-item__notice">
                (주)지마켓과 (주)에스에스지닷컴이 공동으로 운영하는 맴버십
                가입에 동의합니다. 단, 본 서비스는 선택사항으로 동의하지 않아도
                멤버십을 제외한 개별 사이트의 모든 서비스는 그대로 이용하실 수
                있습니다.
              </li>
            </ul>
          </div>
          <div id="js-player__confetti-layer" className="box__confetti" />
        </div>
        {iframeUrl && !isMobile && (
          <div
            className="box__oneclick-smileclub-terms"
            style={{ display: 'block' }}
          >
            <div className="box__terms-header">
              <p className="text__title">스마일클럽</p>
              <button
                className="button__close"
                onClick={(): void => setIframeUrl(undefined)}
              >
                <i className="icon">닫기</i>
              </button>
            </div>
            <div className="box__terms-body">
              <iframe
                src={iframeUrl}
                frameBorder="0"
                className="iframe__oneclick-smileclub-terms"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LayerSmileClubOneClickJoin
