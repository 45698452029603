import { uniq } from 'lodash'
import React, { useEffect } from 'react'
import CartBtRecommendItem from '~/cart/gmarket/ko/common/components/CartRecommend/CartBtRecommendItem'
import { FullFilledBtRecommendItem } from '~/cart/modules/recommend/types'
import areaCodes from '~/data/areaCodes'
import Montelena from '~/lib/montelena'

const CartBtRecommendItemListTypeA = ({
  seedItemNo,
  recommendItemList,
  onClickGoVIP,
  onClickAddCart,
}: {
  seedItemNo: string
  recommendItemList: FullFilledBtRecommendItem[]
  onClickGoVIP: (
    itemNo: string,
    isAdvertisement: boolean,
    clickSecureUrl: string,
  ) => React.MouseEventHandler<HTMLElement>
  onClickAddCart: (
    itemNo: string,
    isAdvertisement: boolean,
    clickSecureUrl: string,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  useEffect(() => {
    if (areaCodes.RECOMMEND_VIEW_TYPE_A) {
      Montelena.logImpression(
        'IMP_VI',
        areaCodes.RECOMMEND_VIEW_TYPE_A,
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          bt_seed_itemno: seedItemNo,
          // eslint-disable-next-line @typescript-eslint/camelcase
          bt_recommend_itemno: JSON.stringify(
            uniq(recommendItemList.map((item) => item.itemNo)).slice(0, 30),
          ), // 최대 30개
        },
        'CartBtRecommendItemListTypeA',
      )
    }
    // [] 로 전달되는 경우 마운트 될 때 한 번만 실행
  }, [])

  return (
    <div className="box__bt-items" id="ui_check-typeA">
      <ul className="list">
        {recommendItemList.map((item, idx) => (
          <CartBtRecommendItem
            key={`recommend_item_${idx}`}
            item={item}
            btViewType={'TypeA'}
            onClickGoVIP={onClickGoVIP}
            onClickAddCart={onClickAddCart}
          />
        ))}
      </ul>
    </div>
  )
}

export default CartBtRecommendItemListTypeA
