import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CartHeader from '~/cart/gmarket/ko/common/components/CartHeader/CartHeader'
import {
  getCartUnitCounts,
  getCurrentCartUnitList,
} from '~/cart/modules/cart/reducer'
import { goCartTab } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { CartTabType } from '~/types/enums'

const CartHeaderContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const currentCartCount = useSelector(
    (state: RootState) => getCurrentCartUnitList(state.cart).length,
  )

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  const cartUnitCounts = useSelector(
    (state: RootState) => getCartUnitCounts(state.cart),
    shallowEqual,
  )

  const onClickCartTab = (cartTab: CartTabType) => (): void => {
    dispatch(goCartTab(cartTab))
  }

  return (
    <CartHeader
      isMobile={isMobile}
      currentCartCount={currentCartCount}
      currentCartTab={currentCartTab}
      cartUnitCounts={cartUnitCounts}
      onClickCartTab={onClickCartTab}
    />
  )
}

export default CartHeaderContainer
