import React from 'react'
import { ShippingCountry } from '~/cart/modules/shipping/types'
import areaCodes from '~/data/areaCodes'
import tenantConstants from '~/data/checkout-constants'
import { ElementIds } from '~/data/consts'
import { CartTabType } from '~/types/enums'

const OrderSummaryTitle = ({
  isOverseaShipping,
  shippingCountry,
  currentCartTab,
  onClickOpenCountrySelector,
}: {
  isOverseaShipping: boolean
  shippingCountry: ShippingCountry
  currentCartTab: CartTabType
  onClickOpenCountrySelector: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return !isOverseaShipping ? (
    <div className="order_title order_local">
      <strong className="text">전체 합계</strong>
      {tenantConstants.IsOverseaShippingAvailable &&
        currentCartTab !== 'SmileFresh' && (
          <button
            className="order_delivery"
            onClick={onClickOpenCountrySelector}
            data-montelena-acode={areaCodes.OVERSEA_SHIPPING}
          >
            <span className="text">해외로 배송</span>
            <span className="for_a11y"> 국가선택 레이어팝업 열기</span>
            <i className="icon sprite__cart img_arrow_right">더보기</i>
          </button>
        )}
    </div>
  ) : (
    <div className="order_title order_oversea">
      <strong className="text">전체 합계</strong>
      {tenantConstants.IsOverseaShippingAvailable && (
        <button
          id={ElementIds.ChangeCountryWhenOversea}
          className="order_delivery"
          onClick={onClickOpenCountrySelector}
          data-montelena-acode={areaCodes.OVERSEA_SHIPPING}
        >
          <em className="emphasis">{shippingCountry.countryName}</em>
          <span className="text">(으)로 배송</span>
          <i className="icon sprite__cart img_arrow_right">더보기</i>
        </button>
      )}
    </div>
  )
}

export default OrderSummaryTitle
