import React from 'react'
import { CartUnit } from '~/cart/modules/cart/types'
import areaCodes from '~/data/areaCodes'
import { ElementIds } from '~/data/consts'

const CartUnitCouponBoxButton = ({
  cartUnit,
  isMobile,
  isCouponApplied,
  onClickOpenCouponBox,
}: {
  cartUnit: CartUnit
  isMobile: boolean
  isCouponApplied: boolean
  onClickOpenCouponBox: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const buttonClassPostfix = isCouponApplied
    ? 'button__active'
    : !cartUnit.isCouponUsable
    ? 'button__disabled'
    : ''

  return (
    <>
      {isMobile ? (
        <button
          id={ElementIds.CouponButton + cartUnit.cartUnitId}
          className={`button__coupon sprite__cart--after ${buttonClassPostfix}`}
          onClick={onClickOpenCouponBox}
          data-montelena-acode={areaCodes.COUPON_BOX}
        >
          쿠폰적용
        </button>
      ) : (
        <button
          id={ElementIds.CouponButton + cartUnit.cartUnitId}
          className={`button__coupon sprite__cart--after ${buttonClassPostfix}`}
          onClick={onClickOpenCouponBox}
          data-montelena-acode={areaCodes.COUPON_BOX}
          aria-haspopup="dialog"
          aria-label={isCouponApplied ? '쿠폰 적용됨, 쿠폰 변경' : '쿠폰 적용'}
          aria-describedby={cartUnit.item.itemNo}
        >
          쿠폰적용
        </button>
      )}
    </>
  )
}

export default CartUnitCouponBoxButton
