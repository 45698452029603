import { once } from 'lodash'
import { useCallback, useRef } from 'react'
import useDeepCompareCallback from '~/lib/deep-compare-hook/useDeepCompareCallback'

interface Target {
  key: string
  element: HTMLElement
  callback?: () => void
}

interface IntersectionObserverHook {
  addTarget: (t: Target) => void
}

const voidFunction = (): void => undefined

const useIntersectionObserver = (
  options?: IntersectionObserverInit,
): IntersectionObserverHook => {
  const targets = useRef<Target[]>([])
  const observer = useRef<IntersectionObserver | null>(null)

  const getObserver = useDeepCompareCallback(
    once(() => {
      if (observer.current) {
        observer.current.disconnect()
        targets.current = []
      }

      const { root, rootMargin = '0px', threshold = 0 } = options || {}
      const ob = new IntersectionObserver(
        (entries) => {
          entries.forEach(({ isIntersecting }, index) => {
            if (isIntersecting) {
              const callback = targets.current[index]?.callback ?? voidFunction
              callback()
            }
          })
        },
        {
          root,
          rootMargin,
          threshold,
        },
      )

      observer.current = ob
      return ob
    }),
    [options],
  )

  const addTarget = useCallback(
    (t: Target) => {
      if (targets.current.some((target) => target.key === t.key)) return

      getObserver().observe(t.element)
      targets.current.push(t)
    },
    [getObserver],
  )

  return { addTarget }
}

export default useIntersectionObserver
