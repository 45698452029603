import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OrderSummary from '~/cart/gmarket/ko/common/components/OrderSummary/OrderSummary'
import { getIsUniverseClub } from '~/cart/modules/buyer/reducer'
import { goCheckout } from '~/cart/modules/complex-actions/checkout'
import {
  getIsShowingCashbackBenefit,
  getTotalCashbackSummary,
  getTotalSummary,
  RootState,
} from '~/cart/modules/reducers'
import { getIsOverseaShipping } from '~/cart/modules/shipping/reducer'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import AppSchemeHelper from '~/lib/app-scheme-helper'
import { formatString } from '~/lib/formatter'
import logRender from '~/lib/log-render'

const OrderSummaryContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const isApp = useSelector((state: RootState) => state.view.isApp)
  const isAndroid = useSelector((state: RootState) => state.view.isAndroid)
  const appType = useSelector((state: RootState) => state.view.appInfo?.appType)

  const isOverseaShipping = useSelector((state: RootState) =>
    getIsOverseaShipping(state.shipping),
  )

  const isShowingCashbackBenefit = useSelector((state: RootState) =>
    getIsShowingCashbackBenefit(state),
  )

  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  const isUniverseClub = useSelector((state: RootState) =>
    getIsUniverseClub(state.buyer),
  )

  const summaryInfo = useSelector(
    (state: RootState) => getTotalSummary(state),
    shallowEqual,
  )
  const cashbackSummary = useSelector(
    (state: RootState) => getTotalCashbackSummary(state),
    shallowEqual,
  )

  const isShippingFeeLoadedAfterChanged = useSelector(
    (state: RootState) => state.view.isShippingFeeLoadedAfterChanged,
  )

  const onClickGoCheckout = (e: React.MouseEvent<HTMLElement>): void => {
    dispatch(goCheckout(e.currentTarget))
  }

  const iosAppScheme = AppSchemeHelper.getIOSOpenWindowSimpleScheme(
    isApp,
    isAndroid,
    appType,
  )

  const smileDeliveryCategoryUrl = isUniverseClub
    ? domains.SMILE_DELIVERY_CATEGORY
    : domains.FREE_DELIVERY_SMILE_DELIVERY_CATEGORY

  const smileDeliveryCategoryLinkUrl =
    iosAppScheme && smileDeliveryCategoryUrl
      ? formatString(iosAppScheme, encodeURIComponent(smileDeliveryCategoryUrl))
      : smileDeliveryCategoryUrl

  return (
    <OrderSummary
      isMobile={isMobile}
      isOverseaShipping={isOverseaShipping}
      isShowingCashbackBenefit={isShowingCashbackBenefit}
      isUniverseClub={isUniverseClub}
      isShippingFeeLoadedAfterChanged={isShippingFeeLoadedAfterChanged}
      currentCartTab={currentCartTab}
      summaryInfo={summaryInfo}
      cashbackSummary={cashbackSummary}
      smileDeliveryCategoryLinkUrl={smileDeliveryCategoryLinkUrl}
      onClickGoCheckout={onClickGoCheckout}
    />
  )
}

export default OrderSummaryContainer
