import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Toast from '~/cart/gmarket/ko/common/components/Toast'
import useHitBuyBox from '~/cart/hooks/useHitBuyBox'
import useImpression from '~/cart/hooks/useImpression'
import { BuyBoxType, HitBuyBox } from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import logRender from '~/lib/log-render'

const messageMap: {
  [type in BuyBoxType]: string
} = {
  GoodDeal: '최저가 추천상품을 담았어요.',
  SoldOut: '품절 상품과 같은 상품으로 바꿔 담았어요.',
}

interface MessageProps {
  message: string
}

const PC = React.memo(function PC({ message }: MessageProps): JSX.Element {
  return (
    <p className="text__message">
      <span className="text__emoji">😊</span>
      {message}
    </p>
  )
})

const Mobile = React.memo(function Mobile({
  message,
}: MessageProps): JSX.Element {
  return <p className="text__message">{message}</p>
})

const areaCodeMap = areaCodes?.TOAST_ADDED_BUY_BOX_TO_CART ?? {}

const HitBuyBoxToast = (): JSX.Element => {
  logRender()

  const [show, setShow] = useState(false)

  const [message, setMessage] = useState('')

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const tab = useSelector((state: RootState) => state.cart.currentCartTab)

  const onHit = useCallback(({ type }: HitBuyBox) => {
    setMessage(messageMap[type])
    setShow(true)
  }, [])

  const [logImp] = useImpression({
    areaCode: areaCodeMap[tab] ?? '',
    areaValues: {},
    source: 'HitBuyBoxToast',
  })

  useHitBuyBox(onHit)

  useEffect(() => {
    if (show) {
      logImp()
    }
  }, [show, logImp])

  return (
    <Toast
      className="box__toast-buybox"
      show={show}
      onClose={(): void => {
        setShow(false)
      }}
    >
      {isMobile ? <Mobile message={message} /> : <PC message={message} />}
    </Toast>
  )
}

export default HitBuyBoxToast
