import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import useUnitCouponRecommendation from '~/cart/hooks/useUnitCouponRecommendation'
import logRender from '~/lib/log-render'

const CouponRecommendationContainer = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  logRender()

  const [, recommend] = useUnitCouponRecommendation((target) => target)

  const debounced = useCallback(
    debounce(async (recommend: () => Promise<void>) => {
      await recommend()
    }, 1000),
    [],
  )

  useEffect(() => {
    debounced(recommend)
  }, [debounced, recommend])

  return <>{children}</>
}

export default CouponRecommendationContainer
