import React, { useEffect } from 'react'
import { CouponBoxTableType } from '~/cart/modules/types'
import Montelena from '~/lib/montelena'

type AutoCouponNotificationProps = {
  couponType: CouponBoxTableType
  couponPolicyNo?: number
}

const AutoCouponNotification = ({
  couponType,
  couponPolicyNo,
}: AutoCouponNotificationProps): JSX.Element => {
  useEffect(() => {
    Montelena.logImpression(
      'IMP_VI',
      '200010213',
      {
        ['buyer_coupon_master_no_1']:
          couponType === CouponBoxTableType.Buyer && couponPolicyNo
            ? couponPolicyNo
            : '',
        ['super_coupon_master_no_2']:
          couponType === CouponBoxTableType.Super && couponPolicyNo
            ? couponPolicyNo
            : '',
      },
      'CouponAutoDownload',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <span className="text__new">
      <span className="icon_new" aria-label="NEW"></span>
      놓친 쿠폰을 발급해 드렸어요!
    </span>
  )
}

export default AutoCouponNotification
