import React from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useSelector } from 'react-redux'
import { getCartUnitListByIdList } from '~/cart/modules/cart/reducer'
import { Discount } from '~/cart/modules/cart/types'
import { getCartBundlePriceInfo, RootState } from '~/cart/modules/reducers'
import { currencyAmount } from '~/lib/formatter'
import logRender from '~/lib/log-render'

const BundleDiscountContainer = ({
  discount,
  cartUnitIdList,
}: {
  discount: Discount
  cartUnitIdList: number[]
}): JSX.Element => {
  logRender()

  const minApplyCondition = discount.discountPolicy.minApplyCondition
  const policyDiscountPrice = discount.discountPolicy.discountPrice
  const policyDiscountRate = discount.discountPolicy.discountRate
  let discountApplySuccess = false
  let discountApplySuccessText = 0

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const cartBundlePrice = useSelector(
    (state: RootState) => getCartBundlePriceInfo(state, cartUnitIdList),
    shallowEqual,
  )
  const cartUnits = useSelector((state: RootState) =>
    getCartUnitListByIdList(state.cart, cartUnitIdList),
  )

  const sumCartUnitsQuantity = cartUnits.reduce((result, cartUnit) => {
    return result + cartUnit.quantity
  }, 0)

  const sumCartUnitPrice = cartBundlePrice.reduce((result, cartUnitPrice) => {
    return result + cartUnitPrice.cartUnitItemPrice
  }, 0)

  if (discount.discountPolicy.calcTypeSub == 'PO') {
    discountApplySuccess = sumCartUnitsQuantity >= minApplyCondition
    discountApplySuccessText =
      sumCartUnitsQuantity % minApplyCondition === 0
        ? sumCartUnitsQuantity / minApplyCondition
        : Math.floor(sumCartUnitsQuantity / minApplyCondition)
  } else if (discount.discountPolicy.calcTypeSub == 'CN') {
    discountApplySuccess = sumCartUnitsQuantity >= minApplyCondition
  } else if (discount.discountPolicy.calcTypeSub == 'PR') {
    discountApplySuccess = sumCartUnitPrice >= minApplyCondition
  }

  return (
    <div
      className={`box__mutiple-purchases ${
        discountApplySuccess ? 'succeed' : ''
      }`}
    >
      {discount.discountPolicy.calcTypeSub == 'PO' &&
        (discountApplySuccess == false ? (
          <span className="text__line">
            잠깐!✋{' '}
            <span className="text__emphasis">{minApplyCondition}개</span> 사고
            할인 받으세요!
          </span>
        ) : (
          <span className="text__line">🛍️ {minApplyCondition}개 사면 할인</span>
        ))}

      {discount.discountPolicy.calcTypeSub == 'CN' &&
        (discount.discountPolicy.calcType == 'M' ? (
          <span className="text__line">
            🛍️ {minApplyCondition}개 이상 사면
            <span className="text__emphasis">
              {' '}
              개당 {currencyAmount(policyDiscountPrice)}원 할인
            </span>
          </span>
        ) : (
          <span className="text__line">
            🛍️ {minApplyCondition}개 이상 사면
            <span className="text__emphasis">
              {' '}
              개당 {policyDiscountRate}% 할인
            </span>
          </span>
        ))}
      {discount.discountPolicy.calcTypeSub == 'PR' &&
        (discount.discountPolicy.calcType == 'M' ? (
          <span className="text__line">
            🛍️ {currencyAmount(minApplyCondition)}원 이상 사면
            <span className="text__emphasis">
              {' '}
              개당 {currencyAmount(policyDiscountPrice)}원 할인
            </span>
          </span>
        ) : (
          <span className="text__line">
            🛍️ {currencyAmount(minApplyCondition)}원 이상 사면
            <span className="text__emphasis">
              {' '}
              개당 {policyDiscountRate}% 할인
            </span>
          </span>
        ))}

      {discountApplySuccess &&
        (discount.discountPolicy.calcTypeSub == 'PO' ? (
          <span className="text__line">
            {isMobile ? '짝짝👏 ' : '👏 짝짝! '}
            <span className="text__emphasis">
              {sumCartUnitsQuantity}개 중 {discountApplySuccessText}개는
            </span>{' '}
            무료로 받으시네요!
          </span>
        ) : (
          <span className="text__line">
            {isMobile ? '짝짝👏 ' : '👏 짝짝! '}
            <span className="text__emphasis">
              {currencyAmount(discount.totalBundleDiscountPrice)}원
            </span>{' '}
            할인 받으시네요!
          </span>
        ))}
    </div>
  )
}

export default React.memo(BundleDiscountContainer, isEqual)
