import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CartUnitComponent from '~/cart/gmarket/ko/common/components/CartItemList/CartUnitComponent'
import CartUnitCouponBoxButton from '~/cart/gmarket/ko/common/components/CartItemList/CartUnitCouponBoxButton'
import CouponRecommendationTooltipContainer from '~/cart/gmarket/ko/common/containers/CartItemList/CouponRecommendationTooltipContainer'
import { getCartUnitById } from '~/cart/modules/cart/reducer'
import { CartUnit } from '~/cart/modules/cart/types'
import {
  openUnitCouponBox,
  removeCartUnits,
} from '~/cart/modules/complex-actions'
import {
  getBuyUnavailableInfo,
  getCartUnitPriceInfo,
  getDeliveryUnitCouponErrorType,
  RootState,
} from '~/cart/modules/reducers'
import {
  getIsCurrentShippingUnavailable,
  getIsOverseaShipping,
  getShippingPolicy,
} from '~/cart/modules/shipping/reducer'
import { ShippingPolicy } from '~/cart/modules/shipping/types'
import { BuyUnavailableInfo } from '~/cart/modules/types'
import {
  getIsCartUnitCouponBoxOpened,
  getIsUnitCouponApplied,
} from '~/cart/modules/unit-coupon/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

const getItemClassName = (
  cartUnit: CartUnit,
  idx: number,
  shippingPolicy?: ShippingPolicy & {
    isSpecialShipping: boolean
    shippingMethodText?: string
  },
  buyUnavailableInfo?: BuyUnavailableInfo,
): string => {
  const result = ['unit--item']
  if (buyUnavailableInfo) {
    result.push('case_soldout')
  } else {
    if (cartUnit.item.options.length <= 0) {
      result.push('case_nooption')
    }
    if (shippingPolicy && shippingPolicy.isSpecialShipping) {
      result.push('case_delivery')
    }
  }
  if (idx === 0) {
    result.push('first')
  }
  return result.join(' ')
}

const CartUnitContainer = ({
  cartUnitId,
  firstCartUnitId,
  idx,
}: {
  cartUnitId: number
  firstCartUnitId: number
  idx: number
}): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const cartUnit = useSelector((state: RootState) =>
    getCartUnitById(state.cart, cartUnitId),
  )
  const cartUnitPrice = useSelector(
    (state: RootState) => getCartUnitPriceInfo(state, cartUnitId),
    shallowEqual,
  )

  const bundleDiscountApplySuccess =
    cartUnitPrice.cartUnitPrice !=
    cartUnitPrice.cartUnitPriceWithoutUnitCouponPrice

  const shippingPolicy = useSelector(
    (state: RootState) =>
      cartUnit
        ? getShippingPolicy(
            state.shipping,
            cartUnit.shippingPolicyKey,
            cartUnit.item.isECoupon,
          )
        : undefined,
    shallowEqual,
  )

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const isCouponApplied = useSelector((state: RootState) =>
    getIsUnitCouponApplied(state.unitCoupon, cartUnitId),
  )
  const deliveryCouponErrorType = useSelector((state: RootState) =>
    getDeliveryUnitCouponErrorType(state, cartUnitId),
  )

  const isShippingUnavailableItem = useSelector((state: RootState) =>
    cartUnit
      ? getIsCurrentShippingUnavailable(state.shipping, cartUnit.cartUnitId)
      : false,
  )

  const isOverseaShipping = useSelector((state: RootState) =>
    getIsOverseaShipping(state.shipping),
  )

  const buyUnavailableInfo = useSelector((state: RootState) =>
    getBuyUnavailableInfo(state, cartUnitId),
  )

  const isFirstCartUnit = cartUnitId === firstCartUnitId

  const isCurrentShippingUnavailable = useSelector((state: RootState) =>
    getIsCurrentShippingUnavailable(state.shipping, cartUnitId),
  )

  const onClickRemoveButton = (e: React.MouseEvent<HTMLElement>): void => {
    dispatch(removeCartUnits(e.currentTarget, [cartUnitId]))
  }

  const isCouponBoxOpened = useSelector((state: RootState) =>
    getIsCartUnitCouponBoxOpened(state.unitCoupon, cartUnitId),
  )

  const onClickOpenCouponBox = (e: React.MouseEvent<HTMLElement>): void => {
    if (!isCouponBoxOpened) {
      dispatch(openUnitCouponBox(cartUnitId, e.currentTarget))
    }
  }

  if (!cartUnit) {
    return <></>
  }

  const bundleDiscount = cartUnit.discounts.find(
    (discount) => discount.discountType === 'Bundle',
  )

  const isBundleDiscountCartUnit = !!bundleDiscount
  const isSignal = !!(
    cartUnit.priceChangedSignalAvailable &&
    cartUnit.signal &&
    cartUnit.signal.signalType !== 'NONE'
  )

  return (
    <dl
      className={getItemClassName(
        cartUnit,
        idx,
        shippingPolicy,
        buyUnavailableInfo,
      )}
    >
      <dt>
        <strong className="for_a11y">구매할 상품 상세 정보</strong>
      </dt>
      <CartUnitComponent
        cartUnit={cartUnit}
        idx={idx}
        cartUnitPrice={cartUnitPrice}
        shippingPolicy={shippingPolicy}
        isMobile={isMobile}
        isOverseaShipping={isOverseaShipping}
        isShippingUnavailable={isShippingUnavailableItem}
        deliveryCouponErrorType={deliveryCouponErrorType}
        isCouponApplied={isCouponApplied}
        buyUnavailableInfo={buyUnavailableInfo}
        onClickRemoveButton={onClickRemoveButton}
        isFirstDuplicatesCartUnit={isFirstCartUnit && cartUnit.hasDuplicates}
        isCurrentShippingUnavailable={isCurrentShippingUnavailable}
        isBundleDiscountCartUnit={isBundleDiscountCartUnit}
        bundleDiscountApplySuccess={bundleDiscountApplySuccess}
        couponBoxButton={
          <CouponRecommendationTooltipContainer
            cartUnitId={cartUnitId}
            isSignal={isSignal}
            isCouponApplied={isCouponApplied}
          >
            <CartUnitCouponBoxButton
              cartUnit={cartUnit}
              isMobile={isMobile}
              isCouponApplied={isCouponApplied}
              onClickOpenCouponBox={onClickOpenCouponBox}
            />
          </CouponRecommendationTooltipContainer>
        }
      ></CartUnitComponent>
    </dl>
  )
}

export default CartUnitContainer
