import React, { useEffect } from 'react'
import { CouponBoxTableType } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import Montelena from '~/lib/montelena'

type ClubNudgingInsertCouponNotificationProps = {
  couponType: CouponBoxTableType
  couponPolicyNo?: number
}

const ClubNudgingInsertCouponNotification = ({
  couponType,
  couponPolicyNo,
}: ClubNudgingInsertCouponNotificationProps): JSX.Element => {
  useEffect(() => {
    if (areaCodes.SHOW_CLUB_NUDGING_INSERT_COUPON) {
      Montelena.logImpression(
        'IMP_VI',
        areaCodes.SHOW_CLUB_NUDGING_INSERT_COUPON,
        {
          ['buyer_coupon_master_no_1']:
            couponType === CouponBoxTableType.Buyer && couponPolicyNo
              ? couponPolicyNo
              : '',
          ['super_coupon_master_no_2']:
            couponType === CouponBoxTableType.Super && couponPolicyNo
              ? couponPolicyNo
              : '',
        },
        'ClubNudgingInsertCouponNotification',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <span className="text__new">
      <span className="icon_new" aria-label="NEW"></span>
      시크릿 쿠폰 쏙 넣어드렸어요!
    </span>
  )
}

export default ClubNudgingInsertCouponNotification
