import { isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useUnitCouponDownloader from '~/cart/hooks/useUnitCouponDownloader'
import {
  issueCoupon,
  updateUnitCouponIssueNo,
} from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { CouponDownloadMode, CouponDownloadState } from '~/cart/modules/types'
import {
  setDownloadableUnitCouponState,
  setUnitCouponDownloaded,
} from '~/cart/modules/unit-coupon/actions'
import {
  DownloadableUnitCoupon,
  DownloadableUnitCouponFailedLifeCycle,
  DownloadableUnitCouponFailedReason,
} from '~/cart/modules/unit-coupon/types'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { retry } from '~/lib/utils/retry'

type UnitCouponDownloading = {
  downloadTargetUnitCouponList: DownloadableUnitCoupon[]
  onDownloadCoupon: (retryCount: number, mode: CouponDownloadMode) => void
}

const voidFunction = (): void => {
  //void
}

const getDownloadTargetUnitCouponList = (
  state: RootState,
): DownloadableUnitCoupon[] =>
  state.unitCoupon.downloadableUnitCoupons.filter(
    ({ downloadState, failedLifeCycle }) =>
      downloadState === CouponDownloadState.Loading ||
      (downloadState === CouponDownloadState.Fail &&
        failedLifeCycle === 'temporary'),
  )

const useUnitCouponDownloading = (
  cartUnitId: number,
  succeeded: (autoSuccess: boolean) => void = voidFunction,
  failed: (
    reason?: DownloadableUnitCouponFailedReason,
    lifeCycle?: DownloadableUnitCouponFailedLifeCycle,
  ) => void = voidFunction,
): UnitCouponDownloading => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const {
    downloadableUnitCoupons,
    loading,
    autoSucceed,
    fails,
  } = useUnitCouponDownloader()

  // 다운로드 대상 쿠폰 조회
  const downloadTargetUnitCouponList = useSelector(
    getDownloadTargetUnitCouponList,
    shallowEqual,
  )

  useEffect(() => {
    if (isEmpty(downloadableUnitCoupons) || loading) {
      return
    }
    if (!isEmpty(fails)) {
      fails.forEach(({ couponPolicyNo, failedReason, failedLifeCycle }) => {
        if (failedLifeCycle === 'permanent') {
          dispatch(
            setDownloadableUnitCouponState({
              couponPolicyNo,
              downloadState: CouponDownloadState.Terminated,
            }),
          )
        }
        failed(failedReason, failedLifeCycle)
      })
    } else {
      succeeded(autoSucceed)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const onDownloadCoupon = useCallback(
    (retryCount: number, mode: CouponDownloadMode) => {
      downloadTargetUnitCouponList.map(async (couponInfo) => {
        const couponPolicyNo = couponInfo.couponPolicyNo || 0

        // 쿠폰 다운로딩중 true
        dispatch(
          setUnitCouponDownloaded({
            cartUnitId,
            couponPolicyNo,
            isDownloading: true,
          }),
        )

        dispatch(
          setDownloadableUnitCouponState({
            couponPolicyNo,
            downloadState: CouponDownloadState.Loading,
          }),
        )

        // 이미 이벤트 응모에 성공한 경우 event api 재호출을 하지 않음
        let issueSuccess
        if (couponInfo.failedReason !== 'delayed') {
          // 쿠폰 이벤트 응모
          issueSuccess = await dispatch(
            issueCoupon(couponInfo.eventNo || 0, couponInfo.encStr || ''),
          )
        } else {
          issueSuccess = {
            isSuccess: true,
          }
        }

        let updateSuccess = false
        if (issueSuccess && issueSuccess.isSuccess) {
          /**
           * 이벤트 응모 성공 시, 1s 간격으로 최대 retryCount(자동 : 2번, 수동 : 10번) 획득 시도
           */
          retry(
            retryCount,
            1000,
            async () => {
              // 쿠폰 발급번호 조회 및 업데이트
              const updateIssueNo = await dispatch(
                updateUnitCouponIssueNo(cartUnitId, couponPolicyNo, mode),
              )

              if (updateIssueNo) {
                updateSuccess = true
                dispatch(
                  setDownloadableUnitCouponState({
                    couponPolicyNo,
                    downloadState:
                      mode === CouponDownloadMode.Auto
                        ? CouponDownloadState.AutoSuccess
                        : CouponDownloadState.ManualSuccess,
                  }),
                )
                return true
              }
              return false
            },
            async () => {
              if (!updateSuccess) {
                dispatch(
                  setDownloadableUnitCouponState({
                    couponPolicyNo,
                    downloadState: CouponDownloadState.Fail,
                    failedReason: 'delayed',
                    failedLifeCycle: 'temporary',
                  }),
                )
              }
              // 쿠폰 다운로딩중 false
              dispatch(
                setUnitCouponDownloaded({
                  cartUnitId,
                  couponPolicyNo,
                  isDownloading: false,
                }),
              )
            },
          )
        } else {
          const { failedReason, failedLifeCycle } = issueSuccess || {}
          dispatch(
            setDownloadableUnitCouponState({
              couponPolicyNo,
              downloadState: CouponDownloadState.Fail,
              failedReason,
              failedLifeCycle,
            }),
          )
          // 쿠폰 다운로딩중 false
          dispatch(
            setUnitCouponDownloaded({
              cartUnitId,
              couponPolicyNo,
              isDownloading: false,
            }),
          )
        }
      })
    },
    [cartUnitId, dispatch, downloadTargetUnitCouponList],
  )

  return {
    downloadTargetUnitCouponList,
    onDownloadCoupon,
  }
}

export default useUnitCouponDownloading
