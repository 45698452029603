import React from 'react'
import CartTab from '~/cart/gmarket/ko/common/components/CartHeader/CartTab'
import CartToolbarContainer from '~/cart/gmarket/ko/common/containers/CartToolbarContainer'
import { CartUnitCounts } from '~/cart/modules/cart/types'
import { CartTabType } from '~/types/enums'

const CartHeader = ({
  isMobile,
  currentCartCount,
  currentCartTab,
  cartUnitCounts,
  onClickCartTab,
}: {
  isMobile: boolean
  currentCartCount: number
  currentCartTab: CartTabType
  cartUnitCounts: CartUnitCounts
  onClickCartTab: (cartTab: CartTabType) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div id="cart_header">
      <div className="cart_header_wrapper">
        <div className="cart_header">
          <div className="inner_cont">
            <h3 className="title">장바구니</h3>
          </div>
        </div>
        <CartTab
          currentCartTab={currentCartTab}
          onClickCartTab={onClickCartTab}
          cartUnitCounts={cartUnitCounts}
        />
        {!isMobile && currentCartCount > 0 && <CartToolbarContainer />}
      </div>
    </div>
  )
}

export default CartHeader
