import React from 'react'
import isEqual from 'react-fast-compare'
import { ExtraDiscountCandidate } from '~/cart/modules/unit-coupon/types'
import { koreanNumeralNotation } from '~/lib/formatter'

type CouponBoxExtraDiscountHeaderProps = {
  extraDiscount: ExtraDiscountCandidate
}

function getDiscountPhrase(extraDiscount: ExtraDiscountCandidate): string {
  return extraDiscount.unitType === 'Rate'
    ? `${koreanNumeralNotation(extraDiscount.discountRate || 0)}%`
    : `${koreanNumeralNotation(extraDiscount.discountAmount || 0)}원`
}

const CouponBoxExtraDiscountHeader = ({
  extraDiscount,
}: CouponBoxExtraDiscountHeaderProps): JSX.Element => {
  return (
    <h3 className="text__title">
      주문서에서 결제 시<br />
      <span className="text__emphasis">
        최대 {getDiscountPhrase(extraDiscount)}
      </span>{' '}
      더 할인 받으세요!
    </h3>
  )
}

export default React.memo(CouponBoxExtraDiscountHeader, isEqual)
