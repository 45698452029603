import { difference, isEmpty, keys, omit, pick } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGetRecommendItems } from '~/apis/recommend'
import { addRecommendedItems } from '~/cart/modules/recommend/actions'
import { RecommendedItemMap } from '~/cart/modules/recommend/types'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'

const useRecommendedItem = (): [
  RecommendedItemMap,
  (itemNos: string[]) => Promise<RecommendedItemMap>,
] => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const recommendedItems = useSelector(
    (state: RootState) => state.recommend.items,
  )

  const find = useCallback(
    async (itemNos: string[]): Promise<RecommendedItemMap> => {
      const diffItemNos = difference(itemNos, keys(recommendedItems))
      const picked = pick(recommendedItems, itemNos)
      if (isEmpty(diffItemNos)) {
        return picked
      }

      const items = await fetchGetRecommendItems({
        itemNos: diffItemNos,
      }).catch((e) => {
        ERROR_HANDLER_DO_NOTHING()(e)
        return [] as cartAPI.RecommendItemResponse[]
      })
      if (isEmpty(items)) {
        return picked
      }

      const itemMap = items.reduce((acc, curr) => {
        const itemNo = curr.itemNo
        acc[itemNo] = omit(curr, ['discounts'])
        return acc
      }, {} as RecommendedItemMap)

      dispatch(addRecommendedItems(itemMap))

      return {
        ...picked,
        ...itemMap,
      }
    },
    [dispatch, recommendedItems],
  )

  return [recommendedItems, find]
}

export default useRecommendedItem
