import React from 'react'
import isEqual from 'react-fast-compare'
import CouponBoxTableItem from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxTableItem'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import {
  CouponBoxTableType,
  CouponBoxTableViewData,
} from '~/cart/modules/types'
import { formatAsDom } from '~/lib/formatter'

type CouponBoxTableProps = {
  isMobile?: boolean
  viewData: CouponBoxTableViewData
  selectedCouponIssueNo: number
  onChangeCoupon: (
    key: CouponBoxTableType,
    couponIssueNo: number,
    isAppliedOnAnother?: boolean,
  ) => React.ChangeEventHandler<HTMLInputElement>
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => React.MouseEventHandler<HTMLButtonElement>
  onClickJoinSmileClub: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const getCouponInfoTableAlias = (type: CouponBoxTableType): string => {
  switch (type) {
    case CouponBoxTableType.Buyer:
      return 'G마켓할인'
    case CouponBoxTableType.Seller:
      return '판매자할인'
    case CouponBoxTableType.Super:
      return '중복할인'
    case CouponBoxTableType.Bundle:
      return '더 사면 할인'
    default:
      return ''
  }
}

const getNoticeText = (type: CouponBoxTableType): string => {
  switch (type) {
    case 'Seller':
      return '적용된 할인이 없습니다.'
    case 'Super':
      return '적용 가능한 쿠폰이 없습니다.'
    default:
      return '적용 가능한 쿠폰이 없습니다.'
  }
}

const CouponBoxTable = ({
  isMobile,
  viewData,
  selectedCouponIssueNo,
  onChangeCoupon,
  onCancelCoupon,
  onClickJoinSmileClub,
}: CouponBoxTableProps): JSX.Element => {
  const availableCoupons = viewData.coupons.filter(
    (coupon) => !coupon.isNotUseCoupon,
  )
  if (
    availableCoupons.length === 0 &&
    viewData.type === CouponBoxTableType.Bundle
  ) {
    return <></>
  }

  if (viewData.isIncludeCoupon) {
    return (
      <div className="box__content">
        <div className={`${isMobile ? '' : 'box__title'}`}>
          <h3 className="text__title">
            {formatAsDom(getCouponInfoTableAlias(viewData.type))}
          </h3>
        </div>
        <div className={`${isMobile ? '' : 'box__information'}`}>
          <ul className="list__coupon">
            {availableCoupons.length > 0 ? (
              viewData.coupons.map((coupon, idx) => {
                return (
                  <CouponBoxTableItem
                    tableType={viewData.type}
                    key={idx}
                    id={`${viewData.type}-${coupon.issueNo}`}
                    viewData={coupon}
                    isSelected={selectedCouponIssueNo === coupon.issueNo}
                    onChangeCoupon={onChangeCoupon}
                    onCancelCoupon={onCancelCoupon}
                    onClickJoinSmileClub={onClickJoinSmileClub}
                  />
                )
              })
            ) : (
              <li className="coupon-unit coupon-empty">
                {getNoticeText(viewData.type)}
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  } else {
    return (
      <div className="box__content box__content--item-sale">
        <div className="box__title">
          <h3 className="text__title">
            {formatAsDom(getCouponInfoTableAlias(viewData.type))}
          </h3>
        </div>
        <div className="box__information">
          <ul className="coupon-list">
            {viewData.coupons &&
              viewData.coupons.map((couponView) => {
                return (
                  <li className="text__price" key={couponView.issueNo}>
                    <FormatPrice krwAmount={couponView.price} />
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }
}

export default React.memo(CouponBoxTable, isEqual)
