import { debounce } from 'lodash'
import {
  DependencyList,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'

interface ScrollPosition {
  x: number
  y: number
}

const useWindowScrollEffect = (
  effect: (position: ScrollPosition) => void,
  deps: DependencyList,
): void => {
  const dep = useMemo(() => ({ ...deps }), [deps])

  const currentScroll = useRef<ScrollPosition>({ x: 0, y: 0 })

  useEffect(() => {
    const saveScroll = debounce(
      () => {
        currentScroll.current = { x: window.scrollX, y: window.scrollY }
      },
      500,
      {
        leading: true,
        maxWait: 1000,
      },
    )
    window.addEventListener('scroll', saveScroll)
    return (): void => {
      window.removeEventListener('scroll', saveScroll)
    }
  }, [])

  useLayoutEffect(() => {
    effect(currentScroll.current)
  }, [dep, effect])
}

export default useWindowScrollEffect
