import React from 'react'
import { Item } from '~/cart/modules/cart/types'

const UnitCouponBoxItemSummary = ({ item }: { item?: Item }) => {
  return (
    <div className="box__item-summary">
      {item && (
        <>
          <span className="box__item-img">
            <img
              src={item.itemImageUrl}
              alt="상품이미지"
              onError={(e): void => {
                e.currentTarget.style.display = 'none'
              }}
            />
          </span>
          <p className="text__item-name">{item.itemName}</p>
        </>
      )}
    </div>
  )
}

export default UnitCouponBoxItemSummary
