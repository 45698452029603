import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { getCurrentDawnDeliveryCutOffTime } from '~/cart/modules/shipping/reducer'
import logRender from '~/lib/log-render'

// 첫 노출시간
const SHOWING_HOURS_BEFORE_CUT_OFF = 0.5 // 시간 전부터 노출

const SmileDeliveryCutOffTimer = (): JSX.Element => {
  logRender()

  const [isShowing, setShowing] = useState(false)
  const [diff, setDiff] = useState(0)

  const dawnCutOffTime = useSelector((state: RootState) =>
    getCurrentDawnDeliveryCutOffTime(state.shipping),
  )
  const currentCartTab = useSelector(
    (state: RootState) => state.cart.currentCartTab,
  )

  // 닫을때 timeout clear 해줘야함
  useEffect(() => {
    if (dawnCutOffTime && currentCartTab === 'SmileDelivery') {
      const cutOffMoment = moment(dawnCutOffTime)
      const startMoment = moment(dawnCutOffTime).subtract(
        SHOWING_HOURS_BEFORE_CUT_OFF,
        'hour',
      )
      if (
        cutOffMoment.isValid() &&
        startMoment.isBefore(moment()) &&
        cutOffMoment.isAfter(moment())
      ) {
        setShowing(true)
        setDiff(cutOffMoment.diff(moment()))
        const timerIntervalKey = window.setInterval(() => {
          setDiff(cutOffMoment.diff(moment()))
        }, 1000)

        const hidingTimeoutKey = window.setTimeout(() => {
          setShowing(false)
          window.clearInterval(timerIntervalKey)
        }, cutOffMoment.diff(moment()))

        return (): void => {
          window.clearInterval(timerIntervalKey)
          window.clearTimeout(hidingTimeoutKey)
        }
      } else {
        setShowing(false)
      }
    } else {
      setShowing(false)
    }
  }, [currentCartTab, dawnCutOffTime])

  return (
    <div
      className={`box__smiledelivery-timer ${
        isShowing && diff > 0 ? 'box__smiledelivery-timer--active' : ''
      }`}
    >
      <span className="text__delivery-dawn sprite__cart">
        새벽배송 주문가능시간
      </span>
      <span className="text__time">
        {diff > 0 && (
          <span id="dawn_timer" className="text sprite__cart--before">
            <span className="text__min">{moment.utc(diff).format('mm')}</span>분{' '}
            <span className="text__sec">{moment.utc(diff).format('ss')}</span>초
          </span>
        )}
        남음
      </span>
    </div>
  )
}

export default SmileDeliveryCutOffTimer
