import { DependencyList, EffectCallback, useEffect } from 'react'
import useDeepCompareDeps from '~/lib/deep-compare-hook/useDeepCompareDeps'

/**
 * Non-primitive type deps 에 대해 deep compare 를 하는 useEffect hook 입니다.
 * deps 가 Non-primitive type(Object, Function, Array) 일 때만 사용하는 것이 좋습니다.
 * @param effect
 * @param deps
 */
const useDeepCompareEffect = (effect: EffectCallback, deps: DependencyList) => {
  useEffect(effect, useDeepCompareDeps(deps))
}

export default useDeepCompareEffect
