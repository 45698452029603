import dataPicker from '~/data/data-picker'
import siteEnv from '~/lib/site-env'
interface AreaValueMap {
  [key: string]: string | undefined
}

interface AreaValues {
  BUY_BOX_TYPE: AreaValueMap
}

const areaValueSources: SiteEnvDataType<Partial<AreaValues>>[] = [
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Korean',
    },
    data: {
      BUY_BOX_TYPE: {
        GoodDeal: 'onsale',
        SoldOut: 'soldout',
      },
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Korean',
    },
    data: {
      BUY_BOX_TYPE: {
        GoodDeal: 'onsale',
        SoldOut: 'soldout',
      },
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Global',
    },
    data: {},
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Global',
    },
    data: {},
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'PC',
    },
    data: {},
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'Mobile',
    },
    data: {},
  },
  {
    env: {
      tenantType: 'G9',
    },
    data: {},
  },
]

const areaValues: Partial<AreaValues> =
  dataPicker<Partial<AreaValues>>(areaValueSources, siteEnv) || {}

export default areaValues
